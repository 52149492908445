@use "global"as *;


.p-contact__links {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  max-width: calc(105rem + ($padding-pc * 2));
  padding-right: $padding-pc;
  padding-left: $padding-pc;
  margin-inline: auto;

  @include mq("md") {
    padding-right: $padding-sp;
    padding-left: $padding-sp;
    padding-bottom: 4rem;
  }
}

.c-contact-links {
  padding: 6rem 5rem;
  background-color: $lightgray;
  border-radius: 2rem;

  @include mq("md") {
    padding: 4rem 2rem;
  }
}

.c-contact-links__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 2.5rem;

  @include mq("md") {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

.p-contact__body:has(.p-contact__alert) {
  position: relative;
  padding-top: 20.6rem;

  @include mq("md") {
    padding-top: 29.8rem;
  }

}

.p-contact__alert {
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50%;
  width: 100%;
  max-width: calc(105rem + ($padding-pc * 2));
  padding-right: $padding-pc;
  padding-left: $padding-pc;

  @include mq("md") {
    padding: 0;
    max-width: initial;
  }
}

.p-contact__alert-body {
  padding: 3rem 5rem 4rem;
  border-radius: 2rem;
  background-color: $main12;
  border: 2px solid $main3;

  @include mq("md") {
    padding: 3.2rem 2rem 5.6rem;
    margin: 0 10px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    translate: -50% 100%;
    width: 3rem;
    bottom: 0.2rem;
    background-color: $main12;
    height: 3.2rem;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }

  &::after {
    bottom: 0.4rem;
    background-color: $main12;
  }

  &::before {
    bottom: 0;
    background-color: $main3;
  }

}

.p-contact__alert-text {
  font-weight: $medium;
  font-size: 2rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  color: $main8;

  @include mq("md") {
    letter-spacing: 0;
  }
}

.p-contact__alert-text.p-contact__alert-text--small {
  font-size: 1.8rem;

  @include mq("md") {
    letter-spacing: 0.05em;
  }
}

.p-contact__alert-list {
  margin-top: 1.1rem;
  display: flex;
  flex-direction: column;

  @include mq("md") {
    margin-top: 3rem;
  }
}

.p-contact__alert-caution {
  margin-top: 2.1rem;
  width: max-content;
  min-width: 84rem;
  padding: 3rem;
  background-color: $white;
  border-radius: 2rem;

  @include mq("md") {
    width: 100%;
    min-width: initial;
    padding: 3rem 1.8rem 3rem 2rem;
  }
}

.p-contact__form {
  background-color: $lightgray;
  padding: 12.4rem $padding-pc 10rem;


  @include mq("md") {
    padding: 21.4rem $padding-sp 10rem;
  }
}

.p-contact__form.p-contact__form--property {
  padding-top: 6rem;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 11rem;
  }
}

.p-contact__form-content {
  width: 100%;
  max-width: 80rem;
  margin-inline: auto;
  padding: 9rem 12.5rem 5rem;
  background-color: $white;
  border-radius: 2rem;
  box-shadow: 0 0.7rem 2.2rem rgba(#000, 8%);

  @include mq("md") {
    padding: 12rem 2rem 4rem;
  }
}

.p-contact__form-content.p-contact__form-content--property {
  padding-top: 5rem;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}

.p-contact__title {
  position: relative;
  width: max-content;
  max-width: 100%;
  margin-inline: auto;
  padding-bottom: 0.9rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main8;

  @include mq("md") {
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-main-full.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-contact__title.p-contact__title--secondary {
  color: $main1;
  &::after{
    background-image: url(/assets/img/common/dot-line-blue-full.png);
  }
}


.p-contact__property-introduction {

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-contact__property-content {
  padding-top: 5rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
}