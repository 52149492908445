/* ============================
/* フォント設定
/* ========================= */

/* フォントファミリー */
$japanese: "Zen Kaku Gothic New", sans-serif;
$english: "Montserrat", sans-serif;

/* フォントウェイト */
$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$heavy: 900;
