/* ============================
/* カラー設定
/* ========================= */


/* 全体設定 */
$bg: #ffffff;
$text: #333333;
$heading: #273838;

/* 無彩色 */
$black: #333333;
$darkgray: #8d8d8d;
$gray: #AEAEAE;
$gray2: #dcdcdc;
$lightgray: #f5f5f5;
$lightgray2: #f2f2f2;
$white: #ffffff;

/* メインカラー */
$main1: #4086AE;
$main2: #8BC2E2;
$main3: #58AFAB;
$main4: #78BABA;
$main5: #CFE4E8;
$main6: #87E4E4; //pagination
$main7: #55A5A2; //cta border
$main8: #27837F; //cta text
$main9: #3f585e; //trouble bg
$main10: #727A79; //trouble line
$main11: #DCF5FA; //accedent bg
$main12: #DCEDEC;
$main13: #267EA7;
$main14: #CDE5E4;
$main15: #C2E5F9;
$main16: #0D3E3C;
$main17: #E3F1F9;

/* アクセントカラー */
$accent1: #705D13;
$accent2: #FAC231;
$accent3: #F9CA6F;
$accent4: #FFE683;
$accent5: #FFF4BB;
$accent6: #FDE3D9;
$accent7: #E9BA11; //cta text
$accent8: #FFE279; //underline
$accent9: #F2C318; //sp footer
$accent10: #FFDE64;
$accent11: #F3B517;
$accent11: #F3B517;
$accent12: #FE9F84;
$accent13: #EF7653;

/* ボタンカラー */
$btn:#58AFAB;
$btn-h:#8ABFBD;
$btn2:#4086AE;
$btn2-h:#83B6D2;
$btn3:#FAC231;
$btn3-h:#F4943B;
$btn-store-tel: #7AB1CA;


/* 特定セクション */
$insurance1:#DEB23F;
$insurance1-h-bg:#FFF2BF;
$insurance1-h-btn:#F4943B;
$insurance2:#91C4C1;
$insurance2-h-bg:#E7F9FF;
$insurance2-h-btn:#3EA7A1;

$reserve-line:#59D68C;
$reserve-line-bg:#EFF6EF;
$reserve-line-btn:#59D68C;
$reserve-tel:#267EA7;
$reserve-tel-bg:#E0EFEE;
$reserve-tel-btn:#7AB1CA;

$breadcrumb-link :#808080;
$modal-table :#C1E3E1;
/* グラデーション */