@use "global"as *;

.c-nav__list {
  display: flex;
  align-items: center;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.c-nav__item {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-image: url(/assets/img/common/nav-item-line.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.c-nav__item a {
  display: block;
  padding: 0.6rem 1.8rem;
  font-family: $japanese;
  font-weight: $medium;
  font-size: 1.4rem;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.c-nav__buttons {
  margin-left: 2rem;
}

.c-nav__button-list {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.c-nav__button-item a {
  display: block;
  padding: 1.2rem 3rem;
  background-color: $btn;
  border-radius: 10rem;
  color: $white;
  font-weight: $bold;
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  transition: background-color 0.3s;

  &:hover {
    background-color: $btn-h;
  }
}

.c-nav__button-item.c-nav__button-item--secondary-color a {
  background-color: $btn3;

  &:hover {
    background-color: $btn3-h;
  }
}

.c-nav__button-item.c-nav__button-item--store-color a {
  background-color: $btn-store-tel;

  &:hover {
    opacity: 0.7;
  }
}