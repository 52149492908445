@use "global"as *;

.p-store-support {
  margin-top: 8rem;

  @include mq("md") {
    margin-top: 6rem;
  }
}

.p-store-support__inner {
  width: 100%;
  max-width: 100rem;
  margin-inline: auto;
}

.p-store-support__container {
  position: relative;
  padding: 5rem 6.5rem;
  border-radius: 2rem;

  background-image: url(/assets/img/store/support-bg.webp), linear-gradient(135deg, #FFD9CA, #FFCBBA), ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    border-radius: 0;
    padding: 4rem 1.9rem;
  }

  &::after {
    content: "";
    position: absolute;
    top: -2rem;
    right: 10.5rem;
    width: 13rem;
    aspect-ratio: 130 / 107;
    background-image: url(/assets/img/store/support-deco.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      top: 38.8rem;
      right: 4.3rem;
    }
  }
}


.p-store-support__list {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 3rem;

  @include mq("md") {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.p-store-support__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  padding: 4rem 3rem;
  border-radius: 2rem;
  border: 3px solid $accent12;
  box-shadow: 0.4rem 0.4rem 0 $accent12;

  @include mq("md") {
    padding: 2rem 1rem 3rem;
    border: 1px solid $accent12;
    box-shadow: 0.3rem 0.3rem 0 $accent12;
  }
}

.p-store-support__item-img {
  width: 9rem;
  aspect-ratio: 1 / 1;

  @include mq("md") {
    width: 6rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-store-support__item-title {
  margin-top: 3.2rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  text-align: center;

  @include mq("md") {
    margin-top: 1rem;
    font-size: 2rem;
    text-align: left;
  }
}

.p-store-support__item-note {
  margin-top: 1.8rem;
  font-weight: $medium;
  font-size: max(1.4rem,12px);
  line-height: 150%;
  letter-spacing: 0.05em;

  @include mq("md") {
    margin-top: 2rem;
    font-size: 1.2rem;
  }
}

.p-store-support__text {
  margin-top: 2rem;
  font-weight: $medium;
  font-size: 1.6rem;
  line-height: 200%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-size: 1.4rem;
  }
}