@use "global"as *;

.p-store-flow {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-image: url(/assets/img/store/flow-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    background-image: url(/assets/img/store/flow-bg-sp.webp);
  }
}

.p-store-flow__container {
  margin-top: 5rem;
  max-width: 100rem;
  margin-inline: auto;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-store-flow__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6.7rem;

  @include mq("md") {
    gap: 8rem;
  }
}

.p-store-flow__item {
  position: relative;
  width: 100%;

}

.p-store-flow__item + .p-store-flow__item {
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -2rem;
    width: 4rem;
    height: 2rem;
    translate: -50% -100%;
    background-image: url(/assets/img/store/flow-arrow.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-store-flow__item-wrap {
  display: flex;
  border-radius: 2rem;
  overflow: hidden;
  background-color: $white;

  @include mq("md") {
    flex-direction: column;
    box-shadow: 0 1rem 3rem rgba(#000, 8%);

  }
}

.p-store-flow__item-img {
  width: 30rem;
  aspect-ratio: 300 / 193;
  flex-shrink: 0;

  @include mq("md") {
    width: 100%;
    aspect-ratio: 360 / 240;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-store-flow__item-body {
  width: 100%;
  padding: 3rem 5rem;

  @include mq("md") {
    padding: 3rem 3rem 4rem;
  }
}

.p-store-flow__item-heading {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-bottom: 1.5rem;

  @include mq("md") {
    gap: 1rem;
    padding-bottom: 2rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-main-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  }
}

.p-store-flow__item-num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: $main3;
  outline: 0.2rem solid $white;
  outline-offset: -0.5rem;
  font-weight: $bold;
  font-size: 1.8rem;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: $white;


  @include mq("md") {
    width: 4rem;
    font-size: 1.6rem;
  }
}

.p-store-flow__item-title {
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main3;

  @include mq("md") {
    font-size: 2.4rem;
    letter-spacing: 0;
  }

  & .small {
    font-size: 2.2rem;
    height: 150%;

    @include mq("md") {
      font-size: 1.8rem;
      line-height: 100%;
    }
  }
}

.p-store-flow__item-detail {
  margin-top: 2rem;
}