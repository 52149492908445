@use "global"as *;

.p-case {
  @include mq("md") {
    margin-top: 3rem;
  }
}

.p-case__wrapper {
  padding-top: 5rem;
  padding-bottom: 10rem;

  @include mq("md") {
    padding-bottom: 8rem;
  }
}