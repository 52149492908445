@use "global" as *;


.c-care-insurance {
  width: 100%;
  max-width: 1000px;
  margin-inline: auto;
  background-color: $white;
  border-radius: 2rem;
  padding: 5rem 5rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1rem 1rem 4rem rgba(#122933, 4%);

  @include mq("md") {
    padding: 3rem 1rem 4rem;
  }
}

.c-care-insurance__heading {
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    font-size: 1.8rem;
    line-height: 180%;
  }

  & .strong {
    font-size: 4rem;
    line-height: 150%;
    color: $main8;

    @include mq("md") {
      font-size: 3rem;
      line-height: 120%;
    }
  }
}


.c-care-insurance__list {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  gap: 16rem;

  @include mq("md") {
    margin-top: 4rem;
    flex-direction: column;
    gap: 4rem;
  }

}

.c-care-insurance__item {
  width: fit-content;
  min-width: 25rem;
  padding: 4rem 2rem;
  background-color: $white;
  border: 3px solid $insurance1;
  border-radius: 2.4rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0.5rem 0.5rem 2rem rgba(#000, 7%);

  &:hover {
    background-color: $insurance1-h-bg;

    & .c-care-insurance__item-button {
      background-color: $insurance1-h-btn;
    }
  }

  &:nth-child(2) {
    border: 3px solid $insurance2;

    & .c-care-insurance__item-button {
      background-color: $insurance2;
    }

    &:hover {
      background-color: $insurance2-h-bg;

      & .c-care-insurance__item-button {
        background-color: $insurance2-h-btn;
      }
    }
  }
}

.c-care-insurance__item-img {
  width: 8rem;
  aspect-ratio: 80 / 85;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-care-insurance__item-title {
  margin-top: 1.5rem;
  font-weight: $medium;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.c-care-insurance__item-button {
  margin-top: 2rem;
  padding: 1.4rem 1rem 1.4rem 3rem;
  background-color: $insurance1;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: $bold;
  font-size: 1.2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;
  transition: background-color 0.3s;


  &::after {
    content: "";
    display: block;
    width: 1.5rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/triangle-arrow-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}