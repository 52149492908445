@use "global" as *;

.p-course-worry-check {
  position: relative;
  padding-bottom: 6rem;

  @include mq("md") {
    padding-bottom: 5rem;
  }

  &::before {
    content: "";
    width: vw(1440, 1295);
    position: absolute;
    top: 2rem;
    left: 0;
    bottom: 0;
    background-color: $lightgray;
    border-radius: 1rem 2rem 2rem 1rem;
    z-index: index $z-bg;

    @include mq("md") {
      width: 100%;
      border-radius: 0;
    }
  }

}

.p-course-worry-check__inner {
  position: relative;
  width: 134.5rem;
  max-width: 100%;
  padding-inline: 5rem;
  margin-left: auto;

  @include mq("md") {
    padding-inline: 2rem;
  }
}

.p-course-worry-check__title {
  position: relative;
  margin-left: 14.7rem;
  width: max-content;
  padding: 0.7rem 3rem 0.9rem;
  background-color: $main3;
  border-radius: 2rem;

  font-weight: $bold;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $white;

  @include mq("md") {
    padding: 0.7rem 1.4rem 0.9rem 1.4rem;
    margin-inline: auto;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 50%;
    translate: -50% 100%;
    width: 2.6rem;
    height: 1.3rem;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    background-color: $main3;
  }
}

.p-course-worry-check__content {
  margin-top: 2.3rem;
  display: flex;
  align-items: center;
  gap: 4rem;

  @include mq("md") {
    margin-top: 2.7rem;
    flex-direction: column;
  }
}

.p-course-worry-check__list-wrapper {
  margin-left: auto;
  display: flex;
  gap: 4rem;

  @include mq("md") {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(9,auto);
    gap: 0 2rem;
    align-items: center;
    justify-content: center;
    grid-auto-flow: column;
  }
}

.p-course-worry-check__list {
  width: 100%;

  @include mq("md") {
    display: contents;
  }
}

.p-course-worry-check__list li {
  & label {
    display: inline-block;
    position: relative;
    padding: 1.65rem 0 1.65rem 3.4rem;
    font-weight: $bold;
    font-size: 2rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;

    @include mq("md") {
      font-size: 1.8rem;
      line-height: 150%;
      white-space: normal;
    }

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";

      @include mq("md") {}
    }

    &::before {
      width: 2rem;
      height: 2rem;
      border: 1.5px solid $main16;
      border-radius: 0.3rem;
      left: 0;

      @include mq("md") {
        top: 50%;
        translate: 0 -50%;
      }
    }

    &::after {
      opacity: 0;
      width: 2.2rem;
      height: 2.2rem;
      background-image: url(/assets/img/common/icon-check-no-frame-main.webp);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      top: 1.2rem;
      left: 0.2rem;

      @include mq("md") {
        top: 45%;
        translate: 0 -50%;
      }
    }
  }

  & input:checked+label::after {
    opacity: 1;
  }
}

.p-course-worry-check__img-area {
  position: relative;
  width: 52.7rem;
  max-width: 100%;
  aspect-ratio: 527 / 480;
  border: 5px solid $white;
  border-radius: 2rem;
  background-color: $lightgray;
  box-shadow: 0 0.7rem 2.2rem rgba(#000, 8%);
  overflow: hidden;


  @include mq("md") {
    display: none;
  }
}

.p-course-worry-check__img {
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-course-worry-check__where {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & img.is-active {
    opacity: 1;
  }
}
