@use "global"as *;

.c-sec-heading {}

.c-sec-heading__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @include mq("md") {
    gap: 1.2rem;
  }
}

.c-sec-heading__logo {
  width: 3rem;
  aspect-ratio: 1/1;

  @include mq("md") {
    width: 2.6rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-sec-heading__logo.c-sec-heading__logo--voice {
  width: 5rem;
  aspect-ratio: 50 / 27;
}

.c-sec-heading__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @include mq("md") {
    gap: 1.2rem;
  }
}

.c-sec-heading__sub {
  font-family: $english;
  font-weight: $medium;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main1;
  text-transform: capitalize;

  @include mq("md") {
    font-size: 1.4rem;
  }

  &.c-sec-heading__sub--row {
    display: flex;
    align-items: center;
    gap: 1rem;

    &::before {
      content: "";
      display: block;
      width: 3rem;
      aspect-ratio: 1/1;
      background-image: url(/assets/img/common/logo-illust.webp);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @include mq("md") {
        width: 2.6rem;
      }
    }
  }
}


.c-sec-heading__main {
  font-weight: $bold;
  font-size: 3.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $heading;

  @include mq("md") {
    font-size: 2.8rem;
  }

  &.c-sec-heading__main--small {
    font-size: 2.4rem;
    line-height: 150%;

    @include mq("md") {
      font-size: 2rem;
    }
  }

  & .num {
    font-size: 5rem;
    color: $accent2;
    line-height: 150%;

    @include mq("md") {
      font-size: 4rem;
    }
  }
}

.c-sec-heading__introduction {
  margin-top: 1.3rem;
  font-weight: $medium;
  font-size: max(1.6rem,14px);
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 150%;
  }
}