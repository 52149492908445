@use "global"as *;

.p-company__policy {
  padding-top: 5rem;
  padding-bottom: 8rem;

  @include mq("md") {
    padding-top: 5rem;
    padding-bottom: 6rem;
  }
}

.p-company__heading {
  position: relative;
  width: max-content;
  padding-bottom: 0.9rem;

  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main1;

  @include mq("md") {
    padding-bottom: 1rem;
    font-size: 2.4rem;
    margin-inline: auto;
  }

  & .num {
    font-size: 3.2rem;
    color: $accent2;

    @include mq("md") {
      font-size: 2.8rem;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-blue-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-company__heading.p-company__heading--center {
  margin-inline: auto;
}

.p-company__policy-list {
  width: 100%;
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 5rem;

  @include mq("md") {
    margin-top: 3rem;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

.p-company__policy-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-company__policy-title {
  position: relative;
  width: 100%;
  max-width: 29rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-color: $white;
  border-radius: 0.5rem;
  border: 1px solid $main1;
  box-shadow: 0.4rem 0.4rem 0 $main1;

  @include mq("md") {
    max-width: 19rem;
  }
}

.p-company__policy-title-en {
  font-family: $english;
  font-weight: $medium;
  font-size: 1.4rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $accent2;
}

.p-company__policy-title-ja {
  font-weight: $medium;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main1;
}

.p-company__policy-item-body {
  margin-top: -3.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5.5rem 2rem 3rem;
  background-color: $main17;
  border-radius: 2rem;
}

.p-company__policy-item-text {
  font-weight: $bold;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    line-height: 180%;
  }
}

.p-company__policy-item:nth-child(3){
  & .p-company__policy-item-text {
    @include mq("md") {
      line-height: 150%;
    }
  }
}

.p-company__message {
  padding-top: 5rem;
  padding-bottom: 6rem;
  background-color: $lightgray;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}

.p-company-message__wrapper {
  display: flex;
  gap: 3rem;

  @include mq("md") {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.p-company-message__img {
  width: 51rem;
  aspect-ratio: 510 / 456;
  border-radius: 2rem;
  overflow: hidden;
  flex-shrink: 0;

  @include mq("md") {
    margin-top: 3rem;
    width: 28.4rem;
    order: 2;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-company__message-body {
  display: flex;
  flex-direction: column;

  @include mq("md") {
    display: contents;
  }
}

.p-company__message-detail {
  margin-top: 2rem;

  @include mq("md") {
    margin-top: 3rem;
    order: 3;
  }
}

.p-company__message-text+.p-company__message-text {
  margin-top: 1em * 1.8;
}

.p-company__message-name {
  margin-top: 5rem;
  margin-left: auto;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;

  @include mq("md") {
    margin-top: 2rem;
    order: 4;

    font-size: 1.4rem;
  }

  & .post {
    margin-right: 1rem;
    color: $main1;

  }
}

.p-company__info {
  padding-top: 8rem;
  padding-bottom: 10rem;

  @include mq("md") {
    padding-top: 6rem;
    padding-bottom: 14.8rem;
  }
}

.p-company__info-list {
  margin-top: 5rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}
