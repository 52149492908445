@charset "UTF-8";

/* ============================
/* 幅に関する設定※案件ごとに変更
/* ========================= */

/* キャンパス設定 */

/* インナー幅設定 */

/* 左右の最低余白設定 */

/* ブレイクポイント設定 */

/* ==============================================
/* メディアクエリとブレークポイントの関数※変更不可
/* ============================================== */

/* ============================
/* カラー設定
/* ========================= */

/* 全体設定 */

/* 無彩色 */

/* メインカラー */

/* アクセントカラー */

/* ボタンカラー */

/* 特定セクション */

/* グラデーション */

/* ============================
/* フォント設定
/* ========================= */

/* フォントファミリー */

/* フォントウェイト */

/* ============================
/* z-indexの数値管理
/* ========================= */

/* 共通パーツ */

/* あしらい */

/* スライダー */

/* top */

html {
  font-size: 62.5%;
}

body {
  font-family: "Zen Kaku Gothic New", sans-serif;
  background-color: #ffffff;
  color: #333333;
}

a {
  color: #333333;
}

/* PCでは電話番号のクリックを無効にする */

a[href^="tel:"] {
  pointer-events: none;
}

iframe {
  max-width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */

ul,
ol {
  padding: 0;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */

/* Set core body defaults */

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */

ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

/* Make images easier to work with */

img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */

article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */

img:not([alt]) {
  filter: blur(10px);
}

/* form reset */

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

button {
  padding: 0;
}

input[type=checkbox],
input[type=radio] {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  vertical-align: middle;
}

input[type=checkbox]:focus-visible + span,
input[type=radio]:focus-visible + span {
  outline: -webkit-focus-ring-color auto 5px;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.l-2col {
  width: 100%;
  display: grid;
  grid-template-columns: 27.6rem 76rem;
  grid-template-rows: 1fr;
  justify-content: space-between;
}

.l-footer {
  width: 100%;
}

.l-header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
}

.l-inner {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 83.3333333333vw;
  padding-right: 25px;
  padding-left: 25px;
}

.l-inner.l-inner--wide {
  max-width: 95.1388888889vw;
}

.l-inner.l-inner--small {
  max-width: 72.9166666667vw;
}

.l-main {
  width: 100%;
}

.c-breadcrumb {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.c-breadcrumb li {
  font-weight: 500;
  font-size: max(1.5rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.c-breadcrumb li a {
  color: #808080;
  transition: opacity 0.3s;
  text-transform: uppercase;
}

.c-breadcrumb li a:hover {
  opacity: 0.7;
}

.c-breadcrumb li span {
  color: #333333;
}

.c-breadcrumb li + li::before {
  content: "";
  display: block;
  width: 1.2rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/breadcrumb-arrow.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-button {
  display: inline-flex;
  width: max-content;
  padding: 2rem 1.5rem 2rem 5rem;
  background-color: #58AFAB;
  align-items: center;
  gap: 3rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.c-button::after {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-button:hover {
  background-color: #8ABFBD;
}

.c-button.c-button--line {
  border: 3px solid #ffffff;
}

.c-button.c-button--secondary {
  background-color: #4086AE;
}

.c-button.c-button--secondary:hover {
  background-color: #83B6D2;
}

.c-button.c-button--tertiary {
  background-color: #FAC231;
}

.c-button.c-button--tertiary:hover {
  background-color: #F4943B;
}

.c-button.c-button--thin {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  white-space: nowrap;
}

.c-button.c-button--pc-thin {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  white-space: nowrap;
}

.c-button.c-button--full {
  width: 100%;
  justify-content: center;
}

span.c-button {
  pointer-events: none;
}

.c-button.c-button--store {
  padding: 1.5rem 2rem;
  gap: 1rem;
}

.c-button.c-button--search {
  padding: 3rem 6.5rem 3rem 10rem;
  font-size: 2.2rem;
  justify-content: center;
}

.c-button.c-button--search::after {
  width: 2.8rem;
  height: 2.8rem;
  background-image: url(/assets/img/common/icon-search.svg);
}

.c-calender {
  width: 46.5rem;
  max-width: 100%;
  border-collapse: collapse;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f5f5f5;
}

.c-calender tr {
  width: 46.5rem;
  display: grid;
  grid-template-columns: 16rem 1fr 1fr 1fr 1fr 1fr 1fr 8.3rem;
  grid-template-rows: 5.7rem;
}

.c-calender th,
.c-calender td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-calender th:last-child,
.c-calender td:last-child {
  padding-right: 1.8rem;
}

.c-calender .c-calender__head th {
  background-color: #58AFAB;
  color: #ffffff;
}

.c-calender__text {
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #58AFAB;
}

.c-calender__text.c-calender__text--reverse {
  color: #ffffff;
}

.c-calender__text.c-calender__text--time {
  color: #267EA7;
  font-weight: 700;
}

.c-calender__text.c-calender__text--yes-no {
  font-size: 1.2rem;
}

.c-calender__body tr + tr {
  border-top: 0.2rem solid #D5DCDC;
}

.c-campaign-banner {
  width: fit-content;
}

.c-campaign-banner__img {
  width: 72rem;
  aspect-ratio: 720/400;
}

.c-campaign-banner__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-care-insurance {
  width: 100%;
  max-width: 1000px;
  margin-inline: auto;
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 5rem 5rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1rem 1rem 4rem rgba(18, 41, 51, 0.04);
}

.c-care-insurance__heading {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}

.c-care-insurance__heading .strong {
  font-size: 4rem;
  line-height: 150%;
  color: #27837F;
}

.c-care-insurance__list {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  gap: 16rem;
}

.c-care-insurance__item {
  width: fit-content;
  min-width: 25rem;
  padding: 4rem 2rem;
  background-color: #ffffff;
  border: 3px solid #DEB23F;
  border-radius: 2.4rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.07);
}

.c-care-insurance__item:hover {
  background-color: #FFF2BF;
}

.c-care-insurance__item:hover .c-care-insurance__item-button {
  background-color: #F4943B;
}

.c-care-insurance__item:nth-child(2) {
  border: 3px solid #91C4C1;
}

.c-care-insurance__item:nth-child(2) .c-care-insurance__item-button {
  background-color: #91C4C1;
}

.c-care-insurance__item:nth-child(2):hover {
  background-color: #E7F9FF;
}

.c-care-insurance__item:nth-child(2):hover .c-care-insurance__item-button {
  background-color: #3EA7A1;
}

.c-care-insurance__item-img {
  width: 8rem;
  aspect-ratio: 80/85;
}

.c-care-insurance__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-care-insurance__item-title {
  margin-top: 1.5rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.c-care-insurance__item-button {
  margin-top: 2rem;
  padding: 1.4rem 1rem 1.4rem 3rem;
  background-color: #DEB23F;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: background-color 0.3s;
}

.c-care-insurance__item-button::after {
  content: "";
  display: block;
  width: 1.5rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-contact-form {
  width: 100%;
}

.c-contact-form__wrapper {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.c-contact-form__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.c-contact-form__item {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.c-contact-form__label p {
  font-weight: 700;
  font-size: 2rem;
  line-height: 144%;
  letter-spacing: 0.05em;
}

.c-contact-form__label p .required {
  display: inline-block;
  margin-left: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #FDE3D9;
  font-weight: 700;
  font-size: max(1.4rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #EF7653;
}

.c-contact-form__input input,
.c-contact-form__input textarea {
  width: 100%;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  padding: 1.5rem 2rem;
  font-weight: 400;
  font-size: 2rem;
  line-height: 144%;
  letter-spacing: 0.05em;
}

.c-contact-form__input textarea {
  height: 15rem;
}

.c-contact-form__button {
  position: relative;
}

.c-contact-form__button input {
  display: inline-block;
  padding: 2rem 7.3rem 2rem 5rem;
  border-radius: 5rem;
  background-color: #58AFAB;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  transition: background-color 0.3s;
}

.c-contact-form__button input:hover {
  background-color: #8ABFBD;
}

.c-contact-form__button-arrow {
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  right: 1.5rem;
  width: 2.8rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-contact-form__button.c-contact-form__button--secondary input {
  background-color: #4086AE;
}

.c-contact-form__button.c-contact-form__button--secondary input:hover {
  background-color: #83B6D2;
}

.c-cta {
  position: relative;
  width: 100%;
  max-width: 78.7rem;
  margin-inline: auto;
  border-radius: 2rem;
  border: 3px solid #55A5A2;
  box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.c-cta::after {
  content: "";
  position: absolute;
  bottom: -2rem;
  right: -11.4rem;
  width: 25rem;
  height: 15rem;
  background-image: url(/assets/img/common/cta-deco.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  rotate: 5deg;
}

.c-cta__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.c-cta__text {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: #27837F;
}

.c-cta__text .strong {
  font-weight: 700;
  font-size: 4rem;
  line-height: 150%;
  color: #E9BA11;
}

.c-cta__text .logo {
  width: 30.9rem;
  aspect-ratio: 309/40;
}

.c-cta__text .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.c-cta__text.c-cta__text--flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.c-cta__store-text {
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}

.c-cta__button {
  margin-top: 3.5rem;
  text-align: center;
}

.c-cta__button.c-cta__button--store {
  margin-top: 4rem;
}

.c-definition-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.c-definition-list__item {
  display: flex;
  gap: 4rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #dcdcdc;
}

.c-definition-list__item dt,
.c-definition-list__item dd {
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.c-definition-list__item > dt {
  width: 20rem;
  flex-shrink: 0;
}

.c-definition-list__item dt .small,
.c-definition-list__item dd .small {
  font-size: 1.8rem;
}

.c-definition-list__child-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.c-definition-list__child-item {
  display: flex;
  align-items: start;
  gap: 10rem;
}

.c-definition-list__child-item dt {
  width: max-content;
  min-width: 15rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4086AE;
  border-radius: 10rem;
  line-height: 100%;
  color: #ffffff;
}

.c-sec-heading__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.c-sec-heading__logo {
  width: 3rem;
  aspect-ratio: 1/1;
}

.c-sec-heading__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-sec-heading__logo.c-sec-heading__logo--voice {
  width: 5rem;
  aspect-ratio: 50/27;
}

.c-sec-heading__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.c-sec-heading__sub {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #4086AE;
  text-transform: capitalize;
}

.c-sec-heading__sub.c-sec-heading__sub--row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.c-sec-heading__sub.c-sec-heading__sub--row::before {
  content: "";
  display: block;
  width: 3rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/logo-illust.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-sec-heading__main {
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: #273838;
}

.c-sec-heading__main.c-sec-heading__main--small {
  font-size: 2.4rem;
  line-height: 150%;
}

.c-sec-heading__main .num {
  font-size: 5rem;
  color: #FAC231;
  line-height: 150%;
}

.c-sec-heading__introduction {
  margin-top: 1.3rem;
  font-weight: 500;
  font-size: max(1.6rem, 14px);
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;
}

.c-menu-item {
  position: relative;
  width: 100%;
  min-height: 50.4rem;
  background-color: #ffffff;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.08);
}

.c-menu-item .c-menu-item__wrapper,
.c-menu-item .c-menu-item__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.c-menu-item .c-menu-item__wrapper:hover::before,
.c-menu-item .c-menu-item__link:hover::before {
  background-color: #ffffff;
}

.c-menu-item .c-menu-item__wrapper:hover::after,
.c-menu-item .c-menu-item__link:hover::after {
  background-image: url(/assets/img/common/triangle-arrow-main.svg);
}

.c-menu-item .c-menu-item__wrapper:hover .c-menu-item__body,
.c-menu-item .c-menu-item__link:hover .c-menu-item__body {
  background-color: #78BABA;
}

.c-menu-item .c-menu-item__wrapper:hover .c-menu-item__title,
.c-menu-item .c-menu-item__link:hover .c-menu-item__title {
  color: #ffffff;
}

.c-menu-item .c-menu-item__wrapper:hover .c-menu-item__title::after,
.c-menu-item .c-menu-item__link:hover .c-menu-item__title::after {
  background-image: url(/assets/img/common/card-line-white.webp);
}

.c-menu-item .c-menu-item__wrapper:hover .c-menu-item__detail,
.c-menu-item .c-menu-item__link:hover .c-menu-item__detail {
  color: #ffffff;
}

.c-menu-item .c-menu-item__wrapper::before,
.c-menu-item .c-menu-item__link::before {
  content: "";
  position: absolute;
  right: -3rem;
  bottom: -3rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: #78BABA;
  transition: background-color 0.3s;
}

.c-menu-item .c-menu-item__wrapper::after,
.c-menu-item .c-menu-item__link::after {
  content: "";
  position: absolute;
  right: 1rem;
  bottom: 1.1rem;
  width: 2rem;
  height: 2rem;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s;
}

.c-menu-item .c-menu-item__img {
  width: 100%;
  aspect-ratio: 350/240;
}

.c-menu-item .c-menu-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-menu-item .c-menu-item__body {
  width: 100%;
  height: 100%;
  padding: 4rem 3rem;
  background-color: #ffffff;
  transition: background-color 0.3s;
}

.c-menu-item .c-menu-item__title {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 100%;
  transition: color 0.3s;
}

.c-menu-item .c-menu-item__title .small {
  font-size: 1.8rem;
}

.c-menu-item .c-menu-item__title::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/card-line.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  transition: background-image 0.3s;
}

.c-menu-item .c-menu-item__detail {
  margin-top: 2rem;
  transition: color 0.3s;
}

.c-menu-item.c-menu-item--page {
  min-height: 49.1rem;
}

.c-menu-item.c-menu-item--page .c-menu-item__body {
  width: 100%;
  height: 100%;
  padding: 3rem 2rem 5rem;
  background-color: #ffffff;
  transition: background-color 0.3s;
}

.c-menu-item.c-menu-item--page .c-menu-item__title {
  padding-bottom: 0;
}

.c-menu-item.c-menu-item--page .c-menu-item__title::after {
  display: none;
}

.c-modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(51, 51, 51, 0.7);
  z-index: 20;
}

.c-modal__overlay {
  position: absolute;
  inset: 0;
}

.c-modal__body {
  display: inline-block;
  width: 80rem;
  max-height: 90vh;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-modal__content {
  padding: 10rem 7.5rem;
}

.c-modal__title {
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}

.c-modal__text {
  font-weight: 500;
  font-size: max(1.4rem, 12px);
  line-height: 170%;
  letter-spacing: 0.05em;
}

.c-modal__text + .c-modal__text {
  margin-top: 1.7em;
}

.c-modal__subtitle {
  position: relative;
  margin: 6rem auto 2rem;
  padding-bottom: 0.5rem;
  width: max-content;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: #DEB23F;
}

.c-modal__subtitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-yellow-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.c-modal__message {
  margin-top: 6rem;
  position: relative;
  background-color: #ffffff;
  border: 3px solid #DEB23F;
  border-radius: 2rem;
  box-shadow: 1rem 1rem 4rem rgba(0, 0, 0, 0.04);
  padding: 5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50rem;
  margin-inline: auto;
}

.c-modal__message::after {
  content: "";
  position: absolute;
  right: -7.1rem;
  bottom: -1.5rem;
  width: 15rem;
  aspect-ratio: 150/98;
  background-image: url(/assets/img/common/cta-deco.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-modal__message-text {
  position: relative;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}

.c-modal__message-text .strong {
  font-size: 3.2rem;
  line-height: 150%;
  color: #27837F;
  text-decoration: underline #FFE279 0.7rem;
  text-underline-offset: -0.2rem;
}

.c-modal__close-button {
  display: block;
  margin-top: 4rem;
  margin-inline: auto;
  padding: 1.4rem 2rem 1.6rem;
  width: max-content;
  min-width: 20rem;
  background-color: #58AFAB;
  transition: background-color 0.3s;
  border-radius: 10rem;
  font-weight: 700;
  font-size: max(1.4rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.c-modal__close-button:hover {
  background-color: #8ABFBD;
}

.c-modal__close-button.c-modal__close-button--secondary {
  background-color: #4086AE;
}

.c-modal__close-button.c-modal__close-button--secondary:hover {
  background-color: #83B6D2;
}

.c-modal__insurance-table {
  margin-inline: auto;
  width: 100%;
  max-width: 50rem;
  border-collapse: collapse;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f6f6f6;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #333333;
}

.c-modal__insurance-table tr {
  display: grid;
  grid-template-columns: 11rem 1fr 1fr 1fr;
  grid-template-rows: 5rem;
}

.c-modal__insurance-table th,
.c-modal__insurance-table td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-modal__insurance-table thead tr th {
  background-color: #C1E3E1;
}

.c-modal__insurance-table tr + tr {
  border-top: 0.1rem solid #D5DCDC;
}

.c-modal__insurance-table th + td,
.c-modal__insurance-table td + td {
  border-left: 0.1rem solid #D5DCDC;
}

.c-modal__menu-list {
  margin-top: 4rem;
  max-width: 50rem;
  margin-inline: auto;
}

.c-modal__menu-item {
  display: flex;
}

.c-modal__menu-item dt,
.c-modal__menu-item dd {
  padding: 0 3rem 1rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.c-modal__menu-item dt {
  width: 20rem;
  flex-shrink: 0;
  border-bottom: 2px solid #58AFAB;
}

.c-modal__menu-item dd {
  border-bottom: 2px solid #dcdcdc;
  width: 100%;
}

.c-modal__menu-item:not(:first-child) {
  margin-top: 2rem;
}

.c-nav__list {
  display: flex;
  align-items: center;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.c-nav__item {
  position: relative;
}

.c-nav__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-image: url(/assets/img/common/nav-item-line.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.c-nav__item a {
  display: block;
  padding: 0.6rem 1.8rem;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  transition: opacity 0.3s;
}

.c-nav__item a:hover {
  opacity: 0.7;
}

.c-nav__buttons {
  margin-left: 2rem;
}

.c-nav__button-list {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.c-nav__button-item a {
  display: block;
  padding: 1.2rem 3rem;
  background-color: #58AFAB;
  border-radius: 10rem;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  transition: background-color 0.3s;
}

.c-nav__button-item a:hover {
  background-color: #8ABFBD;
}

.c-nav__button-item.c-nav__button-item--secondary-color a {
  background-color: #FAC231;
}

.c-nav__button-item.c-nav__button-item--secondary-color a:hover {
  background-color: #F4943B;
}

.c-nav__button-item.c-nav__button-item--store-color a {
  background-color: #7AB1CA;
}

.c-nav__button-item.c-nav__button-item--store-color a:hover {
  opacity: 0.7;
}

.c-page-card-text {
  font-weight: 500;
  font-size: max(1.4rem, 12px);
  line-height: 150%;
  letter-spacing: 0.05em;
}

.c-page-card-text.c-page-card-text--ls0 {
  letter-spacing: 0;
}

.c-page-cta {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f2f2f2;
}

.c-page-cta::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-main-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.c-page-cta__content {
  width: 100%;
  max-width: 72rem;
  margin-inline: auto;
  padding: 4rem;
  background-color: #ffffff;
  border: 3px solid #55A5A2;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.c-page-cta__title {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.c-page-cta__button {
  text-align: center;
}

.c-page-mv {
  width: 100%;
  padding-top: 9rem;
  padding-bottom: 8.5rem;
  background-image: url(/assets/img/common/page-mv-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.c-page-mv__inner {
  position: relative;
}

.c-page-mv__breadcrumb {
  position: absolute;
  left: 25px;
  bottom: -2rem;
  translate: 0 100%;
}

.c-sec-heading.c-sec-heading--case .c-sec-heading__wrapper {
  gap: 2rem;
}

.c-sec-heading.c-sec-heading--case .c-sec-heading__main {
  font-size: 2.8rem;
  line-height: 100%;
  letter-spacing: 0;
}

.c-shop-item {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
}

.c-shop-item__img {
  width: 100%;
  aspect-ratio: 350/240;
}

.c-shop-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-shop-item__body {
  width: 100%;
  height: 100%;
  padding: 4rem 3rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-shop-item.c-shop-item--4col .c-shop-item__body {
  padding: 3rem 2rem;
}

.c-shop-item.c-shop-item--4col .c-shop-item__button {
  margin-top: auto;
}

.c-shop-item.c-shop-item--4col .c-shop-item__info {
  margin-bottom: 2.4rem;
}

.c-shop-item__name {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: 700;
  font-size: 2em;
  line-height: 100%;
}

.c-shop-item__name::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/card-line.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.c-shop-item__info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.c-shop-item__links {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.p-top-shop__content .c-shop-item__links {
  margin-top: auto;
}

.c-shop-item__link a {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: max-content;
  padding: 1.2rem 1.5rem 1.2rem 2rem;
  background-color: #ffffff;
  border: 1px solid #58AFAB;
  border-radius: 10rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #58AFAB;
  transition: background-color 0.3s, color 0.3s;
}

.c-shop-item__link a::after {
  content: "";
  display: block;
  width: 1.5rem;
  aspect-ratio: 1/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s;
}

.c-shop-item__link a:hover {
  background-color: #58AFAB;
  color: #ffffff;
}

.c-shop-item__link.c-shop-item__link--tel a::after {
  background-image: url(/assets/img/common/icon-tel.svg);
}

.c-shop-item__link.c-shop-item__link--tel a:hover::after {
  background-image: url(/assets/img/common/icon-tel-white.svg);
}

.c-shop-item__link.c-shop-item__link--line a {
  text-transform: uppercase;
}

.c-shop-item__link.c-shop-item__link--line a::after {
  background-image: url(/assets/img/common/icon-calender.svg);
}

.c-shop-item__link.c-shop-item__link--line a:hover::after {
  background-image: url(/assets/img/common/icon-calender-white.svg);
}

.c-shop-item__link.c-shop-item__link--review a {
  text-transform: uppercase;
}

.c-shop-item__link.c-shop-item__link--review a::after {
  background-image: url(/assets/img/common/icon-review.svg);
}

.c-shop-item__link.c-shop-item__link--review a:hover::after {
  background-image: url(/assets/img/common/icon-review-white.svg);
}

/* 一覧ページ用スタイル */

.c-shop-item.c-shop-item--page .c-shop-item__info {
  margin-top: 2rem;
}

.c-shop-item.c-shop-item--page .c-shop-item__links {
  gap: 0.5rem;
  flex-wrap: wrap;
}

.c-shop-item.c-shop-item--page .c-shop-item__link a {
  padding: 1.2rem 0.9rem;
  font-size: max(1.2rem, 10px);
  gap: 0.5rem;
}

.c-shop-item.c-shop-item--page .c-shop-item__link a::after {
  width: max(1.2rem, 10px);
}

.c-shop-item__features {
  margin-top: auto;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.c-shop-item__feature {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #FDE3D9;
  font-weight: 700;
  font-size: max(1.2rem, 10px);
  line-height: 120%;
  letter-spacing: 0.05em;
  text-align: center;
  color: #EF7653;
}

.c-shop-item__feature::before {
  content: "";
  display: block;
  width: 3.2rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/icon-kids.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-shop-item__feature:nth-child(2) {
  background-color: #FFF4BB;
  color: #F3B517;
}

.c-shop-item__feature:nth-child(2)::before {
  background-image: url(/assets/img/common/icon-staff.webp);
}

.c-sub-heading {
  position: relative;
  width: max-content;
  margin-inline: auto;
  padding-inline: 2.1rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-align: center;
}

.c-sub-heading::before,
.c-sub-heading::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  width: 1.1rem;
  height: 2.6rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c-sub-heading::before {
  left: 0;
  background-image: url(/assets/img/common/sub-heading-l.webp);
}

.c-sub-heading::after {
  right: 0;
  background-image: url(/assets/img/common/sub-heading-r.webp);
}

.c-sub-heading .strong {
  font-size: 3.2rem;
  line-height: 120%;
}

.c-sub-heading.c-sub-heading--store-reserve {
  color: #27837F;
  font-weight: 500;
}

.c-sub-heading.c-sub-heading--store-reserve::before {
  background-image: url(/assets/img/common/sub-heading-main-l.webp);
}

.c-sub-heading.c-sub-heading--store-reserve::after {
  background-image: url(/assets/img/common/sub-heading-main-r.webp);
}

.c-text {
  font-size: max(1.6rem, 12px);
  font-weight: 400;
  line-height: 200%;
  letter-spacing: 0.05em;
}

.c-text.c-text--lh150 {
  line-height: 150%;
}

.c-text.c-text--lh180 {
  line-height: 180%;
}

.c-text.c-text--fw500 {
  font-weight: 500;
}

.c-text.c-text--center {
  text-align: center;
}

.c-text.c-text--reverse {
  color: #ffffff;
}

/* PCだけのmodifier */

.c-text.c-text--pc-center {
  text-align: center;
}

.c-text.c-text--pc-fw500 {
  font-weight: 500;
}

.c-text.c-text--pc-ls0 {
  letter-spacing: 0;
}

/* SPだけのmodifier */

.c-voice-item {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.08);
}

.c-voice-item__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.c-voice-item__link:hover::before {
  background-color: #ffffff;
}

.c-voice-item__link:hover::after {
  background-image: url(/assets/img/common/triangle-arrow-main.svg);
}

.c-voice-item__link:hover .c-voice-item__content {
  background-color: #78BABA;
}

.c-voice-item__link:hover .c-voice-item__dl > * {
  color: #ffffff;
}

.c-voice-item__link:hover .c-voice-item__info::after {
  background-image: url(/assets/img/common/card-line-white.webp);
}

.c-voice-item__link::before {
  content: "";
  position: absolute;
  right: -3rem;
  bottom: -3rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: #78BABA;
  transition: background-color 0.3s;
}

.c-voice-item__link::after {
  content: "";
  position: absolute;
  right: 1rem;
  bottom: 1.1rem;
  width: 2rem;
  height: 2rem;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s;
}

.c-voice-item__img {
  width: 100%;
  aspect-ratio: 350/240;
}

.c-voice-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-voice-item__content {
  width: 100%;
  height: 100%;
  padding: 4rem 3rem 11.2rem 3rem;
  background-color: #ffffff;
  transition: background-color 0.3s;
}

.c-voice-item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.c-voice-item__info::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/card-line.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  transition: background-image 0.3s;
}

.c-voice-item__parson {
  padding: 0.8rem 2rem 1rem;
  background-color: #DCEDEC;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #58AFAB;
}

.c-voice-item__dl {
  display: flex;
  align-items: center;
}

.c-voice-item__dl dt,
.c-voice-item__dl dd:not(.c-text) {
  font-weight: 500;
  font-size: 2rem;
  line-height: 100%;
  transition: color 0.3s;
}

.c-voice-item__dl dt {
  display: flex;
  min-width: 11rem;
}

.c-voice-item__dl dt::after {
  content: "：";
  display: block;
  margin-left: auto;
}

.c-voice-item__body {
  padding-top: 2rem;
}

.c-voice-item__dl.c-voice-item__dl--flex-col {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.c-voice-item__to-store {
  position: absolute;
  bottom: 5rem;
  left: 2.4rem;
}

.c-voice-item__button {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: max-content;
  padding: 1.2rem 1.5rem 1.2rem 2rem;
  background-color: #ffffff;
  border: 1px solid #58AFAB;
  border-radius: 10rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #58AFAB;
  transition: background-color 0.3s, color 0.3s;
}

.c-voice-item__button::after {
  content: "";
  display: block;
  width: 1.5rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/triangle-arrow-main.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s;
}

.c-voice-item__button:hover {
  background-color: #58AFAB;
  color: #ffffff;
}

.c-voice-item__button:hover::after {
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
}

.p-bottom-cta {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.p-bottom-cta__inner {
  width: 100%;
  max-width: 1050px;
  margin-inline: auto;
}

.p-bottom-cta__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.p-bottom-cta__item {
  aspect-ratio: 500/400;
  overflow: hidden;
}

.p-bottom-cta__item:nth-child(1) {
  border-radius: 2rem 0 0 2rem;
}

.p-bottom-cta__item:nth-child(2) {
  border-radius: 0 2rem 2rem 0;
}

.p-bottom-cta__item a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-bottom-cta__item a::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(5, 5, 5, 0.4);
  transition: background-color 0.3s;
}

.p-bottom-cta__item a:hover::after {
  background-color: rgba(5, 5, 5, 0.7);
}

.p-bottom-cta__item a:hover .c-button {
  background-color: #8ABFBD;
}

.p-bottom-cta__item:nth-child(1) a {
  background-image: url(/assets/img/top/bottom-cta-recruit.webp);
}

.p-bottom-cta__item:nth-child(2) a {
  background-image: url(/assets/img/top/bottom-cta-property.webp);
}

.p-bottom-cta__body {
  position: absolute;
  left: 4rem;
  bottom: 3.8rem;
  z-index: 1;
}

.p-bottom-cta__logo {
  width: 7.9rem;
  aspect-ratio: 79/17;
}

.p-bottom-cta__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-bottom-cta__title {
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-bottom-cta__text {
  margin-top: 2rem;
}

.p-bottom-cta__button {
  margin-top: 2rem;
}

.c-button.c-button--small {
  padding: 1.2rem 1.5rem 1.2rem 3rem;
  gap: 0.5rem;
  font-size: 1.4rem;
}

.c-button.c-button--small::after {
  width: 1.4rem;
  height: 1.4rem;
}

.p-bottom-cta__sponsor {
  margin-top: 6rem;
}

.p-bottom-cta__sponsor-img {
  width: 100%;
  aspect-ratio: 1000/270;
}

.p-bottom-cta__sponsor-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-case-main {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-case-main__introduction {
  margin-top: 5rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;
}

.p-case-main__anchors {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 16.5rem));
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
}

.p-case-main__anchor a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.2rem 1.6rem;
  background-color: #ffffff;
  border: 1px solid #58AFAB;
  border-radius: 10rem;
  transition: color 0.3s, background-color 0.3s;
  font-weight: 700;
  font-size: max(1.4rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #58AFAB;
  white-space: nowrap;
}

.p-case-main__anchor a .small {
  font-size: max(1.2rem, 10px);
}

.p-case-main__anchor a::after {
  content: "";
  display: block;
  width: 1.2rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/arrow-nml-main.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  rotate: 90deg;
  flex-shrink: 0;
  transition: background-image 0.3s;
}

.p-case-main__anchor a:hover {
  color: #ffffff;
  background-color: #58AFAB;
}

.p-case-main__anchor a:hover::after {
  background-image: url(/assets/img/common/arrow-nml-white.webp);
}

.p-case-main__body {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.p-case-main__content {
  position: relative;
  width: 100%;
  padding: 0 5rem 4rem;
}

.p-case-main__content::before {
  content: "";
  position: absolute;
  inset: 2.8rem 0 0 0;
  background-color: #f5f5f5;
  z-index: -1;
  border-radius: 2rem;
}

.p-case-main__content-title {
  position: relative;
  padding: 1rem 3rem;
  width: max-content;
  min-width: 35.3rem;
  margin-inline: auto;
  background-color: #58AFAB;
  border-radius: 2rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-align: center;
  color: #ffffff;
}

.p-case-main__content-title .small {
  font-size: 2rem;
}

.p-case-main__content-title::after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 50%;
  translate: -50% 100%;
  width: 2.9rem;
  height: 1.5rem;
  background-color: #58AFAB;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.p-case-main__content-list {
  margin-top: 4.4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 2rem 1.5rem;
}

.p-case-main__content-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.2rem 0.5rem;
  background-color: #DCEDEC;
  border-radius: 1rem;
  font-weight: 700;
  font-size: max(1.6rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #27837F;
  transition: opacity 0.3s;
}

.p-case-main__content-item a::after {
  content: "";
  display: block;
  width: 1.8rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/triangle-arrow-main2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-case-main__content-item a:hover {
  opacity: 0.7;
}

.p-case-side__heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 100%;
}

.p-case-side__heading::before {
  content: "";
  display: block;
  width: 5rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/icon-note.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-case-side__content {
  margin-top: 2rem;
  padding: 3rem 3rem 4rem;
  border-radius: 2rem;
  background-color: #f5f5f5;
}

.p-case-side__content-title {
  position: relative;
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  padding-bottom: 2rem;
}

.p-case-side__content-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-main-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-case-side__main-list {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.p-case-side__main-item {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-case-side__child-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.p-case-side__child-item a {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 2.4rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.p-case-side__child-item a::before {
  content: "";
  display: block;
  width: 1.2rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/arrow-nml-black.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-case-side__child-item a:hover {
  opacity: 0.7;
}

.p-case-side__link {
  margin-top: 4rem;
  width: 100%;
  aspect-ratio: 276/175;
  border-radius: 1rem;
  overflow: hidden;
}

.p-case-side__link a {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  background-image: url(/assets/img/case/store.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-case-side__link a p {
  position: relative;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-case-side__link a::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(5, 5, 5, 0.3);
  transition: background-color 0.3s;
}

.p-case-side__link a:hover::before {
  background-color: rgba(5, 5, 5, 0.6);
}

.p-case-side__link-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 2.2rem;
  border-radius: 2.2rem;
  border: 2px solid #ffffff;
  background-color: #58AFAB;
  font-weight: 700;
  font-size: max(1.2rem, 10px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-case-side__link-button::after {
  content: "";
  display: block;
  width: max(1.2rem, 10px);
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-case__wrapper {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.p-company__policy {
  padding-top: 5rem;
  padding-bottom: 8rem;
}

.p-company__heading {
  position: relative;
  width: max-content;
  padding-bottom: 0.9rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.p-company__heading .num {
  font-size: 3.2rem;
  color: #FAC231;
}

.p-company__heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-blue-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-company__heading.p-company__heading--center {
  margin-inline: auto;
}

.p-company__policy-list {
  width: 100%;
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 5rem;
}

.p-company__policy-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-company__policy-title {
  position: relative;
  width: 100%;
  max-width: 29rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #4086AE;
  box-shadow: 0.4rem 0.4rem 0 #4086AE;
}

.p-company__policy-title-en {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #FAC231;
}

.p-company__policy-title-ja {
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.p-company__policy-item-body {
  margin-top: -3.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5.5rem 2rem 3rem;
  background-color: #E3F1F9;
  border-radius: 2rem;
}

.p-company__policy-item-text {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}

.p-company__message {
  padding-top: 5rem;
  padding-bottom: 6rem;
  background-color: #f5f5f5;
}

.p-company-message__wrapper {
  display: flex;
  gap: 3rem;
}

.p-company-message__img {
  width: 51rem;
  aspect-ratio: 510/456;
  border-radius: 2rem;
  overflow: hidden;
  flex-shrink: 0;
}

.p-company-message__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-company__message-body {
  display: flex;
  flex-direction: column;
}

.p-company__message-detail {
  margin-top: 2rem;
}

.p-company__message-text + .p-company__message-text {
  margin-top: 1.8em;
}

.p-company__message-name {
  margin-top: 5rem;
  margin-left: auto;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
}

.p-company__message-name .post {
  margin-right: 1rem;
  color: #4086AE;
}

.p-company__info {
  padding-top: 8rem;
  padding-bottom: 10rem;
}

.p-company__info-list {
  margin-top: 5rem;
}

.p-contact__links {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  max-width: calc(105rem + 50px);
  padding-right: 25px;
  padding-left: 25px;
  margin-inline: auto;
}

.c-contact-links {
  padding: 6rem 5rem;
  background-color: #f5f5f5;
  border-radius: 2rem;
}

.c-contact-links__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 2.5rem;
}

.p-contact__body:has(.p-contact__alert) {
  position: relative;
  padding-top: 20.6rem;
}

.p-contact__alert {
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50%;
  width: 100%;
  max-width: calc(105rem + 50px);
  padding-right: 25px;
  padding-left: 25px;
}

.p-contact__alert-body {
  padding: 3rem 5rem 4rem;
  border-radius: 2rem;
  background-color: #DCEDEC;
  border: 2px solid #58AFAB;
}

.p-contact__alert-body::before,
.p-contact__alert-body::after {
  content: "";
  position: absolute;
  left: 50%;
  translate: -50% 100%;
  width: 3rem;
  bottom: 0.2rem;
  background-color: #DCEDEC;
  height: 3.2rem;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.p-contact__alert-body::after {
  bottom: 0.4rem;
  background-color: #DCEDEC;
}

.p-contact__alert-body::before {
  bottom: 0;
  background-color: #58AFAB;
}

.p-contact__alert-text {
  font-weight: 500;
  font-size: 2rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.p-contact__alert-text.p-contact__alert-text--small {
  font-size: 1.8rem;
}

.p-contact__alert-list {
  margin-top: 1.1rem;
  display: flex;
  flex-direction: column;
}

.p-contact__alert-caution {
  margin-top: 2.1rem;
  width: max-content;
  min-width: 84rem;
  padding: 3rem;
  background-color: #ffffff;
  border-radius: 2rem;
}

.p-contact__form {
  background-color: #f5f5f5;
  padding: 12.4rem 25px 10rem;
}

.p-contact__form.p-contact__form--property {
  padding-top: 6rem;
}

.p-contact__form-content {
  width: 100%;
  max-width: 80rem;
  margin-inline: auto;
  padding: 9rem 12.5rem 5rem;
  background-color: #ffffff;
  border-radius: 2rem;
  box-shadow: 0 0.7rem 2.2rem rgba(0, 0, 0, 0.08);
}

.p-contact__form-content.p-contact__form-content--property {
  padding-top: 5rem;
}

.p-contact__title {
  position: relative;
  width: max-content;
  max-width: 100%;
  margin-inline: auto;
  padding-bottom: 0.9rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.p-contact__title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-main-full.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-contact__title.p-contact__title--secondary {
  color: #4086AE;
}

.p-contact__title.p-contact__title--secondary::after {
  background-image: url(/assets/img/common/dot-line-blue-full.png);
}

.p-contact__property-content {
  padding-top: 5rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.p-course-worry-check {
  position: relative;
  padding-bottom: 6rem;
}

.p-course-worry-check::before {
  content: "";
  width: 89.9305555556vw;
  position: absolute;
  top: 2rem;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  border-radius: 1rem 2rem 2rem 1rem;
  z-index: index -1;
}

.p-course-worry-check__inner {
  position: relative;
  width: 134.5rem;
  max-width: 100%;
  padding-inline: 5rem;
  margin-left: auto;
}

.p-course-worry-check__title {
  position: relative;
  margin-left: 14.7rem;
  width: max-content;
  padding: 0.7rem 3rem 0.9rem;
  background-color: #58AFAB;
  border-radius: 2rem;
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-course-worry-check__title::after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 50%;
  translate: -50% 100%;
  width: 2.6rem;
  height: 1.3rem;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  background-color: #58AFAB;
}

.p-course-worry-check__content {
  margin-top: 2.3rem;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.p-course-worry-check__list-wrapper {
  margin-left: auto;
  display: flex;
  gap: 4rem;
}

.p-course-worry-check__list {
  width: 100%;
}

.p-course-worry-check__list li label {
  display: inline-block;
  position: relative;
  padding: 1.65rem 0 1.65rem 3.4rem;
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
}

.p-course-worry-check__list li label::before,
.p-course-worry-check__list li label::after {
  display: block;
  position: absolute;
  content: "";
}

.p-course-worry-check__list li label::before {
  width: 2rem;
  height: 2rem;
  border: 1.5px solid #0D3E3C;
  border-radius: 0.3rem;
  left: 0;
}

.p-course-worry-check__list li label::after {
  opacity: 0;
  width: 2.2rem;
  height: 2.2rem;
  background-image: url(/assets/img/common/icon-check-no-frame-main.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  top: 1.2rem;
  left: 0.2rem;
}

.p-course-worry-check__list li input:checked + label::after {
  opacity: 1;
}

.p-course-worry-check__img-area {
  position: relative;
  width: 52.7rem;
  max-width: 100%;
  aspect-ratio: 527/480;
  border: 5px solid #ffffff;
  border-radius: 2rem;
  background-color: #f5f5f5;
  box-shadow: 0 0.7rem 2.2rem rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.p-course-worry-check__img {
  width: 100%;
  height: 100%;
}

.p-course-worry-check__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-course-worry-check__where {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-course-worry-check__where img {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-course-worry-check__where img.is-active {
  opacity: 1;
}

.p-courses {
  width: 100%;
}

.p-courses__select {
  width: 100%;
  padding-top: 3rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #DCEDEC;
}

.p-courses__select-list {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8rem;
}

.p-courses__select-item {
  border-radius: 2rem 2rem 0 0;
  background-color: #ffffff;
  border-top: 2px solid #58AFAB;
  border-right: 2px solid #58AFAB;
  border-left: 2px solid #58AFAB;
  overflow: hidden;
}

.p-courses__select-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 4rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 100%;
  letter-spacing: 0;
  text-align: center;
}

.p-courses__select-link::before {
  content: "";
  display: block;
  width: 3.5rem;
  aspect-ratio: 1/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-courses__select-link::after {
  content: "";
  display: block;
  width: 2rem;
  aspect-ratio: 1/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

span.p-courses__select-link {
  color: #333333;
}

span.p-courses__select-link::after {
  background-image: url(/assets/img/common/arrow-nml-main.webp);
  rotate: 90deg;
}

a.p-courses__select-link {
  color: #ffffff;
  background-color: #58AFAB;
}

a.p-courses__select-link::after {
  content: "";
  display: block;
  width: 2rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/arrow-nml-white.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-courses__select-link.p-courses__select-link--worry::before {
  background-image: url(/assets/img/common/icon-check-main.webp);
}

.p-courses__select-link.p-courses__select-link--course::before {
  background-image: url(/assets/img/common/icon-list-main.webp);
}

a.p-courses__select-link.p-courses__select-link--worry::before {
  background-image: url(/assets/img/common/icon-check-white.webp);
}

a.p-courses__select-link.p-courses__select-link--course::before {
  background-image: url(/assets/img/common/icon-list-white.webp);
}

.p-courses__container {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.p-courses__heading {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 100%;
  letter-spacing: 0;
}

.p-courses__heading::before {
  content: "";
  display: block;
  width: 5rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/icon-list-main.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-courses__heading.p-courses__heading--check::before {
  background-image: url(/assets/img/common/icon-check-main-bicolor.webp);
}

.p-courses__introduction {
  margin-top: 3rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 180%;
  letter-spacing: 0.05em;
}

.p-courses__worry-content + .p-courses__worry-content {
  margin-top: 6rem;
}

.p-courses__worry-content {
  margin-top: 2rem;
}

.p-courses__list-content:first-of-type {
  margin-top: 5.2rem;
  padding-top: 0;
}

.p-courses__list-content {
  padding-top: 6rem;
  padding-bottom: 5.5rem;
  background-color: #ffffff;
}

.p-course-list-content:nth-of-type(even) {
  background-color: #f5f5f5;
}

.p-course-list-content__heading {
  position: relative;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
  padding-bottom: 1rem;
}

.p-course-list-content__heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-main-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-course-list-content__heading .small {
  font-size: 2rem;
}

.p-course-list-content__list {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 6rem 5rem;
}

.p-courses-recommend-item {
  display: none;
}

.p-courses-recommend-item.is-active {
  display: block;
}

.p-courses-recommend-item__link {
  display: flex;
  background-color: #ffffff;
  outline: 3px solid transparent;
  border-radius: 1rem;
  transition: outline 0.3s;
  overflow: hidden;
}

.p-courses-recommend-item__link:hover {
  outline: 3px solid #58AFAB;
}

.p-courses-recommend-item__img {
  width: 18rem;
  aspect-ratio: 180/150;
  flex-shrink: 0;
  overflow: hidden;
}

.p-courses-recommend-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-courses-recommend-item__body {
  background-color: #ffffff;
  padding: 1rem 2rem;
}

.p-courses-recommend-item__wrap {
  display: flex;
  align-items: center;
  gap: 1rem 1.5rem;
  flex-wrap: wrap;
}

.p-courses-recommend-item__title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-courses-recommend-item__category {
  background-color: #ffffff;
  padding: 0.8rem 1rem;
  border: 1px solid #58AFAB;
  border-radius: 10rem;
  font-weight: 700;
  font-size: max(1.2rem, 10px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #58AFAB;
}

.p-courses-recommend-item__detail {
  margin-top: 1rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.p-courses-recommend-item__more {
  display: none;
}

.p-courses-recommend__container {
  padding: 5rem 5rem 6rem;
  background-color: #DCEDEC;
  border-radius: 2rem;
}

.p-courses-recommend__heading {
  width: max-content;
  margin-inline: auto;
  padding-bottom: 1.3rem;
  position: relative;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.p-courses-recommend__heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-main-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-courses-recommend__list {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 3rem 5rem;
}

.p-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* headerの高さ */
  padding-top: 7.6rem;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  z-index: 5;
}

.p-drawer__overlay {
  width: 100%;
  height: 100%;
}

.p-drawer__container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 3rem 2rem;
  transform: translateX(100%);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.p-drawer__container::-webkit-scrollbar {
  display: none;
}

.p-drawer__body {
  width: 100%;
  margin-inline: auto;
}

.p-drawer__list {
  display: flex;
  flex-direction: column;
}

.p-drawer__item {
  position: relative;
  border-bottom: 1px solid #AEAEAE;
}

/* 全aタグ共通 */

.p-drawer__item a {
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
}

.p-drawer__item a::after {
  content: "";
  display: block;
  margin-left: auto;
  width: 0.8rem;
  height: 1rem;
  background-image: url(/assets/img/common/triangle-arrow-black.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-drawer__item a:hover {
  opacity: 0.7;
}

/* メインリストのaタグのみ */

.p-drawer__item > a {
  padding: 2rem 2.6rem 2rem 2rem;
  color: #333333;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
}

.p-drawer__item > a:hover {
  opacity: 0.7;
}

.p-drawer__item:has(.p-drawer__stores-list) > a {
  padding-right: 2rem;
  padding-bottom: 1.5rem;
}

.p-drawer__item:has(.p-drawer__stores-list) > a::after {
  display: none;
}

.p-drawer__switch {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.4rem;
  aspect-ratio: 1/1;
  background-color: #8BC2E2;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.p-drawer__switch:hover {
  opacity: 0.7;
}

.p-drawer__switch span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.2rem;
  height: 0.06rem;
  background-color: #ffffff;
  transition: translate 0.3s;
}

.p-drawer__switch span:nth-child(2) {
  transform-origin: center center;
  transform: translate(-50%, -50%) rotate(90deg);
}

.p-drawer__switch.is-open span:nth-child(2) {
  transform: translate(-50%, -50%) rotate(0deg);
}

.p-drawer__stores-list {
  height: 0;
  overflow: hidden;
  padding-right: 2.6rem;
  padding-left: 2rem;
}

.p-drawer__store-item a {
  padding: 0.75rem 0rem 0.75rem 1.5rem;
  font-weight: 500;
  font-size: 1.6em;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-drawer__store-item:first-child a {
  padding-top: 0;
}

.p-drawer__store-item:last-child a {
  padding-bottom: 2rem;
}

.p-drawer__button-list {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.p-drawer__button-item {
  width: 100%;
}

.p-drawer__button-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  width: 100%;
  padding: 2rem 1.5rem 2rem 4rem;
  background-color: #58AFAB;
  border-radius: 1rem;
  transition: background-color 0.3s;
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-drawer__button-item a:hover {
  background-color: #8ABFBD;
}

.p-drawer__button-item a::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-drawer__button-item--secondary-color a {
  background-color: #FAC231;
}

.p-drawer__button-item--secondary-color a:hover {
  background-color: #F4943B;
}

.p-drawer__button-item--store-color a {
  background-color: #7AB1CA;
}

.p-drawer__button-item--store-color a:hover {
  opacity: 0.7;
}

.p-footer-sp-menu {
  width: 100%;
}

.p-footer-sp-menu__links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.p-footer-sp-menu__link a {
  width: 100%;
  height: 100%;
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-footer-sp-menu__link a::before {
  content: "";
  display: block;
  aspect-ratio: 1/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--recruit a {
  background-color: #F2C318;
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--recruit a::before {
  width: 2.2rem;
  background-image: url(/assets/img/common/icon-document.svg);
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--call a {
  background-color: #7AB1CA;
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--call a::before {
  width: 2.2rem;
  background-image: url(/assets/img/common/icon-phone.svg);
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--contact a {
  background-color: #58AFAB;
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--contact a::before {
  width: 2.4rem;
  background-image: url(/assets/img/common/icon-calender-white.svg);
}

.p-footer__content {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-image: url(/assets/img/common/footer-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-footer__body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.p-footer__logo {
  width: 37rem;
  aspect-ratio: 370/74;
}

.p-footer__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-footer__information {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.p-footer__text {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 200%;
  letter-spacing: 0.05em;
}

.p-footer__nav {
  display: flex;
  align-items: flex-start;
  gap: 6.4rem;
  margin-left: auto;
}

.p-footer__nav-list {
  display: flex;
  flex-direction: column;
}

.p-footer__nav-sp-wrapper {
  display: contents;
}

.p-footer__nav-item > a {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.p-footer__nav-item > a::before {
  content: "";
  display: block;
  width: 1.2rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/arrow-nml-black.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-footer__nav-item:first-child > a {
  padding-top: 0;
}

.p-footer__nav-item a {
  transition: opacity 0.3s;
}

.p-footer__nav-item a:hover {
  opacity: 0.7;
}

.p-footer__nav-child-list {
  display: flex;
  flex-direction: column;
}

.p-footer__nav-child-item > a {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.4rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.p-footer__nav-child-item > a::before {
  content: "";
  display: block;
  width: 1rem;
  height: 0.1rem;
  background-color: #333333;
}

.p-footer__copy {
  margin-top: 4rem;
}

.p-footer__copy-text {
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #8d8d8d;
}

.p-footer__sp-menu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}

.p-hamburger {
  position: fixed;
  top: 1.8rem;
  right: 2rem;
  width: 4rem;
  height: 4rem;
  background-color: #8BC2E2;
  border-radius: 10rem;
  z-index: 10;
  cursor: pointer;
}

.p-hamburger div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 1.2rem;
}

.p-hamburger div span {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  transition: transform 0.3s, opacity 0.3s;
}

.p-hamburger div span:first-child {
  top: 0;
}

.p-hamburger.is-active div span:first-child {
  top: 50%;
  transform: translateY(-50%) rotate(-135deg);
}

.p-hamburger div span:nth-child(2) {
  top: 0.6rem;
}

.p-hamburger.is-active div span:nth-child(2) {
  opacity: 0;
}

.p-hamburger div span:last-child {
  top: 1.2rem;
}

.p-hamburger.is-active div span:last-child {
  top: 50%;
  transform: translateY(-50%) rotate(135deg);
}

.p-header {
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 4rem;
  padding-bottom: 3.4rem;
  background-color: rgba(255, 255, 255, 0);
}

.p-header__inner {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 89.5833333333vw;
  padding-right: 25px;
  padding-left: 25px;
  position: relative;
  z-index: 6;
}

.p-header__inner.p-header__inner--wide {
  max-width: 96.5277777778vw;
}

.p-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-header__logo {
  width: 24.7rem;
  aspect-ratio: 247/50;
}

.p-header__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-header__nav {
  margin-left: auto;
}

.p-menu-detail__container {
  margin-top: 5rem;
  padding-bottom: 10rem;
}

.p-menu-detail__heading {
  text-align: center;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-menu-detail__img {
  margin-top: 5rem;
  width: 100%;
  aspect-ratio: 1000/600;
  border-radius: 2rem;
  overflow: hidden;
}

.p-menu-detail__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-menu-detail__body {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.p-menu-detail__body section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.p-menu-detail__body h2 {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 2rem 3rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
}

.p-menu-detail__body h2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 100%;
  background-color: #58AFAB;
}

.p-menu-detail__body h3 {
  padding-bottom: 1.5rem;
  font-weight: 700;
  font-size: 2.4rem;
  color: #27837F;
  border-bottom: 2px solid #dcdcdc;
}

.p-menu-detail__body p {
  font-weight: 500;
  font-size: max(1.6rem, 14px);
  line-height: 180%;
  letter-spacing: 0.05em;
}

.p-menu-detail__body dl {
  display: flex;
}

.p-menu-detail__body dl dt,
.p-menu-detail__body dl dd {
  padding: 0 3rem 1rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.p-menu-detail__body dl dt {
  flex-shrink: 0;
  border-bottom: 2px solid #58AFAB;
}

.p-menu-detail__body dl dd {
  border-bottom: 2px solid #dcdcdc;
  width: 100%;
}

.p-menu-detail__body dl dl:not(:first-child) {
  margin-top: 1rem;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tag-list li {
  background-color: #DCEDEC;
  padding: 0.8rem 2rem 1rem;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: max(1.6rem, 14px);
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.p-news {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.p-news {
  position: relative;
  z-index: 2;
}

.p-news__content {
  width: 100%;
  max-width: 84rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 2.5rem 4rem;
}

.p-news__wrapper {
  display: flex;
  align-items: flex-start;
}

.p-news__heading {
  flex-shrink: 0;
  padding: 0.5rem 0;
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.p-news__link {
  width: 100%;
  display: contents;
  align-items: center;
  transition: opacity 0.3s;
}

.p-news__link:hover {
  opacity: 0.7;
}

.p-news__link:hover .p-news__arrow {
  transform: translateX(0.5rem);
}

.p-news__date {
  flex-shrink: 0;
  padding: 0.8rem 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  color: #8d8d8d;
}

.p-news__title {
  margin-inline: 2rem;
  padding: 0.65rem 0;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-store-about {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-store-about__container {
  display: flex;
  flex-direction: column-reverse;
  gap: 6rem;
}

.p-store-about__wrapper {
  display: flex;
  align-items: center;
  gap: 5rem;
  padding-left: 3.5rem;
  padding-right: 5.5rem;
}

.p-store-about__img {
  width: 100%;
  max-width: 510rem;
  aspect-ratio: 510/420;
  border-radius: 2rem;
  overflow: hidden;
}

.p-store-about__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-about__body {
  width: 100%;
  max-width: 50rem;
}

.p-store-about__title {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.p-store-about__title::after {
  content: "";
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/store/about-line.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-store-about__detail {
  margin-top: 2rem;
}

.p-store-about__name {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row-reverse;
  gap: 1rem;
}

.p-store-about__name .group,
.p-store-about__name .staff {
  font-weight: 700;
  font-size: max(1.6rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
}

.p-store-about__name .staff {
  color: #4086AE;
}

.p-store-about__points {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 2.3rem;
}

.p-store-about__item {
  padding: 3rem;
  border-radius: 2rem;
  border: 3px solid #4086AE;
  box-shadow: 4px 4px 0 #4086AE;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-about__item-icon {
  width: 12rem;
  aspect-ratio: 1/1;
}

.p-store-about__item-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-store-about__item-title {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 100%;
}

.p-store-about__item-detail {
  margin-top: 2rem;
  font-weight: 400;
  font-size: max(1.6rem, 12px);
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-store-access {
  margin-top: 8rem;
  margin-bottom: 10rem;
}

.p-store-access__wrapper {
  margin-top: 3.6rem;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.p-store-access__map {
  width: 100%;
  max-width: 63rem;
  aspect-ratio: 630/420;
  border-radius: 1rem;
  overflow: hidden;
  flex-shrink: 0;
}

.p-store-access__map iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.p-store-access__body {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
}

.p-store-access__title {
  position: relative;
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 100%;
  letter-spacing: 0.05em;
}

.p-store-access__title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.2px;
  background-image: url(/assets/img/store/access-line.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-store-access__detail {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.p-store-access__img {
  width: 100%;
  max-width: 48rem;
  aspect-ratio: 480/240;
  border-radius: 1rem;
  overflow: hidden;
}

.p-store-access__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-accident {
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-store-accident__container {
  padding-left: 5.5rem;
  padding-right: 4.5rem;
}

.p-store-accident__wrapper {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.p-store-accident__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50rem;
  max-width: 100%;
}

.p-store-accident__title {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.p-store-accident__title::before {
  content: "";
  display: block;
  width: 9rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/store/store-accident-medal.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0 0.5rem 3rem rgba(0, 0, 0, 0.08));
}

.p-store-accident__detail {
  margin-top: 2rem;
}

.p-store-accident__button {
  margin-top: 3rem;
}

.p-store-accident__button-link {
  width: max-content;
  padding: 1.4rem 1.5rem 1.4rem 5rem;
  background-color: #4086AE;
  display: inline-flex;
  align-items: center;
  gap: 3rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  border-radius: 10rem;
  transition: background-color 0.3s;
}

.p-store-accident__button-link::after {
  content: "";
  display: block;
  width: 1.6rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-accident__button-link:hover {
  background-color: #83B6D2;
}

.p-store-accident__img {
  width: 51rem;
  max-width: 100%;
  aspect-ratio: 510/420;
  border-radius: 2rem;
  overflow: hidden;
}

.p-store-accident__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-campaign {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-store-campaign__banner {
  margin-top: 3rem;
  margin-inline: auto;
}

.p-store-cta-reserve {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #F9CA6F;
  overflow: hidden;
}

.p-store-cta-reserve__container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-store-faq {
  margin-top: 8rem;
}

.p-store-faq__container {
  width: 100%;
  padding: 5rem 10rem;
  background-color: #f5f5f5;
  border-radius: 2rem;
}

.p-store-faq__list {
  margin-top: 5rem;
}

.p-faq-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.p-faq-list__item {
  padding: 1rem 0;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.p-faq-list__item-question {
  padding: 2rem 5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-faq-list__item-question::before {
  content: "Q";
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 100%;
  color: #58AFAB;
}

.p-faq-list__item-question .button {
  position: relative;
  margin-left: auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #58AFAB;
  flex-shrink: 0;
}

.p-faq-list__item-question .button::before,
.p-faq-list__item-question .button::after {
  content: "";
  width: 1.1rem;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%);
  transition: transform 0.3s, opacity 0.3s;
}

.p-faq-list__item-question .button::before {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.p-faq-list__item-question.is-open .button::before {
  transform: translate(-50%, -50%) rotate(0);
  opacity: 0;
}

.p-faq-list__item-answer {
  height: 0;
  overflow: hidden;
}

.p-faq-list__item-answer p {
  border-top: 0.2rem solid #CDE5E4;
  padding: 2rem 5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-faq-list__item-answer p::before {
  content: "A";
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 100%;
  color: #58AFAB;
}

.p-store-flow {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-image: url(/assets/img/store/flow-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-flow__container {
  margin-top: 5rem;
  max-width: 100rem;
  margin-inline: auto;
}

.p-store-flow__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6.7rem;
}

.p-store-flow__item {
  position: relative;
  width: 100%;
}

.p-store-flow__item + .p-store-flow__item::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -2rem;
  width: 4rem;
  height: 2rem;
  translate: -50% -100%;
  background-image: url(/assets/img/store/flow-arrow.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-flow__item-wrap {
  display: flex;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #ffffff;
}

.p-store-flow__item-img {
  width: 30rem;
  aspect-ratio: 300/193;
  flex-shrink: 0;
}

.p-store-flow__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-flow__item-body {
  width: 100%;
  padding: 3rem 5rem;
}

.p-store-flow__item-heading {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-store-flow__item-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/common/dot-line-main-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-flow__item-num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #58AFAB;
  outline: 0.2rem solid #ffffff;
  outline-offset: -0.5rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-store-flow__item-title {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #58AFAB;
}

.p-store-flow__item-title .small {
  font-size: 2.2rem;
  height: 150%;
}

.p-store-flow__item-detail {
  margin-top: 2rem;
}

.p-store-footer {
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 6rem;
  background-image: url(/assets/img/store/footer-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-footer__body {
  display: flex;
  flex-direction: column;
  gap: 8.7rem;
}

.p-store-footer__information-wrapper {
  margin-top: 3rem;
  display: flex;
  align-items: flex-start;
  gap: 4.5rem;
}

.p-store-footer__information-body {
  display: flex;
  flex-direction: column;
}

.p-store-footer__title {
  position: relative;
  padding-bottom: 0.9rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-store-footer__title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/store/footer-line-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-store-footer__information-tags {
  display: flex;
  gap: 1rem;
}

.p-store-footer__information-tag {
  padding: 1.2rem 1.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #58AFAB;
}

.p-store-footer__information-troubles {
  margin-top: 3rem;
}

.p-store-footer__information-address {
  margin-top: 3rem;
}

.p-store-footer__information-button {
  margin-top: 1.5rem;
}

.p-store-footer__information-link {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem 3rem 1.3rem 3rem;
  background-color: #7AB1CA;
  border-radius: 10rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.p-store-footer__information-link::before {
  content: "";
  display: block;
  width: 2.5rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/icon-tel-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-footer__shop-list {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 2.3rem;
}

.p-store-footer__nav {
  width: 100%;
  margin-top: 6rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-footer__nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/store/footer-line-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-store-footer__nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-store-footer__nav-item a {
  display: block;
  padding-right: 2rem;
  padding-left: 2rem;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
}

.p-store-footer__nav-item a:hover {
  opacity: 0.7;
}

.p-store-footer__copy-text {
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}

.p-store-footer__sp-menu {
  position: fixed;
  left: 0;
  bottom: 0;
}

.p-store-menu {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #f5f5f5;
}

.p-store-menu__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-menu__banner {
  margin-top: 4rem;
}

.p-store-menu__insurance {
  margin-top: 6rem;
}

.p-store-menu__self-pay {
  margin-top: 6rem;
}

.p-store-self-pay__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-self-pay__heading {
  position: relative;
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.p-store-self-pay__heading::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background-image: url(/assets/img/common/dot-line-main-full.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-store-self-pay__introduction {
  margin-top: 2rem;
}

.p-store-self-pay__list {
  margin-top: 5rem;
  display: flex;
  gap: 5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.p-store-self-pay__item {
  max-width: calc((100% - 10rem) / 3);
}

.p-store-message {
  margin-top: 8rem;
}

.p-store-message__container {
  padding-left: 5.5rem;
  padding-right: 4.5rem;
}

.p-store-message__wrapper {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.p-store-message__body {
  display: flex;
  flex-direction: column;
}

.p-store-message__title {
  position: relative;
  width: max-content;
  padding-bottom: 0.9rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.p-store-message__title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-image: url(/assets/img/store/message-line.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.p-store-message__title span {
  font-size: 2rem;
}

.p-store-message__detail {
  margin-top: 2rem;
}

.p-store-message__detail p + p {
  margin-top: 1.8em;
}

.p-store-message__img {
  width: 100%;
  max-width: 51rem;
  aspect-ratio: 510/420;
  border-radius: 2rem;
  overflow: hidden;
  flex-shrink: 0;
}

.p-store-message__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-mv {
  position: relative;
  width: 100%;
  aspect-ratio: 1440/816;
}

.p-store-mv__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.p-store-mv__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-mv__body {
  margin-top: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-mv__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 5rem;
  line-height: 144%;
  color: #ffffff;
}

.p-store-mv__title::before {
  content: "";
  display: block;
  width: 3rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/logo-illust-white.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-mv__time-list {
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 144%;
  color: #ffffff;
}

.p-store-mv__features {
  margin-top: 1.5rem;
  width: 89rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 3rem;
}

.p-store-mv__feature-item {
  width: 100%;
  aspect-ratio: 1/1;
}

.p-store-mv__feature-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 1rem 6rem, rgba(0, 0, 0, 0.07));
}

.p-store-mv-splide {
  margin-top: 6.2rem;
  width: 126.6rem;
  max-width: 100%;
}

.p-store-mv-splide .splide__slide {
  width: 30rem;
  aspect-ratio: 300/210;
  border-radius: 2rem;
  border: 5px solid #ffffff;
  overflow: hidden;
}

.p-store-mv-splide .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-mv-splide .splide__arrow.button {
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  transition: opacity 0.3s;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}

.p-store-mv-splide .splide__arrow.button.prev {
  left: 0;
  translate: -50%;
}

.p-store-mv-splide .splide__arrow.button.next {
  right: 0;
  translate: 50%;
}

.p-store-mv-splide .splide__arrow.button:hover {
  opacity: 0.7;
}

.p-store-mv-splide .button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-store-mv-splide .button.prev::before {
  background-image: url(/assets/img/common/arrow-prev.webp);
}

.p-store-mv-splide .button.next::before {
  background-image: url(/assets/img/common/arrow-next.webp);
}

.p-store-mv-splide .button:disabled::before {
  opacity: 0.5;
}

.p-store-pelvis-menu__content {
  position: relative;
}

.p-store-pelvis-menu__content::before {
  content: "";
  width: 89.9305555556vw;
  position: absolute;
  top: 3rem;
  right: 0;
  bottom: 0;
  background-color: #CFE4E8;
  border-radius: 2rem 0 0 2rem;
  z-index: index -1;
}

.p-store-pelvis-menu__heading {
  width: max-content;
  min-width: 70.1rem;
  margin-inline: auto;
  position: relative;
  padding: 0.7rem 3rem;
  background-color: #58AFAB;
  border-radius: 2rem;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: #ffffff;
}

.p-store-pelvis-menu__heading::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 1px;
  translate: -50% 100%;
  width: 4.2rem;
  height: 1.8rem;
  background-color: #58AFAB;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.p-store-pelvis-menu__inner {
  position: relative;
  width: 134.5rem;
  max-width: 100%;
  padding-inline: 5rem;
  margin-right: auto;
}

.p-store-pelvis-menu__wrapper {
  padding-top: 5.8rem;
  padding-bottom: 6rem;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.p-store-pelvis-menu__img {
  max-width: 46.5277777778vw;
  width: 100%;
  aspect-ratio: 670/410;
  border: 3px solid #ffffff;
  border-radius: 2rem;
  overflow: hidden;
  filter: drop-shadow(0 0.7rem 2.2rem rgba(0, 0, 0, 0.08));
  flex-shrink: 0;
}

.p-store-pelvis-menu__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-pelvis-menu__body {
  width: 100%;
  max-width: 37.0138888889vw;
  margin-right: auto;
}

.p-store-pelvis-menu__title-wrap {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  background-color: #ffffff;
  padding: 1rem 1.4rem;
  border-left: 3px solid #58AFAB;
}

.p-store-pelvis-menu__title {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #27837F;
}

.p-store-pelvis-menu__subtitle {
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.p-store-pelvis-menu__detail {
  margin-top: 2.7rem;
  font-weight: 500;
  font-size: max(1.4rem, 12px);
  line-height: 200%;
  letter-spacing: 0.05em;
}

.p-store-pelvis-menu__detail .strong {
  font-weight: 700;
  color: #27837F;
}

.p-store-pelvis-menu__button {
  margin-top: 2.1rem;
}

.p-store-pelvis-menu__link {
  width: max-content;
  padding: 1.4rem 1.5rem 1.4rem 5rem;
  background-color: #58AFAB;
  display: inline-flex;
  align-items: center;
  gap: 3rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  border-radius: 10rem;
  transition: background-color 0.3s;
}

.p-store-pelvis-menu__link::after {
  content: "";
  display: block;
  width: 1.6rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-pelvis-menu__link:hover {
  background-color: #8ABFBD;
}

/* 産後セクション差異スタイル */

.p-store-pelvis-menu__content:nth-child(2) {
  margin-top: 8rem;
}

.p-store-pelvis-menu__content:nth-child(2)::before {
  top: 3rem;
  left: 0;
  right: auto;
  bottom: 0;
  background-color: #FFF4BB;
  border-radius: 0 2rem 2rem 0;
}

.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__heading,
.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__heading::after {
  background-color: #F3B517;
}

.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__inner {
  margin-right: 0;
  margin-left: auto;
}

.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__wrapper {
  flex-direction: row-reverse;
}

.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__title-wrap {
  border-left: 3px solid #F3B517;
}

.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__title {
  color: #F3B517;
}

.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__link {
  background-color: #FAC231;
}

.p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__link:hover {
  background-color: #F4943B;
}

.p-store-pelvis-message {
  width: 100%;
}

.p-store-pelvis-message__heading {
  width: 100%;
  padding-top: 2.8rem;
  padding-bottom: 2.9rem;
  background-color: #58AFAB;
  box-shadow: 0 0.7rem 2.2rem rgba(0, 0, 0, 0.08);
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
}

.p-store-pelvis-message__heading .strong {
  font-size: 4rem;
  line-height: 100%;
  color: #FFDE64;
}

.p-store-pelvis-message__container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 8rem;
}

.p-store-pelvis-message__container::before {
  content: "";
  position: absolute;
  top: 2rem;
  left: 10.7rem;
  width: 6.5rem;
  height: 9.6rem;
  background-image: url(/assets/img/store/pelvis-message-deco1.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-pelvis-message__container::after {
  content: "";
  position: absolute;
  right: 14.7rem;
  bottom: 3.9rem;
  width: 8rem;
  height: 8rem;
  background-image: url(/assets/img/store/pelvis-message-deco2.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-pelvis-message__text {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 200%;
  letter-spacing: 0.05em;
  text-align: center;
}

.p-store-pelvis-message__text .strong {
  font-weight: 700;
  color: #27837F;
}

.p-store-pelvis-trouble {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 13rem;
  width: 100%;
  background-image: url(/assets/img/store/pelvis-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-pelvis-trouble::after {
  content: "";
  width: 4rem;
  aspect-ratio: 40/70;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  translate: -50%;
  background-image: url(/assets/img/store/pelvis-arrow.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-pelvis-trouble__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-pelvis-trouble__heading {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}

.p-store-pelvis-trouble__heading .strong {
  font-size: 4rem;
  line-height: 150%;
  text-decoration: underline #FFE279 1.2rem;
  text-underline-offset: -0.5rem;
}

.p-store-pelvis-trouble__content {
  margin-top: 4rem;
  display: flex;
  gap: 4rem;
}

.p-store-pelvis-trouble__list {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.p-store-pelvis-trouble__list li {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.p-store-pelvis-trouble__list li::before {
  content: "";
  display: block;
  width: 3.5rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/store/check-icon.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-reserve {
  position: relative;
  margin-top: 10rem;
  padding-top: 30rem;
}

.p-store-reserve::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  aspect-ratio: 1440/470;
  background-image: url(/assets/img/store/reserve-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.p-store-reserve__container {
  padding: 5rem 4.5rem 6rem;
  background-color: #ffffff;
  border-radius: 2rem;
  box-shadow: 1rem 1rem 4rem rgba(18, 41, 51, 0.04);
}

.p-store-reserve__introduction {
  margin-top: 4rem;
  text-transform: uppercase;
}

.p-store-reserve__wrapper {
  margin-top: 2rem;
  display: flex;
  gap: 6rem;
}

.p-store-reserve__content {
  width: 100%;
  max-width: 50rem;
  padding: 3rem 3rem 4rem;
  border-radius: 2rem;
  background-color: #EFF6EF;
}

.p-store-reserve__title {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  color: #59D68C;
}

.p-store-reserve__detail {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.p-store-reserve__button {
  margin-top: 3rem;
  text-align: center;
}

.p-store-reserve__link {
  display: inline-flex;
  padding: 1.2rem 3rem;
  width: max-content;
  min-width: 20.6rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #59D68C;
  border-radius: 10rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-transform: uppercase;
  transition: opacity 0.3s;
}

.p-store-reserve__link:hover {
  opacity: 0.8;
}

.p-store-reserve__link::before {
  content: "";
  display: block;
  width: 2.5rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/icon-calender-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-reserve__content:nth-child(2) {
  background-color: #E0EFEE;
}

.p-store-reserve__content:nth-child(2) .p-store-reserve__title {
  color: #267EA7;
}

.p-store-reserve__content:nth-child(2) .p-store-reserve__link {
  background-color: #7AB1CA;
}

.p-store-reserve__content:nth-child(2) .p-store-reserve__link::before {
  background-image: url(/assets/img/common/icon-tel-white.svg);
}

.p-store-support {
  margin-top: 8rem;
}

.p-store-support__inner {
  width: 100%;
  max-width: 100rem;
  margin-inline: auto;
}

.p-store-support__container {
  position: relative;
  padding: 5rem 6.5rem;
  border-radius: 2rem;
  background-image: url(/assets/img/store/support-bg.webp), linear-gradient(135deg, #FFD9CA, #FFCBBA);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-support__container::after {
  content: "";
  position: absolute;
  top: -2rem;
  right: 10.5rem;
  width: 13rem;
  aspect-ratio: 130/107;
  background-image: url(/assets/img/store/support-deco.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-support__list {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 3rem;
}

.p-store-support__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 4rem 3rem;
  border-radius: 2rem;
  border: 3px solid #FE9F84;
  box-shadow: 0.4rem 0.4rem 0 #FE9F84;
}

.p-store-support__item-img {
  width: 9rem;
  aspect-ratio: 1/1;
}

.p-store-support__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-support__item-title {
  margin-top: 3.2rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  text-align: center;
}

.p-store-support__item-note {
  margin-top: 1.8rem;
  font-weight: 500;
  font-size: max(1.4rem, 12px);
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-store-support__text {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 200%;
  letter-spacing: 0.05em;
}

.p-store-trouble {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 5.5rem;
  background-color: #3f585e;
  background-image: url(/assets/img/store/trouble-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-trouble__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-trouble__heading {
  position: relative;
  padding-inline: 2rem;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: #ffffff;
}

.p-store-trouble__heading::before,
.p-store-trouble__heading::after {
  content: "";
  position: absolute;
  bottom: 0.3rem;
  width: 2rem;
  height: 3rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-store-trouble__heading::before {
  background-image: url(/assets/img/store/trouble-heading-l.webp);
  left: 0;
  translate: -100%;
}

.p-store-trouble__heading::after {
  background-image: url(/assets/img/store/trouble-heading-r.webp);
  right: 0;
  translate: 100%;
}

.p-store-trouble__heading .strong {
  font-size: 4rem;
  line-height: 150%;
}

.p-store-trouble__text {
  margin-top: 2rem;
}

.p-store-trouble__list {
  margin-top: 4rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 4rem 3rem;
}

.p-store-trouble__item {
  padding: 1rem 2.7rem 3rem;
  background-color: #ffffff;
  border-radius: 2rem;
  border: 3px solid #727A79;
  box-shadow: 0 0.2rem 2.7rem rgba(0, 0, 0, 0.03);
}

.p-store-trouble__title-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1rem;
}

.p-store-trouble__img {
  width: 5rem;
  aspect-ratio: 50/80;
}

.p-store-trouble__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-trouble__title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 144%;
  letter-spacing: 0.05em;
}

.p-store-troule__about {
  margin-top: 5.4rem;
  padding: 4rem;
  min-width: 78.7rem;
  background-color: #ffffff;
  border-radius: 2rem;
  border: 3px solid #27837F;
  box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.03);
}

.p-store-troule__about-text {
  margin-top: 2rem;
}

.p-store-troule__about-btn {
  margin-top: 4rem;
  text-align: center;
}

.p-store-voice {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-store-voice__introduction {
  margin-top: 2rem;
}

.p-store-voice__splide {
  margin-top: 5rem;
}

.p-store-voice__splide .splide__arrow.button {
  width: 6rem;
  height: 6rem;
  background-color: transparent;
  transition: opacity 0.3s;
  top: 24rem;
  opacity: 1;
}

.p-store-voice__splide .splide__arrow.button.prev {
  left: -2.5rem;
}

.p-store-voice__splide .splide__arrow.button.next {
  right: -2.5rem;
}

.p-store-voice__splide .splide__arrow.button:hover {
  opacity: 0.7;
}

.p-store-voice__splide .button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 6rem;
  height: 6rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-store-voice__splide .button.prev::before {
  background-image: url(/assets/img/common/arrow-prev.webp);
}

.p-store-voice__splide .button.next::before {
  background-image: url(/assets/img/common/arrow-next.webp);
}

.p-store-voice__splide .button:disabled::before {
  opacity: 0.5;
}

.p-store-voice__button {
  text-align: center;
}

.p-store-voice__review {
  margin-top: 6rem;
}

.p-store-voice__review a {
  transition: opacity 0.3s;
}

.p-store-voice__review a:hover {
  opacity: 0.8;
}

.p-store-voice__review-img {
  width: 100%;
  max-width: 72rem;
  aspect-ratio: 720/362;
  margin-inline: auto;
}

.p-store-voice__review-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-store-voice .splide__track {
  padding-bottom: 5rem;
}

.p-stores {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.p-stores__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 6rem 5rem;
}

.p-top-about {
  width: 100%;
  background-color: #f5f5f5;
  padding-top: 6rem;
  padding-bottom: 9.8rem;
}

.p-top-about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.p-top-about__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.p-top-about__catch {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.p-top-about__catch.u-pc {
  display: flex;
  position: relative;
}

.p-top-about__catch.u-pc::before {
  content: "";
  position: absolute;
  top: -8.3rem;
  left: -11rem;
  width: 12.1rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/top/about-deco1.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-about__catch.u-pc::after {
  content: "";
  position: absolute;
  bottom: -7.8rem;
  right: -7.5rem;
  width: 11.2rem;
  height: 11.6rem;
  background-image: url(/assets/img/top/about-deco2.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-about__catch.u-sp {
  display: none;
}

.about__catch-text {
  padding: 0.2rem 4.8rem;
  background-color: #ffffff;
  box-shadow: 0.4rem 0.4rem 0 #4086AE;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 200%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.about__catch-text .strong {
  font-size: 3rem;
  line-height: 200%;
  color: #FAC231;
}

.p-top-about__detail-text {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 180%;
  letter-spacing: 0;
  text-align: center;
}

.p-top-about__detail-text + .p-top-about__detail-text {
  margin-top: 1.8em;
}

.p-top-gallery {
  margin-top: 8rem;
}

.p-top-gallery .splide__slide {
  width: 40rem;
  height: 28rem;
  border-radius: 2rem;
  border: 5px solid #ffffff;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.p-top-gallery .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top-gallery .splide__slide:nth-child(even) {
  margin-top: 6rem;
}

.p-top-care-accedent {
  position: relative;
  width: 100%;
  max-width: 100rem;
  margin-inline: auto;
  background-color: #DCF5FA;
  border-radius: 2rem;
  padding: 8.7rem 3rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1rem 1rem 4rem rgba(18, 41, 51, 0.04);
  background-image: url(/assets/img/top/accident-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-care-accident__heading {
  position: absolute;
  top: -3rem;
  width: fit-content;
  max-width: 100%;
  padding: 0.7rem 3rem;
  border-radius: 2rem;
  background-color: #4086AE;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
}

.p-top-care-accident__heading::after {
  content: "";
  position: absolute;
  width: 4.2rem;
  height: 1.8rem;
  bottom: 1px;
  left: 50%;
  background-color: #4086AE;
  transform: translate(-50%, 100%);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.p-top-care-accident__selected {
  margin-top: 5rem;
}

.p-top-care-accident__list {
  margin-top: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.p-top-care-accident__banner {
  width: 38rem;
  aspect-ratio: 380/180;
  transition: opacity 0.3s;
}

.p-top-care-accident__banner:hover {
  opacity: 0.7;
}

.p-top-care-accident__banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top-care-accident__message {
  margin-top: 4rem;
}

.p-top-care-accident__button {
  margin-top: 2.4rem;
  text-align: center;
}

.p-top-care-message {
  padding-top: 3rem;
}

.p-top-care-message__wrapper {
  position: relative;
  width: fit-content;
  max-width: 100%;
  margin-inline: auto;
}

.p-top-care-message__wrapper::before {
  content: "";
  position: absolute;
  left: -6.15rem;
  top: -2.2rem;
  width: 7.7rem;
  height: 10rem;
  translate: -100%;
  background-image: url(/assets/img/top/message-deco1.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-care-message__wrapper::after {
  content: "";
  position: absolute;
  right: -3.95rem;
  bottom: -3.2rem;
  width: 9.9rem;
  height: 7.9rem;
  translate: 100%;
  background-image: url(/assets/img/top/message-deco2.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-care-message__text {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;
}

.p-top-care-message__text .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.p-top-care-message__text .flex .img {
  width: 3rem;
  aspect-ratio: 1/1;
}

.p-top-care-message__text .flex .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top-care-message__text .strong {
  display: inline-block;
  font-size: 4rem;
  line-height: 180%;
  color: #27837F;
  text-decoration: underline #FFE279 1.2rem;
  text-underline-offset: -0.5rem;
  padding-inline: 0.1em;
}

.p-top-care-trouble {
  position: relative;
  padding: 4rem 5rem 5rem;
  background-color: #3f585e;
  filter: drop-shadow(0 1rem 3rem rgba(0, 0, 0, 0.08));
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/assets/img/top/care-trouble-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3f585e;
}

.p-top-care-trouble::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 1px;
  width: 8rem;
  height: 4rem;
  transform: translate(-50%, 100%);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  background-color: #3f585e;
  background-image: url(/assets/img/top/care-trouble-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3f585e;
}

.p-top-care-trouble__heading {
  position: relative;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-align: center;
}

.p-top-care-trouble__heading .strong {
  font-size: 4rem;
  line-height: 150%;
}

.p-top-care-trouble__heading::before {
  content: "";
  position: absolute;
  left: -2.4rem;
  bottom: 1rem;
  transform: translateX(-100%);
  width: 12.4rem;
  height: 17.8rem;
  background-image: url(/assets/img/top/care-deco1.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-care-trouble__heading::after {
  content: "";
  position: absolute;
  right: -0.6rem;
  bottom: -0.4rem;
  transform: translateX(100%);
  width: 14.4rem;
  height: 20rem;
  background-image: url(/assets/img/top/care-deco2.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-care-trouble__list {
  width: 100%;
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 4rem 2.2rem;
}

.p-top-care-trouble__item {
  background-color: #ffffff;
  padding: 4rem 3rem;
  border-radius: 2rem;
  border: 3px solid #727A79;
  box-shadow: 0 0.2rem 2.8rem rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-top-care-trouble__title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 100%;
}

.p-top-care-trouble__img {
  margin-top: 3rem;
  width: 8.6rem;
  aspect-ratio: 86/80;
}

.p-top-care-trouble__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top-care-trouble__detail {
  margin-top: 2rem;
}

.p-top-care-trouble__button {
  margin-top: 5rem;
}

.p-top-care {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 12rem;
}

.p-top-care::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30rem;
  background-image: url(/assets/img/top/care-bg-top-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: -1;
}

.p-top-care::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30rem;
  background-image: url(/assets/img/top/care-bg-bottom-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: -1;
}

.p-top-care__bg {
  background-color: #f5f5f5;
}

.p-top-care__content {
  margin-top: 5rem;
}

.p-top-care__trouble {
  margin-bottom: 4rem;
}

.p-top-care__insurance {
  margin-top: 8rem;
}

.p-top-care__accedent {
  margin-top: 11rem;
}

.p-top-menu {
  padding-top: 8rem;
}

.p-top-menu__content {
  margin-top: 2rem;
}

.p-top-menu-list {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6rem 5rem;
}

.p-top-menu-list__item {
  max-width: calc((100% - 10rem) / 3);
}

.p-top-menu__links {
  margin-top: 5rem;
  margin-inline: auto;
  width: 100%;
  max-width: 100rem;
  padding: 4rem;
  border-radius: 2rem;
  background-color: #f5f5f5;
}

.p-top-menu__link-list {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
}

.p-top-mv {
  position: relative;
  width: 100%;
  padding-top: 2.9rem;
  margin-top: -2.9rem;
  padding-bottom: 7.7777777778vw;
  background-image: url(/assets/img/top/mv-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: hidden;
}

.p-top-mv::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2rem;
  width: 17.5rem;
  height: 19.9rem;
  background-image: url(/assets/img/top/mv-deco1.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
}

.p-top-mv::after {
  content: "";
  position: absolute;
  bottom: 0.3rem;
  right: -4rem;
  width: 17.5rem;
  height: 19.9rem;
  background-image: url(/assets/img/top/mv-deco2.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-mv__splide {
  margin-inline: auto 3.4722222222vw;
  width: 62.5vw;
  aspect-ratio: 900/640;
  border-radius: 2.0833333333vw;
  overflow: hidden;
}

.p-top-mv__splide .splide__track {
  width: 100%;
  height: 100%;
}

.p-top-mv__splide .splide__list {
  width: 100%;
  height: 100%;
}

.p-top-mv__splide .splide__slide {
  width: 100%;
  height: 100%;
}

.p-top-mv__splide .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* pagination */

.p-top-mv .splide__pagination {
  position: absolute;
  top: auto;
  left: auto;
  right: 6.5972222222vw;
  bottom: 3.3333333333vw;
  padding: 0;
  display: flex;
  gap: 1.0416666667vw;
}

.p-top-mv .splide__pagination__page {
  margin: 0;
  width: 0.5555555556vw;
  height: 0.5555555556vw;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 1;
  transition: background-color 0.3s;
}

.p-top-mv .splide__pagination__page.is-active {
  transform: scale(1);
  background-color: #87E4E4;
}

/* animation */

.p-top-mv .splide__slide img {
  transition: 12s ease-out;
}

.p-top-mv .splide__slide.is-active img {
  transform: scale(1.15);
  transition-delay: 0s;
}

.p-top-mv__body {
  position: absolute;
  top: 16.5277777778vw;
  left: 6.9444444444vw;
}

.p-top-mv__catch {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 2.0833333333vw;
  z-index: 3;
}

.p-top-mv__catch:first-child .p-top-mv__catch-text {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.p-top-mv__catch-text {
  display: flex;
  align-items: center;
  width: max-content;
  background-color: #ffffff;
  padding: 1.3888888889vw;
  border-radius: 0.3472222222vw;
  box-shadow: 0.3472222222vw 0.3472222222vw 0 #4086AE;
  font-weight: 700;
  font-size: 3.4722222222vw;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #4086AE;
  opacity: 0;
  visibility: hidden;
  transform: translateY(1rem);
}

.p-top-mv__catch-text .accent {
  color: #FAC231;
}

.p-top-mv__catch-text .large {
  font-size: 3.75vw;
}

.p-top-mv__button {
  margin-top: 17.6388888889vw;
  margin-left: 3.125vw;
}

.p-top-mv__news {
  margin-top: -2.0833333333vw;
  margin-left: 6.9444444444vw;
}

.p-top-news {
  position: relative;
  z-index: 2;
}

.p-top-news__content {
  width: 100%;
  max-width: 84rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 2.5rem 4rem;
}

.p-top-news__wrapper {
  display: flex;
  align-items: flex-start;
}

.p-top-news__heading {
  flex-shrink: 0;
  padding: 0.5rem 0;
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #4086AE;
}

.p-top-news__link {
  width: 100%;
  display: contents;
  align-items: center;
  transition: opacity 0.3s;
}

.p-top-news__link:hover {
  opacity: 0.7;
}

.p-top-news__link:hover .p-top-news__arrow {
  transform: translateX(0.5rem);
}

.p-top-news__date {
  flex-shrink: 0;
  padding: 0.8rem 0;
  margin-left: 3rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  color: #8d8d8d;
}

.p-top-news__title {
  margin-inline: 2rem;
  padding: 0.65rem 0;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-top-news__arrow {
  position: relative;
  margin-left: auto;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  background-color: #8BC2E2;
  border-radius: 50%;
  transition: transform 0.3s;
}

.p-top-news__arrow img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}

.p-top-point {
  position: relative;
  background-color: #ffffff;
  padding-top: 10.2rem;
}

.p-top-point::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 14rem;
  transform: translateY(-100%);
  background-image: url(/assets/img/top/about-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.p-top-point__content {
  padding-top: 5rem;
  padding-bottom: 15rem;
}

.p-top-point__items {
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.p-top-point__item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding-left: 4rem;
}

.p-top-point__item:nth-child(even) {
  flex-direction: row-reverse;
}

.p-top-point__item:nth-child(even) .p-top-point__img {
  box-shadow: 4rem 4rem 0 #FFF4BB;
}

.p-top-point__img {
  width: 53.5rem;
  aspect-ratio: 535/340;
  box-shadow: -4rem 4rem 0 #C2E5F9;
  border-radius: 2rem;
  outline: 0.5rem solid #ffffff;
  outline-offset: -1.5rem;
  flex-shrink: 0;
  overflow: hidden;
}

.p-top-point__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top-point__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.p-top-point__num {
  padding: 0.5rem 1.5rem;
  background-color: #FFE683;
  border-radius: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: 0.125em;
  text-align: center;
}

.p-top-point__title {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #58AFAB;
  white-space: nowrap;
}

.p-top-search {
  margin-top: -8.2rem;
  padding-top: 13.5rem;
  padding-bottom: 7.5rem;
  width: 100%;
  background-color: #F9CA6F;
  overflow: hidden;
}

.p-top-search__container {
  width: 100%;
  padding: 3rem;
}

.p-top-shop {
  position: relative;
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.p-top-shop::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12rem;
  background-image: url(/assets/img/top/shop-bg-top-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: -1;
}

.p-top-shop::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12rem;
  background-image: url(/assets/img/top/shop-bg-bottom-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: -1;
}

.p-top-shop__bg {
  background-color: #f5f5f5;
}

.p-top-shop__container {
  background-color: #f5f5f5;
}

.p-top-shop__content {
  margin-top: 4rem;
}

.p-top-shop__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 6rem 5rem;
}

.p-top-voice {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-top-voice__introduction {
  margin-top: 2rem;
}

.p-top-voice__splide .splide__track {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-top-voice__splide .splide__arrow.button {
  width: 6rem;
  height: 6rem;
  background-color: transparent;
  transition: opacity 0.3s;
  top: 24rem;
  opacity: 1;
}

.p-top-voice__splide .splide__arrow.button.prev {
  left: -2.5rem;
}

.p-top-voice__splide .splide__arrow.button.next {
  right: -2.5rem;
}

.p-top-voice__splide .splide__arrow.button:hover {
  opacity: 0.7;
}

.p-top-voice__splide .button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 6rem;
  height: 6rem;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-top-voice__splide .button.prev::before {
  background-image: url(/assets/img/common/arrow-prev.webp);
}

.p-top-voice__splide .button.next::before {
  background-image: url(/assets/img/common/arrow-next.webp);
}

.p-top-voice__splide .button:disabled::before {
  opacity: 0.5;
}

.p-top-voice__button {
  text-align: center;
}

.p-voice__container {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.p-voice__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 6rem 5rem;
}

.p-voice__pagination {
  margin-top: 8rem;
}

.p-voice__pagination .nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.p-voice__pagination .page-numbers {
  font-family: "Montserrat", sans-serif;
  display: block;
  width: 4.5rem;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 100%;
  letter-spacing: 0.1em;
}

.p-voice__pagination a.page-numbers,
.p-voice__pagination .page-numbers.dots {
  color: #58AFAB;
}

.p-voice__pagination a.page-numbers {
  transition: opacity 0.3s;
}

.p-voice__pagination a.page-numbers:hover {
  opacity: 0.7;
}

.p-voice__pagination .page-numbers.current {
  color: #ffffff;
  background-color: #58AFAB;
  border-radius: 50%;
}

.p-voice__pagination .page-numbers.prev span,
.p-voice__pagination .page-numbers.next span {
  width: 1.8rem;
  aspect-ratio: 1/1;
  background-image: url(/assets/img/common/pagination-arrow.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-voice__pagination .page-numbers.prev span {
  rotate: 180deg;
}

/* ============================
/* PC/SP 表示切替
/* ========================= */

/* 768px以上で表示 */

/* 768px以下で表示 */

.u-sp {
  display: none;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-ml-0 {
  margin-left: 0;
}

.u-whitespace-no-wrap {
  white-space: nowrap;
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 0.6944444444vw;
  }
}

@media screen and (max-width: 1420px) {
  html {
    font-size: 0.7042253521vw;
  }

  .p-header__logo.p-header__logo--store {
    width: 18rem;
  }

  .p-top-point {
    margin-right: 4rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 2.5vw;
  }

  a[href^="tel:"] {
    pointer-events: auto;
  }

  .l-2col {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
  }

  .l-2col__side {
    order: 2;
  }

  .l-2col__main {
    order: 1;
  }

  .l-inner {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .l-inner.l-inner--wide {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .l-inner.l-inner--small {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .c-breadcrumb li {
    font-size: 1.2rem;
  }

  .c-breadcrumb li + li::before {
    width: 1rem;
  }

  .c-button {
    padding: 1.5rem 1.5rem 1.5rem 4rem;
    gap: 2.5rem;
    font-size: 1.4rem;
  }

  .c-button::after {
    width: 1.5rem;
    height: 1.5rem;
  }

  .c-button.c-button--mv {
    padding: 2rem 1.5rem 2rem 4rem;
    min-width: 21rem;
    text-align: center;
    justify-content: center;
    font-size: 1.8rem;
  }

  .c-button.c-button--mv.c-button::after {
    width: 1.9rem;
    height: 1.9rem;
  }

  .c-button.c-button--pc-thin {
    padding: 1.5rem 1.5rem 1.5rem 4rem;
    gap: 2.5rem;
    font-size: 1.8rem;
  }

  .c-button.c-button--pc-thin::after {
    width: 1.5rem;
    height: 1.5rem;
  }

  .c-button.c-button--search {
    width: 100%;
    padding: 3rem 1.5rem 3rem 4rem;
    font-size: 2rem;
  }

  .c-button.c-button--search::after {
    width: 2.6rem;
    height: 2.6rem;
  }

  .c-button.c-button--sp-same {
    padding: 2rem 1.5rem 2rem 5rem;
    gap: 3rem;
    font-size: 1.8rem;
  }

  .c-button.c-button--sp-same::after {
    width: 2rem;
    height: 2rem;
  }

  .c-calender {
    width: 100%;
  }

  .c-calender tr {
    width: 36rem;
    grid-template-columns: 12.4rem 1fr 1fr 1fr 1fr 1fr 1fr 6.4rem;
    grid-template-rows: 4rem;
  }

  .c-calender__text {
    font-size: 1.3rem;
  }

  .c-calender__text.c-calender__text--yes-no {
    font-size: 0.9rem;
  }

  .c-calender__body tr + tr {
    border-top: 0.15rem solid #D5DCDC;
  }

  .c-campaign-banner__img {
    width: 36rem;
  }

  .c-care-insurance {
    padding: 3rem 1rem 4rem;
  }

  .c-care-insurance__heading {
    font-size: 1.8rem;
    line-height: 180%;
  }

  .c-care-insurance__heading .strong {
    font-size: 3rem;
    line-height: 120%;
  }

  .c-care-insurance__list {
    margin-top: 4rem;
    flex-direction: column;
    gap: 4rem;
  }

  .c-contact-form__wrapper {
    margin-top: 4rem;
    gap: 4rem;
  }

  .c-contact-form__body {
    gap: 2rem;
  }

  .c-contact-form__item {
    gap: 1rem;
  }

  .c-contact-form__label p {
    font-size: 1.6rem;
  }

  .c-contact-form__label p .required {
    margin-left: 1rem;
    padding: 0.9rem 1rem;
    font-size: 1.2rem;
  }

  .c-contact-form__input input,
  .c-contact-form__input textarea {
    font-size: 1.6rem;
    padding: 0.85rem 1.2rem;
  }

  .c-contact-form__input textarea {
    height: 8rem;
  }

  .c-contact-form__button input {
    padding: 1.5rem 6.9rem 1.5rem 5rem;
    font-size: 2rem;
  }

  .c-contact-form__button-arrow {
    width: 2.4rem;
  }

  .c-cta {
    border-width: 2px;
  }

  .c-cta::after {
    bottom: -2.3rem;
    right: -5.7rem;
    width: 12.5rem;
    height: 7.5rem;
  }

  .c-cta__text {
    font-size: 1.6rem;
  }

  .c-cta__text .strong {
    font-size: 2rem;
  }

  .c-cta__text .logo {
    width: 15.4rem;
  }

  .c-cta__text.c-cta__text--flex {
    gap: 0.5rem;
  }

  .c-cta__store-text {
    font-size: 1.6rem;
    line-height: 180%;
    letter-spacing: 0;
  }

  .c-cta__button {
    margin-top: 2.3rem;
  }

  .c-cta__button.c-cta__button--store {
    margin-top: 3rem;
  }

  .c-definition-list {
    gap: 2rem;
  }

  .c-definition-list__item {
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .c-definition-list__item dt,
  .c-definition-list__item dd {
    font-size: 1.6rem;
  }

  .c-definition-list__item > dt {
    width: 100%;
  }

  .c-definition-list__item dt .small,
  .c-definition-list__item dd .small {
    font-size: 1.4rem;
  }

  .c-definition-list__child-list {
    gap: 2rem;
  }

  .c-definition-list__child-item {
    flex-direction: column;
    gap: 1.5rem;
  }

  .c-sec-heading__wrapper {
    gap: 1.2rem;
  }

  .c-sec-heading__logo {
    width: 2.6rem;
  }

  .c-sec-heading__body {
    gap: 1.2rem;
  }

  .c-sec-heading__sub {
    font-size: 1.4rem;
  }

  .c-sec-heading__sub.c-sec-heading__sub--row::before {
    width: 2.6rem;
  }

  .c-sec-heading__main {
    font-size: 2.8rem;
  }

  .c-sec-heading__main.c-sec-heading__main--small {
    font-size: 2rem;
  }

  .c-sec-heading__main .num {
    font-size: 4rem;
  }

  .c-sec-heading__introduction {
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 150%;
  }

  .c-menu-item {
    min-height: initial;
  }

  .c-menu-item .c-menu-item__img {
    aspect-ratio: 360/240;
  }

  .c-menu-item .c-menu-item__body {
    padding: 3rem 4rem 5rem 3rem;
  }

  .c-menu-item.c-menu-item--page {
    min-height: initial;
  }

  .c-modal__body {
    border-radius: 1rem;
    width: 90vw;
    max-height: 70vh;
  }

  .c-modal__content {
    padding: 4rem 3rem;
  }

  .c-modal__title {
    margin-bottom: 3rem;
    font-size: 2.4rem;
  }

  .c-modal__text {
    font-size: 1.2rem;
  }

  .c-modal__subtitle {
    margin: 4rem auto 2rem;
    font-size: 1.6rem;
  }

  .c-modal__message {
    margin-top: 4rem;
    padding: 2.4rem 1.6rem;
  }

  .c-modal__message::after {
    width: 10rem;
    right: -5rem;
    bottom: -2.5rem;
  }

  .c-modal__message-text {
    font-size: 1.4rem;
  }

  .c-modal__message-text .strong {
    font-size: 1.8rem;
  }

  .c-modal__close-button {
    font-size: 1.4rem;
  }

  .c-modal__insurance-table {
    font-size: 1.4rem;
  }

  .c-modal__insurance-table tr {
    grid-template-columns: 8rem 1fr 1fr 1fr;
    grid-template-rows: 4.6rem;
  }

  .c-modal__menu-list {
    margin-top: 3rem;
  }

  .c-modal__menu-item dt,
  .c-modal__menu-item dd {
    padding: 0 1.5rem 1rem;
    font-size: 1.4rem;
  }

  .c-modal__menu-item dt {
    width: 14rem;
  }

  .c-page-cta {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .c-page-cta__content {
    padding: 3rem 3rem;
  }

  .c-page-cta__title {
    font-size: 2rem;
    white-space: nowrap;
  }

  .c-page-cta__button {
    width: 100%;
  }

  .c-page-mv {
    padding-top: 8rem;
    padding-bottom: 8.2rem;
    background-image: url(/assets/img/common/page-mv-bg-sp.webp);
  }

  .c-page-mv__breadcrumb {
    left: 20px;
    bottom: -3rem;
  }

  .c-sec-heading.c-sec-heading--case .c-sec-heading__wrapper {
    gap: 1.2rem;
  }

  .c-shop-item__img {
    aspect-ratio: 175/120;
  }

  .c-shop-item__body {
    padding: 3rem 2rem 4rem;
  }

  .c-shop-item.c-shop-item--4col .c-shop-item__body {
    padding: 3rem 2rem 4rem;
  }

  .c-shop-item.c-shop-item--4col .c-shop-item__button {
    margin-top: 2rem;
  }

  .c-shop-item.c-shop-item--4col .c-shop-item__info {
    margin-bottom: 0;
  }

  .c-shop-item__button {
    margin-top: 2rem;
  }

  .c-shop-item__info .c-text {
    font-size: 1.6rem;
  }

  .c-shop-item__links {
    margin-bottom: 0;
  }

  .c-shop-item__link a {
    padding: 0.5rem 0.7rem 0.5rem 1.2rem;
    gap: 0.5rem;
    font-size: 1.4rem;
  }

  .c-shop-item__link a::after {
    width: 1rem;
  }

  .c-shop-item.c-shop-item--page .c-shop-item__body {
    padding: 3rem 2rem 4rem;
  }

  .c-shop-item.c-shop-item--page .c-shop-item__name {
    font-size: 2rem;
    gap: 1.5rem;
  }

  .c-sub-heading {
    line-height: 150%;
  }

  .c-sub-heading::before,
  .c-sub-heading::after {
    display: none;
  }

  .c-sub-heading.c-sub-heading--store-reserve::before {
    display: block;
  }

  .c-sub-heading.c-sub-heading--store-reserve::after {
    display: block;
  }

  .c-sub-heading.c-sub-heading--sp-small {
    font-size: 2rem;
  }

  .c-text {
    font-size: 1.4rem;
  }

  .c-text.c-text--pc-center {
    text-align: left;
  }

  .c-text.c-text--pc-fw500 {
    font-weight: 400;
  }

  .c-text.c-text--pc-ls0 {
    letter-spacing: 0.05em;
  }

  .c-text.c-text--no-change {
    font-size: 1.6rem;
  }

  .c-text.c-text--sp-fz16 {
    font-size: 1.6rem;
  }

  .c-text.c-text--sp-ls0 {
    letter-spacing: 0;
  }

  .c-text.c-text--sp-lh180 {
    line-height: 180%;
  }

  .c-voice-item__img {
    aspect-ratio: 360/240;
  }

  .c-voice-item__content {
    padding: 3rem 4rem 11.2rem 3rem;
  }

  .p-bottom-cta {
    padding-top: 6rem;
    padding-bottom: 8rem;
  }

  .p-bottom-cta__inner {
    padding: 0;
  }

  .p-bottom-cta__list {
    grid-template-columns: 1fr;
  }

  .p-bottom-cta__item {
    aspect-ratio: 400/250;
  }

  .p-bottom-cta__item:nth-child(1) {
    border-radius: 0;
  }

  .p-bottom-cta__item:nth-child(2) {
    border-radius: 0;
  }

  .p-bottom-cta__body {
    left: 2rem;
    bottom: 1.9rem;
  }

  .p-bottom-cta__logo {
    width: 5.4rem;
  }

  .p-bottom-cta__title {
    font-size: 2rem;
  }

  .p-bottom-cta__text {
    margin-top: 1rem;
  }

  .p-bottom-cta__button {
    margin-top: 1rem;
  }

  .c-button.c-button--small {
    padding: 1rem 1rem 1rem 2.4rem;
    gap: 0.2rem;
    font-size: 1.1rem;
  }

  .c-button.c-button--small::after {
    width: 1.2rem;
    height: 1.2rem;
  }

  .p-bottom-cta__sponsor {
    margin-top: 4rem;
  }

  .p-bottom-cta__sponsor-img {
    padding-right: 20px;
    padding-left: 20px;
  }

  .p-case-main {
    padding-right: 0;
    padding-left: 0;
  }

  .p-case-main__introduction {
    margin-top: 4rem;
  }

  .p-case-main__anchors {
    margin-top: 4rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .p-case-main__body {
    margin-top: 4rem;
    gap: 5rem;
  }

  .p-case-main__content {
    padding: 0 0 3rem;
    width: inherit;
    margin: 0 20px;
  }

  .p-case-main__content::before {
    inset: 2.3rem -20px 0 -20px;
  }

  .p-case-main__content-title .small {
    font-size: 1.6rem;
  }

  .p-case-main__content-title {
    min-width: 20.5rem;
    padding: 0.7rem 2rem 0.9rem;
    font-size: 2rem;
    line-height: 150%;
  }

  .p-case-main__content-title::after {
    width: 2.5rem;
    height: 1.3rem;
  }

  .p-case-main__content-list {
    margin-top: 2.7rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .p-case-main__content-item a {
    font-size: 1.4rem;
  }

  .p-case-main__content-item a::after {
    width: 1.6rem;
  }

  .p-case-side {
    margin-top: 6rem;
  }

  .p-case-side__heading {
    justify-content: start;
  }

  .p-case-side__content {
    margin-top: 3rem;
    padding: 3rem 2rem 4rem;
  }

  .p-case-side__link {
    aspect-ratio: 360/230;
    border-radius: 1.4rem;
  }

  .p-case-side__link a p {
    font-size: 2.8rem;
  }

  .p-case-side__link-button {
    font-size: 1.6rem;
  }

  .p-case-side__link-button::after {
    width: 1.6rem;
  }

  .p-case {
    margin-top: 3rem;
  }

  .p-case__wrapper {
    padding-bottom: 8rem;
  }

  .p-company__policy {
    padding-top: 5rem;
    padding-bottom: 6rem;
  }

  .p-company__heading {
    padding-bottom: 1rem;
    font-size: 2.4rem;
    margin-inline: auto;
  }

  .p-company__heading .num {
    font-size: 2.8rem;
  }

  .p-company__policy-list {
    margin-top: 3rem;
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .p-company__policy-title {
    max-width: 19rem;
  }

  .p-company__policy-item-text {
    line-height: 180%;
  }

  .p-company__policy-item:nth-child(3) .p-company__policy-item-text {
    line-height: 150%;
  }

  .p-company__message {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  .p-company-message__wrapper {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .p-company-message__img {
    margin-top: 3rem;
    width: 28.4rem;
    order: 2;
  }

  .p-company__message-body {
    display: contents;
  }

  .p-company__message-detail {
    margin-top: 3rem;
    order: 3;
  }

  .p-company__message-name {
    margin-top: 2rem;
    order: 4;
    font-size: 1.4rem;
  }

  .p-company__info {
    padding-top: 6rem;
    padding-bottom: 14.8rem;
  }

  .p-company__info-list {
    margin-top: 4rem;
  }

  .p-contact__links {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 4rem;
  }

  .c-contact-links {
    padding: 4rem 2rem;
  }

  .c-contact-links__list {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .p-contact__body:has(.p-contact__alert) {
    padding-top: 29.8rem;
  }

  .p-contact__alert {
    padding: 0;
    max-width: initial;
  }

  .p-contact__alert-body {
    padding: 3.2rem 2rem 5.6rem;
    margin: 0 10px;
  }

  .p-contact__alert-text {
    letter-spacing: 0;
  }

  .p-contact__alert-text.p-contact__alert-text--small {
    letter-spacing: 0.05em;
  }

  .p-contact__alert-list {
    margin-top: 3rem;
  }

  .p-contact__alert-caution {
    width: 100%;
    min-width: initial;
    padding: 3rem 1.8rem 3rem 2rem;
  }

  .p-contact__form {
    padding: 21.4rem 20px 10rem;
  }

  .p-contact__form.p-contact__form--property {
    padding-top: 4rem;
    padding-bottom: 11rem;
  }

  .p-contact__form-content {
    padding: 12rem 2rem 4rem;
  }

  .p-contact__form-content.p-contact__form-content--property {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  .p-contact__title {
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  .p-contact__property-introduction {
    margin-top: 4rem;
  }

  .p-contact__property-content {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }

  .p-course-worry-check {
    padding-bottom: 5rem;
  }

  .p-course-worry-check::before {
    width: 100%;
    border-radius: 0;
  }

  .p-course-worry-check__inner {
    padding-inline: 2rem;
  }

  .p-course-worry-check__title {
    padding: 0.7rem 1.4rem 0.9rem 1.4rem;
    margin-inline: auto;
  }

  .p-course-worry-check__content {
    margin-top: 2.7rem;
    flex-direction: column;
  }

  .p-course-worry-check__list-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(9, auto);
    gap: 0 2rem;
    align-items: center;
    justify-content: center;
    grid-auto-flow: column;
  }

  .p-course-worry-check__list {
    display: contents;
  }

  .p-course-worry-check__list li label {
    font-size: 1.8rem;
    line-height: 150%;
    white-space: normal;
  }

  .p-course-worry-check__list li label::before {
    top: 50%;
    translate: 0 -50%;
  }

  .p-course-worry-check__list li label::after {
    top: 45%;
    translate: 0 -50%;
  }

  .p-course-worry-check__img-area {
    display: none;
  }

  .p-courses__select {
    padding-top: 2rem;
  }

  .p-courses__select-list {
    gap: 0.8rem;
  }

  .p-courses__select-link {
    padding: 1rem;
    gap: 1rem;
    font-size: 1.8rem;
    line-height: 130%;
    text-align: left;
  }

  .p-courses__select-link::before {
    width: 2rem;
  }

  .p-courses__select-link::after {
    width: 1.8rem;
  }

  .p-courses__container {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }

  .p-courses__heading {
    font-size: 2.4rem;
    gap: 1rem;
  }

  .p-courses__heading::before {
    width: 4rem;
  }

  .p-courses__worry-content + .p-courses__worry-content {
    margin-top: 4rem;
  }

  .p-courses__worry-content {
    margin-top: 3rem;
  }

  .p-courses__list-content:first-of-type {
    margin-top: 3rem;
  }

  .p-courses__list-content {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .p-course-list-content__heading {
    font-size: 2.4rem;
  }

  .p-course-list-content__heading .small {
    font-size: 1.6rem;
  }

  .p-course-list-content__list {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .p-courses-recommend-item__link {
    flex-direction: column;
  }

  .p-courses-recommend-item__img {
    width: 100%;
    aspect-ratio: 320/150;
  }

  .p-courses-recommend-item__body {
    padding: 1rem 2rem 2rem;
  }

  .p-courses-recommend-item__more {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #58AFAB;
  }

  .p-courses-recommend-item__more::after {
    content: "";
    display: block;
    width: 1.2rem;
    aspect-ratio: 1/1;
    background-image: url(/assets/img/common/arrow-nml-main.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .p-courses-recommend__container {
    padding: 4rem 2rem 5rem;
  }

  .p-courses-recommend__heading {
    font-size: 2.4rem;
  }

  .p-courses-recommend__list {
    margin-top: 3.3rem;
    grid-template-columns: 1fr;
    gap: 4.8rem;
  }

  .p-footer__content {
    padding-top: 6rem;
    padding-bottom: 12rem;
    background-image: url(/assets/img/common/footer-bg-sp.webp);
  }

  .p-footer__body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .p-footer__logo {
    width: 22rem;
  }

  .p-footer__information {
    align-items: center;
  }

  .p-footer__text {
    line-height: 150%;
  }

  .p-footer__nav {
    width: 100%;
    margin-top: 4rem;
    margin-left: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    gap: 2rem;
    justify-content: space-between;
  }

  .p-footer__nav-sp-wrapper {
    display: block;
  }

  .p-footer__copy-text {
    text-align: center;
  }

  .p-hamburger div span:nth-child(2) {
    top: 0.5rem;
  }

  .p-hamburger div span:last-child {
    top: 1rem;
  }

  .p-header {
    background-color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .p-header__inner {
    padding-right: 20px;
    padding-left: 0;
  }

  .p-header__inner.p-header__inner--wide {
    padding-left: 20px;
  }

  .p-header__logo {
    width: 18rem;
  }

  .p-menu-detail__container {
    margin-top: 4rem;
  }

  .p-menu-detail__heading {
    font-size: 2.8rem;
  }

  .p-menu-detail__img {
    margin-top: 4rem;
  }

  .p-menu-detail__body {
    gap: 5rem;
  }

  .p-menu-detail__body h2 {
    padding: 1.5rem 2.5rem;
    font-size: 2rem;
  }

  .p-menu-detail__body h2::before {
    width: 0.8rem;
  }

  .p-menu-detail__body h3 {
    padding-bottom: 1rem;
    font-size: 1.8rem;
  }

  .p-menu-detail__body dl dt,
  .p-menu-detail__body dl dd {
    padding: 0 1.5rem 1rem;
  }

  .p-news {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }

  .p-news__content {
    padding: 2.5rem 2rem 2rem;
    margin-inline: auto;
  }

  .p-news__wrapper {
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1.7rem;
  }

  .p-news__heading {
    padding: 0;
    font-size: 1.6rem;
  }

  .p-news__title {
    margin-inline: 0;
    padding: 0;
    flex-shrink: 0;
    max-width: 83.75%;
    font-size: 1.6rem;
  }

  .p-store-about {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .p-store-about__wrapper {
    flex-direction: column;
    gap: 3rem;
    padding: 0;
  }

  .p-store-about__img {
    border-radius: 1.4rem;
    aspect-ratio: 360/260;
  }

  .p-store-about__title {
    width: 100%;
    font-size: 2.4rem;
    gap: 1rem;
  }

  .p-store-about__title::after {
    background-image: url(/assets/img/store/about-line-sp.webp);
  }

  .p-store-about__name {
    margin-top: 3rem;
  }

  .p-store-about__name .group,
  .p-store-about__name .staff {
    font-size: 1.4rem;
  }

  .p-store-about__points {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .p-store-about__item {
    padding: 2rem 1rem 3rem;
    border-width: 0.1rem;
    box-shadow: 3px 3px 0 #4086AE;
  }

  .p-store-about__item-icon {
    width: 6rem;
  }

  .p-store-about__item-title {
    margin-top: 1rem;
    font-size: 2rem;
    letter-spacing: 0.04em;
  }

  .p-store-about__item-detail {
    font-weight: 500;
    font-size: 1.2rem;
  }

  .p-store-access {
    margin-top: 3.6rem;
    margin-bottom: 8rem;
  }

  .p-store-access__wrapper {
    margin-top: 3rem;
    flex-direction: column;
    gap: 3rem;
  }

  .p-store-access__map {
    max-width: initial;
    aspect-ratio: 360/240;
  }

  .p-store-access__title::after {
    background-image: url(/assets/img/store/access-line-sp.webp);
  }

  .p-store-access__title {
    font-size: 2rem;
  }

  .p-store-access__img {
    max-width: initial;
    aspect-ratio: 360/240;
  }

  .p-store-accident {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .p-store-accident__container {
    padding: 0;
  }

  .p-store-accident__wrapper {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .p-store-accident__title {
    width: 100%;
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  .p-store-accident__title::before {
    width: 8rem;
  }

  .p-store-accident__title::after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url(/assets/img/store/accident-line-sp.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .p-store-accident__img {
    aspect-ratio: 360/260;
  }

  .p-store-campaign {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .p-store-campaign__banner {
    margin-top: 2rem;
  }

  .p-store-cta-reserve {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .p-store-cta-reserve__container {
    padding-top: 3rem;
  }

  .p-store-faq {
    margin-top: 6rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: #f5f5f5;
  }

  .p-store-faq__container {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }

  .p-store-faq__list {
    margin-top: 4rem;
  }

  .p-faq-list__item {
    padding: 0;
    border-radius: 1rem;
  }

  .p-faq-list__item-question {
    padding: 2rem;
    gap: 1.5rem;
    font-size: 1.6rem;
  }

  .p-faq-list__item-answer p {
    padding: 2rem;
    gap: 1.5rem;
    font-size: 1.6rem;
  }

  .p-store-flow {
    background-image: url(/assets/img/store/flow-bg-sp.webp);
  }

  .p-store-flow__container {
    margin-top: 4rem;
  }

  .p-store-flow__list {
    gap: 8rem;
  }

  .p-store-flow__item-wrap {
    flex-direction: column;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.08);
  }

  .p-store-flow__item-img {
    width: 100%;
    aspect-ratio: 360/240;
  }

  .p-store-flow__item-body {
    padding: 3rem 3rem 4rem;
  }

  .p-store-flow__item-heading {
    gap: 1rem;
    padding-bottom: 2rem;
  }

  .p-store-flow__item-num {
    width: 4rem;
    font-size: 1.6rem;
  }

  .p-store-flow__item-title {
    font-size: 2.4rem;
    letter-spacing: 0;
  }

  .p-store-flow__item-title .small {
    font-size: 1.8rem;
    line-height: 100%;
  }

  .p-store-footer {
    padding-top: 6rem;
    padding-bottom: 12rem;
    background-image: url(/assets/img/store/footer-bg-sp.webp);
  }

  .p-store-footer__body {
    gap: 4rem;
  }

  .p-store-footer__information-wrapper {
    flex-direction: column;
    gap: 3rem;
  }

  .p-store-footer__title {
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  .p-store-footer__title::after {
    background-image: url(/assets/img/store/footer-line-pc.webp);
  }

  .p-store-footer__information-tags {
    flex-wrap: wrap;
  }

  .p-store-footer__information-link {
    padding-top: 1.2rem 4.2rem;
    font-size: 1.4rem;
  }

  .p-store-footer__shop-list {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .p-store-footer__nav {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .p-store-footer__nav::before {
    background-image: url(/assets/img/store/footer-line-sp.webp);
  }

  .p-store-footer__nav {
    margin-top: 4rem;
  }

  .p-store-footer__nav-list {
    flex-direction: column;
  }

  .p-store-footer__nav-item a {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.6rem;
  }

  .p-store-footer__copy-text {
    color: #8d8d8d;
  }

  .p-store-menu {
    padding-top: 4rem;
  }

  .p-store-menu__banner {
    margin-top: 2rem;
  }

  .p-store-menu__insurance {
    margin-top: 4rem;
  }

  .p-store-menu__self-pay {
    margin-top: 4rem;
  }

  .p-store-self-pay__container {
    align-items: flex-start;
  }

  .p-store-self-pay__heading {
    width: 100%;
    font-size: 2.4rem;
  }

  .p-store-self-pay__list {
    flex-direction: column;
    margin-top: 4rem;
  }

  .p-store-self-pay__item {
    max-width: initial;
  }

  .p-store-message {
    margin-top: 6rem;
  }

  .p-store-message__container {
    padding: 0;
  }

  .p-store-message__wrapper {
    margin-top: 4rem;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .p-store-message__title {
    width: 100%;
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  .p-store-message__title::after {
    background-image: url(/assets/img/store/message-line-sp.webp);
  }

  .p-store-message__title span {
    font-size: 1.6rem;
  }

  .p-store-message__detail {
    margin-top: 1.8rem;
  }

  .p-store-message__img {
    max-width: initial;
    border-radius: 1.4rem;
    aspect-ratio: 360/260;
  }

  .p-store-mv {
    aspect-ratio: 400/550;
  }

  .p-store-mv__body {
    margin-top: 2rem;
    width: 100%;
  }

  .p-store-mv__title {
    font-size: 3.2rem;
  }

  .p-store-mv__time-list {
    margin-top: 1rem;
    font-size: 1.6rem;
  }

  .p-store-mv__features {
    margin-top: 3rem;
    width: 39rem;
    gap: 0.2rem;
  }

  .p-store-mv__feature-item img {
    filter: drop-shadow(0 0.5rem 3rem, rgba(0, 0, 0, 0.07));
  }

  .p-store-mv-splide {
    margin-top: 3rem;
    margin-left: auto;
    width: 95%;
  }

  .p-store-mv-splide .splide__slide {
    width: 20rem;
  }

  .p-store-mv-splide .splide__arrow.button {
    width: 3rem;
    height: 3rem;
  }

  .p-store-mv-splide .splide__arrow.button.next {
    right: 0.5rem;
    translate: 0;
  }

  .p-store-mv-splide .button::before {
    width: 3rem;
    height: 3rem;
  }

  .p-store-pelvis-menu__content::before {
    width: 100%;
    border-radius: 0;
  }

  .p-store-pelvis-menu__heading {
    min-width: initial;
    padding: 0.5rem 3.5rem;
    font-size: 2.4rem;
  }

  .p-store-pelvis-menu__heading::after {
    margin-top: -1.5rem;
  }

  .p-store-pelvis-menu__inner {
    padding-inline: 2rem;
  }

  .p-store-pelvis-menu__wrapper {
    padding-top: 4.5rem;
    padding-bottom: 5rem;
    flex-direction: column;
    gap: 3rem;
  }

  .p-store-pelvis-menu__img {
    max-width: initial;
  }

  .p-store-pelvis-menu__body {
    max-width: initial;
  }

  .p-store-pelvis-menu__title-wrap {
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 0;
  }

  .p-store-pelvis-menu__detail {
    margin-top: 3rem;
  }

  .p-store-pelvis-menu__button {
    margin-top: 3rem;
  }

  .p-store-pelvis-menu__content:nth-child(2) {
    margin-top: 6rem;
  }

  .p-store-pelvis-menu__content:nth-child(2)::before {
    border-radius: 0;
  }

  .p-store-pelvis-menu__content:nth-child(2) .p-store-pelvis-menu__wrapper {
    flex-direction: column;
  }

  .p-store-pelvis-message__heading {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 2.4rem;
    line-height: 150%;
  }

  .p-store-pelvis-message__heading .strong {
    font-size: 3.2rem;
    line-height: 150%;
  }

  .p-store-pelvis-message__container {
    padding-top: 3rem;
    padding-bottom: 18.5rem;
  }

  .p-store-pelvis-message__container::before {
    top: auto;
    left: 8.4rem;
    bottom: 6.8rem;
  }

  .p-store-pelvis-message__container::after {
    top: auto;
    right: 7.6rem;
    bottom: 7.5rem;
  }

  .p-store-pelvis-message__text {
    font-size: 2rem;
  }

  .p-store-pelvis-trouble {
    padding-top: 22rem;
    background-image: url(/assets/img/store/pelvis-bg-sp.webp);
  }

  .p-store-pelvis-trouble__heading {
    font-size: 2.8rem;
  }

  .p-store-pelvis-trouble__heading .strong {
    font-size: 3.9rem;
    line-height: 150%;
    text-decoration-thickness: 0.8rem;
    text-underline-offset: -0.4rem;
  }

  .p-store-pelvis-trouble__content {
    margin-top: 3rem;
    flex-direction: column;
    gap: 1rem;
  }

  .p-store-pelvis-trouble__list {
    gap: 1rem;
  }

  .p-store-pelvis-trouble__list li {
    font-size: 1.6rem;
  }

  .p-store-pelvis-trouble__list li::before {
    width: 3rem;
  }

  .p-store-reserve {
    margin-top: 6rem;
    padding-top: 15rem;
  }

  .p-store-reserve::before {
    aspect-ratio: 390/250;
    background-image: url(/assets/img/store/reserve-bg-sp.webp);
  }

  .p-store-reserve__container {
    padding: 4rem 2rem 5rem;
  }

  .p-store-reserve__wrapper {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .p-store-reserve__content {
    max-width: initial;
    padding: 3rem;
    border-radius: 1.1rem;
  }

  .p-store-reserve__title {
    font-size: 2.4rem;
  }

  .p-store-reserve__detail {
    font-size: 1.4rem;
    line-height: 150%;
  }

  .p-store-reserve__button {
    margin-top: 2rem;
  }

  .p-store-reserve__link {
    min-width: initial;
    padding: 1.2rem 4.2rem;
    font-size: 1.4rem;
  }

  .p-store-support {
    margin-top: 6rem;
  }

  .p-store-support__container {
    border-radius: 0;
    padding: 4rem 1.9rem;
  }

  .p-store-support__container::after {
    top: 38.8rem;
    right: 4.3rem;
  }

  .p-store-support__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .p-store-support__item {
    padding: 2rem 1rem 3rem;
    border: 1px solid #FE9F84;
    box-shadow: 0.3rem 0.3rem 0 #FE9F84;
  }

  .p-store-support__item-img {
    width: 6rem;
  }

  .p-store-support__item-title {
    margin-top: 1rem;
    font-size: 2rem;
    text-align: left;
  }

  .p-store-support__item-note {
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .p-store-support__text {
    font-size: 1.4rem;
  }

  .p-store-trouble {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }

  .p-store-trouble__heading {
    padding-inline: 1.5rem;
    font-size: 1.8rem;
  }

  .p-store-trouble__heading::before,
  .p-store-trouble__heading::after {
    bottom: 0.7rem;
  }

  .p-store-trouble__heading .strong {
    font-size: 3rem;
  }

  .p-store-trouble__text {
    display: none;
  }

  .p-store-trouble__list {
    margin-top: 2.7rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 1rem;
  }

  .p-store-trouble__item {
    padding: 1.5rem 1.3rem 2rem;
    border-radius: 1.3rem;
    border-width: 2px;
    box-shadow: 0 0.1rem 1.8rem rgba(0, 0, 0, 0.03);
  }

  .p-store-trouble__title-wrapper {
    gap: 0.3rem;
    padding-left: 0;
  }

  .p-store-trouble__img {
    width: 3.3rem;
  }

  .p-store-trouble__title {
    font-size: 1.3rem;
  }

  .p-store-troule__about {
    margin-top: 4rem;
    width: 100%;
    min-width: initial;
    padding: 2rem 2rem 4rem;
  }

  .p-store-troule__about-btn {
    margin-top: 3rem;
  }

  .p-store-voice {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .p-store-voice__splide {
    margin-top: 4rem;
  }

  .p-store-voice__splide .splide__arrow.button {
    width: 5rem;
    height: 5rem;
  }

  .p-store-voice__splide .splide__arrow.button.prev {
    left: -1.5rem;
  }

  .p-store-voice__splide .splide__arrow.button.next {
    right: -1.5rem;
  }

  .p-store-voice__splide .button::before {
    width: 5rem;
    height: 5rem;
  }

  .p-store-voice__review {
    margin-top: 4rem;
  }

  .p-store-voice .splide__track {
    padding-bottom: 4rem;
  }

  .p-stores {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }

  .p-stores__list {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .p-top-about {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }

  .p-top-about__container {
    gap: 2.5rem;
  }

  .p-top-about__content {
    gap: 4rem;
  }

  .p-top-about__catch {
    align-items: flex-start;
    gap: 2rem;
  }

  .p-top-about__catch.u-pc {
    display: none;
  }

  .p-top-about__catch.u-sp {
    display: flex;
  }

  .about__catch-text {
    padding: 0.5rem 1.9rem;
    font-size: 2rem;
    line-height: 4.8rem;
    white-space: nowrap;
  }

  .about__catch-text .strong {
    font-size: 2.4rem;
  }

  .p-top-about__detail-text {
    font-size: 1.6rem;
  }

  .p-top-gallery {
    margin-top: 4rem;
  }

  .p-top-gallery .splide__slide {
    width: 20rem;
    height: 14rem;
    border-radius: 1rem;
    border-width: 2.5px;
  }

  .p-top-gallery .splide__slide:nth-child(even) {
    margin-top: 3rem;
  }

  .p-top-care-accident__heading {
    min-width: 32rem;
    font-size: 2.4rem;
    padding: 0.5rem 3rem;
  }

  .p-top-care-accident__selected {
    margin-top: 4rem;
  }

  .p-top-care-accident__list {
    margin-top: 2rem;
    gap: 1.6rem;
    display: block;
  }

  .p-top-care-accident__banner {
    width: 100%;
  }

  .p-top-care-accident__message {
    margin-top: 3rem;
  }

  .p-top-care-accident__button {
    margin-top: 2rem;
  }

  .p-top-care-accident__item:first-child {
    margin-bottom: 2rem;
  }

  .p-top-care-message__wrapper {
    width: 100%;
  }

  .p-top-care-message__wrapper::before {
    left: 0rem;
    top: -1.8rem;
    width: 3.85rem;
    height: 5rem;
    translate: none;
  }

  .p-top-care-message__wrapper::after {
    right: -0.5rem;
    bottom: -3.8rem;
    width: 5rem;
    height: 4rem;
    translate: none;
  }

  .p-top-care-message__text {
    font-size: 1.8rem;
  }

  .p-top-care-message__text .flex {
    gap: 1rem;
  }

  .p-top-care-message__text .flex .img {
    width: 2.6rem;
  }

  .p-top-care-message__text .strong {
    font-size: 3rem;
    text-decoration-thickness: 0.8rem;
    text-underline-offset: -0.4rem;
    line-height: 120%;
  }

  .p-top-care-message__text .mt {
    display: inline-block;
    margin-top: 1.8em;
  }

  .p-top-care-trouble {
    padding: 3rem 1rem;
  }

  .p-top-care-trouble::after {
    width: 4.2rem;
    height: 2.1rem;
  }

  .p-top-care-trouble__heading {
    font-size: 2.2rem;
  }

  .p-top-care-trouble__heading .strong {
    font-size: 3.4rem;
  }

  .p-top-care-trouble__heading::before {
    display: none;
  }

  .p-top-care-trouble__heading::after {
    display: none;
  }

  .p-top-care-trouble__list {
    margin-top: 1.8rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 1rem;
  }

  .p-top-care-trouble__item {
    padding: 2rem 1rem;
    border-radius: 1rem;
    border-width: 2px;
    box-shadow: 0 0.1rem 1.4rem rgba(0, 0, 0, 0.03);
  }

  .p-top-care-trouble__title {
    font-size: 1.6rem;
  }

  .p-top-care-trouble__img {
    margin-top: 2rem;
    width: 4.3rem;
  }

  .p-top-care-trouble__detail {
    margin-top: 1rem;
  }

  .p-top-care-trouble__detail.c-text {
    font-size: 1.2rem;
  }

  .p-top-care-trouble__button {
    margin-top: 3.2rem;
  }

  .p-top-care {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .p-top-care::before {
    height: 8rem;
    background-image: url(/assets/img/top/care-bg-top-sp.webp);
  }

  .p-top-care::after {
    height: 8rem;
    background-image: url(/assets/img/top/care-bg-bottom-sp.webp);
  }

  .p-top-care__content {
    margin-top: 4rem;
  }

  .p-top-care__trouble {
    margin-bottom: 2rem;
  }

  .p-top-care__insurance {
    margin-top: 5rem;
  }

  .p-top-care__accedent {
    margin-top: 5rem;
  }

  .p-top-menu {
    padding-top: 6rem;
  }

  .p-top-menu-list {
    margin-top: 4rem;
    flex-direction: column;
    gap: 5rem;
  }

  .p-top-menu-list__item {
    max-width: 36rem;
  }

  .p-top-menu__links {
    padding: 3rem 3rem 4rem;
  }

  .p-top-menu__link-list {
    margin-top: 3rem;
    width: fit-content;
    margin-inline: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  .p-top-menu__link-item {
    width: 100%;
  }

  .p-top-menu__link-item .c-button {
    width: 100%;
  }

  .p-top-mv {
    background-image: url(/assets/img/top/mv-bg-sp.webp);
    background-position: center bottom;
    padding-bottom: 6.3rem;
    padding-top: 0;
    margin-top: 0;
  }

  .p-top-mv::before {
    width: 7.5rem;
    height: 8.6rem;
    top: 1.8rem;
    left: -1.7rem;
  }

  .p-top-mv::after {
    width: 5rem;
    height: 5.7rem;
    top: 39.5rem;
    bottom: auto;
    right: -0.2rem;
  }

  .p-top-mv__splide {
    margin-inline: auto 0;
    width: 36.5rem;
    aspect-ratio: 365/400;
    border-radius: 3rem 0 0 3rem;
  }

  .p-top-mv__splide .splide__slide img {
    object-fit: inherit;
  }

  .p-top-mv .splide__pagination {
    display: none;
  }

  .p-top-mv__body {
    top: 29.4rem;
    left: 2rem;
    right: 2rem;
  }

  .p-top-mv__catch {
    gap: 1.5rem;
  }

  .p-top-mv__catch-text {
    padding: 1.2rem 1.5rem;
    border-radius: 1rem;
    box-shadow: 0.4rem 0.4rem 0 #4086AE;
    font-weight: 700;
    font-size: 3rem;
  }

  .p-top-mv__catch-text .large {
    font-size: 3.2rem;
  }

  .p-top-mv__button {
    margin-top: 15.8rem;
    text-align: center;
  }

  .p-top-mv__news {
    margin-top: 6rem;
    margin-left: 0;
    width: 100%;
    padding: 0 20px;
  }

  .p-top-news__content {
    padding: 2.5rem 2rem 2rem;
    margin-inline: auto;
  }

  .p-top-news__wrapper {
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1.7rem;
  }

  .p-top-news__heading {
    padding: 0;
    font-size: 1.6rem;
  }

  .p-top-news__title {
    margin-inline: 0;
    padding: 0;
    flex-shrink: 0;
    max-width: 83.75%;
    font-size: 1.6rem;
  }

  .p-top-news__arrow {
    width: 3.2rem;
    height: 3.2rem;
  }

  .p-top-news__arrow img {
    width: 1.6rem;
    height: 1.6rem;
  }

  .p-top-point {
    margin-right: 0;
    padding-top: 6rem;
  }

  .p-top-point::before {
    height: 8rem;
    background-image: url(/assets/img/top/about-bg-sp.webp);
  }

  .p-top-point__content {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }

  .p-top-point__items {
    gap: 5rem;
  }

  .p-top-point__item {
    flex-direction: column;
    gap: 5rem;
    padding-left: 0;
  }

  .p-top-point__item:nth-child(even) {
    flex-direction: column;
  }

  .p-top-point__item:nth-child(even) .p-top-point__img {
    box-shadow: 2rem 2rem 0 #FFF4BB;
  }

  .p-top-point__img {
    margin-left: 0;
    width: 100%;
    box-shadow: -2rem 2rem 0 #C2E5F9;
    aspect-ratio: 360/220;
    outline-width: 0.3rem;
    outline-offset: -1.3rem;
  }

  .p-top-point__title {
    white-space: normal;
    font-size: 2.4rem;
  }

  .p-top-search {
    margin-top: -6.3rem;
    padding-top: 9.7rem;
    padding-bottom: 4rem;
  }

  .p-top-search__container {
    padding: 3rem 1.5rem;
  }

  .p-top-shop {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .p-top-shop::before {
    height: 8rem;
    background-image: url(/assets/img/top/shop-bg-top-sp.webp);
  }

  .p-top-shop::after {
    height: 8rem;
    background-image: url(/assets/img/top/shop-bg-bottom-sp.webp);
  }

  .p-top-shop__content {
    margin-top: 3.6rem;
  }

  .p-top-shop__list {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .p-top-voice {
    padding-bottom: 6rem;
  }

  .p-top-voice__splide .splide__track {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .p-top-voice__splide .splide__arrow.button {
    width: 5rem;
    height: 5rem;
  }

  .p-top-voice__splide .splide__arrow.button.prev {
    left: -1.5rem;
  }

  .p-top-voice__splide .splide__arrow.button.next {
    right: -1.5rem;
  }

  .p-top-voice__splide .button::before {
    width: 5rem;
    height: 5rem;
  }

  .p-voice__container {
    padding-bottom: 8rem;
  }

  .p-voice__list {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .p-voice__pagination {
    margin-top: 6rem;
  }

  .p-voice__pagination .nav-links {
    gap: 0.6rem;
  }

  .p-voice__pagination .page-numbers {
    width: 3.5rem;
    font-size: 1.8rem;
  }

  .p-voice__pagination .page-numbers.prev span,
  .p-voice__pagination .page-numbers.next span {
    width: 1.4rem;
  }

  .u-pc {
    display: none;
  }

  .u-sp {
    display: block;
  }
}