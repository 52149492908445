@use "global"as *;

.p-store-about {
  padding-top: 8rem;
  padding-bottom: 8rem;

  @include mq("md") {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.p-store-about__container {
  display: flex;
  flex-direction: column-reverse;
  gap: 6rem;
}

.p-store-about__wrapper {
  display: flex;
  align-items: center;
  gap: 5rem;
  padding-left: 3.5rem;
  padding-right: 5.5rem;

  @include mq("md") {
    flex-direction: column;
    gap: 3rem;
    padding: 0;
  }
}

.p-store-about__img {
  width: 100%;
  max-width: 510rem;
  aspect-ratio: 510 / 420;
  border-radius: 2rem;
  overflow: hidden;

  @include mq("md") {
    border-radius: 1.4rem;
    aspect-ratio: 360 / 260;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-store-about__body {
  width: 100%;
  max-width: 50rem;
}

.p-store-about__title {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.9rem;

  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main1;

  @include mq("md") {
    width: 100%;
    font-size: 2.4rem;
    gap: 1rem;
  }

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/store/about-line.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    @include mq("md") {
      background-image: url(/assets/img/store/about-line-sp.webp);
    }
  }
}

.p-store-about__detail {
  margin-top: 2rem;
}

.p-store-about__name {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row-reverse;
  gap: 1rem;

  @include mq("md") {
    margin-top: 3rem;
  }

  & .group,
  & .staff {
    font-weight: $bold;
    font-size: max(1.6rem, 12px);
    line-height: 100%;
    letter-spacing: 0.05em;

    @include mq("md") {
      font-size: 1.4rem;
    }
  }

  & .staff {
    color: $main1;
  }
}


.p-store-about__points {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 2.3rem;

  @include mq("md") {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.p-store-about__item {
  padding: 3rem;
  border-radius: 2rem;
  border: 3px solid $main1;
  box-shadow: 4px 4px 0 $main1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq("md") {
    padding: 2rem 1rem 3rem;
    border-width: 0.1rem;
    box-shadow: 3px 3px 0 $main1;
  }
}

.p-store-about__item-icon {
  width: 12rem;
  aspect-ratio: 1 / 1;

  @include mq("md") {
    width: 6rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.p-store-about__item-title {
  margin-top: 2rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 100%;

  @include mq("md") {
    margin-top: 1rem;
    font-size: 2rem;
    letter-spacing: 0.04em;
  }
}

.p-store-about__item-detail {
  margin-top: 2rem;
  font-weight: $regular;
  font-size: max(1.6rem,12px);
  line-height: 150%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-weight: $medium;
    font-size: 1.2rem;
  }
}
