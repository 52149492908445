@use "global" as *;


.c-contact-form {
  width: 100%;
}

.c-contact-form__wrapper {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;

  @include mq("md") {
    margin-top: 4rem;
    gap: 4rem;
  }
}

.c-contact-form__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @include mq("md") {
    gap: 2rem;
  }
}

.c-contact-form__item {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include mq("md") {
    gap: 1rem;
  }
}

.c-contact-form__label p {
  font-weight: $bold;
  font-size: 2rem;
  line-height: 144%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-size: 1.6rem;
  }

  & .required {
    display: inline-block;
    margin-left: 1.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: $accent6;

    font-weight: $bold;
    font-size: max(1.4rem,12px);
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $accent13;
    
    @include mq("md") {
      margin-left: 1rem;
      padding: 0.9rem 1rem;
      font-size: 1.2rem;
    }

  }
}

.c-contact-form__input input,
.c-contact-form__input textarea {
  width: 100%;
  border-radius: 0.5rem;
  background-color: $lightgray;
  border: 1px solid $gray2;
  padding: 1.5rem 2rem;

  font-weight: $regular;
  font-size: 2rem;
  line-height: 144%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-size: 1.6rem;
    padding: 0.85rem 1.2rem;

  }
}

.c-contact-form__input textarea {
  height: 15rem;

  @include mq("md") {
    height: 8rem;
  }
}

.c-contact-form__next {}

.c-contact-form__button {
  position: relative;

  & input {
    display: inline-block;
    padding: 2rem 7.3rem 2rem 5rem;
    border-radius: 5rem;
    background-color: $btn;
    font-weight: $bold;
    font-size: 2.4rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $white;
    transition: background-color 0.3s;

    @include mq("md") {
      padding: 1.5rem 6.9rem 1.5rem 5rem;
      font-size: 2rem;
    }

    &:hover {
      background-color: $btn-h;
    }
  }
}

.c-contact-form__button-arrow {
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  right: 1.5rem;
  width: 2.8rem;
  aspect-ratio: 1 / 1;
  background-image: url(/assets/img/common/triangle-arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    width: 2.4rem;
  }

}

.c-contact-form__button.c-contact-form__button--secondary {
  & input {
    background-color: $btn2;

    &:hover {
      background-color: $btn2-h;

    }
  }
}