@use "global" as *;


.c-calender {
  width: 46.5rem;
  max-width: 100%;
  border-collapse: collapse;
  border-radius: 2rem;
  overflow: hidden;
  background-color: $lightgray;

  @include mq("md") {
    width: 100%;
  }
}

.c-calender tr {
  width: 46.5rem;
  display: grid;
  grid-template-columns: 16rem 1fr 1fr 1fr 1fr 1fr 1fr 8.3rem;
  grid-template-rows: 5.7rem;

  @include mq("md") {
    width: 36rem;
    grid-template-columns: 12.4rem 1fr 1fr 1fr 1fr 1fr 1fr 6.4rem;
    grid-template-rows: 4rem;

  }
}

.c-calender th,
.c-calender td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-calender th:last-child,
.c-calender td:last-child {
  padding-right: 1.8rem;
}

.c-calender .c-calender__head th {
  background-color: $main3;
  color: $white;
}

.c-calender__text {
  font-weight: $medium;
  font-size: 1.7rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main3;

  @include mq("md") {
    font-size: 1.3rem;
  }

  &.c-calender__text--reverse {
    color: $white;
  }

  &.c-calender__text--time {
    color: $main13;
    font-weight: $bold;
  }

  &.c-calender__text--yes-no {
    font-size: 1.2rem;

    @include mq("md") {
      font-size: 0.9rem;
    }
  }
}

.c-calender__body tr+tr {
  border-top: 0.2rem solid #D5DCDC;

  @include mq("md") {
    border-top: 0.15rem solid #D5DCDC;
  }
}
