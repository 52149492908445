@use "global"as *;

.p-store-access {
  margin-top: 8rem;
  margin-bottom: 10rem;

  @include mq("md") {
    margin-top: 3.6rem;
    margin-bottom: 8rem;
  }
}


.p-store-access__wrapper {  margin-top: 3.6rem;
  display: flex;
  align-items: center;
  gap: 4rem;

  @include mq("md") {
    margin-top: 3rem;
    flex-direction: column;
    gap: 3rem;
  }
}

.p-store-access__map {
  width: 100%;
  max-width: 63rem;
  aspect-ratio: 630 / 420;
  border-radius: 1rem;
  overflow: hidden;
  flex-shrink: 0;

  @include mq("md") {
    max-width: initial;
    aspect-ratio: 360 / 240;
  }

  & iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}

.p-store-access__body {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
}

.p-store-access__title {
  position: relative;
  padding-bottom: 1rem;
  font-weight: $bold;
  font-size: 2.4rem;
  line-height: 100%;
  letter-spacing: 0.05em;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2px;
    background-image: url(/assets/img/store/access-line.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    @include mq("md") {
      background-image: url(/assets/img/store/access-line-sp.webp);
    }
  }

  @include mq("md") {
    font-size: 2rem;
  }
}

.p-store-access__detail {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.p-store-access__img {
  width: 100%;
  max-width: 48rem;
  aspect-ratio: 480 / 240;
  border-radius: 1rem;
  overflow: hidden;

  @include mq("md") {
    max-width: initial;
    aspect-ratio: 360 / 240;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-store-access__text {}
