@use "global"as *;

.p-top-search {
  margin-top: -8.2rem;
  padding-top: 13.5rem;
  padding-bottom: 7.5rem;
  width: 100%;
  background-color: $accent3;
  overflow: hidden;

  @include mq("md") {
    margin-top: -6.3rem;
    padding-top: 9.7rem;
    padding-bottom: 4rem;
  }
}

.p-top-search__container {
  width: 100%;
  padding: 3rem;

  @include mq("md") {
    padding: 3rem 1.5rem;
  }
}

