@use "global"as *;

.p-store-footer {
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 6rem;
  background-image: url(/assets/img/store/footer-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    padding-top: 6rem;
    padding-bottom: 12rem;
    background-image: url(/assets/img/store/footer-bg-sp.webp);
  }
}

.p-store-footer__body {
  display: flex;
  flex-direction: column;
  gap: 8.7rem;

  @include mq("md") {
    gap: 4rem;
  }
}

.p-store-footer__information-wrapper {
  margin-top: 3rem;
  display: flex;
  align-items: flex-start;
  gap: 4.5rem;

  @include mq("md") {
    flex-direction: column;
    gap: 3rem;
  }
}

.p-store-footer__information-body {
  display: flex;
  flex-direction: column;
}

.p-store-footer__title {
  position: relative;
  padding-bottom: 0.9rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;

  @include mq("md") {
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/store/footer-line-pc.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    @include mq("md") {
      background-image: url(/assets/img/store/footer-line-pc.webp);
    }
  }

}

.p-store-footer__information-tags {
  display: flex;
  gap: 1rem;

  @include mq("md") {
    flex-wrap: wrap;
  }
}

.p-store-footer__information-tag {
  padding: 1.2rem 1.5rem;
  background-color: $white;
  border-radius: 0.5rem;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main3;
}

.p-store-footer__information-troubles {
  margin-top: 3rem;
}

.p-store-footer__information-address {
  margin-top: 3rem;
}

.p-store-footer__information-button {
  margin-top: 1.5rem;
}

.p-store-footer__information-link {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem 3rem 1.3rem 3rem;
  background-color: $btn-store-tel;
  border-radius: 10rem;
  font-weight: $bold;
  font-size: 1.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;

  @include mq("md") {
    padding-top: 1.2rem 4.2rem;
    font-size: 1.4rem;
  }

  &::before {
    content: "";
    display: block;
    width: 2.5rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/icon-tel-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-store-footer__shop-list {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 2.3rem;

  @include mq("md") {
    grid-template-columns: 1fr;
        gap: 5rem;
  }
}

.p-store-footer__nav {
  width: 100%;
  margin-top: 6rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq("md") {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/store/footer-line-pc.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    @include mq("md") {

      background-image: url(/assets/img/store/footer-line-sp.webp);
    }
  }

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-store-footer__nav-list {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq("md") {
    flex-direction: column;
  }

}

.p-store-footer__nav-item a {
  display: block;
  padding-right: 2rem;
  padding-left: 2rem;
  font-weight: $medium;
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  @include mq("md") {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.6rem;
  }
}

.p-store-footer__copy {}

.p-store-footer__copy-text {
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    color: $darkgray;
  }
}

.p-store-footer__sp-menu {
  position: fixed;
  left: 0;
  bottom: 0;
}