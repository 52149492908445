@use "global"as *;


.c-shop-item {
  position: relative;
  width: 100%;
  background-color: $white;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(#000, 8%);
  display: flex;
  flex-direction: column;
}

.c-shop-item__img {
  width: 100%;
  aspect-ratio: 350 / 240;

  @include mq("md") {
    aspect-ratio: 175 / 120;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-shop-item__body {
  width: 100%;
  height: 100%;
  padding: 4rem 3rem;
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include mq("md") {
    padding: 3rem 2rem 4rem;
  }
}

.c-shop-item.c-shop-item--4col {

  & .c-shop-item__body {
    padding: 3rem 2rem;

    @include mq("md") {
      padding: 3rem 2rem 4rem;
    }
  }

  & .c-shop-item__button {
    margin-top: auto;
    
    @include mq("md") {
      margin-top: 2rem;
    }
  }

  & .c-shop-item__info {
  margin-bottom: 2.4rem;
  @include mq('md') {
    margin-bottom: 0;
  }
  }
}

.c-shop-item__button {
  @include mq("md") {
    margin-top: 2rem;
  }
}

.c-shop-item__name {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: $bold;
  font-size: 2em;
  line-height: 100%;


  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/card-line.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.c-shop-item__info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;

}

.c-shop-item__info .c-text{
@include mq('md') {
  font-size: 1.6rem;
}
}


.c-shop-item__links {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  @include mq('md') {
    margin-bottom: 0;
  }
}

.p-top-shop__content .c-shop-item__links{
  margin-top: auto;
}

.c-shop-item__link a {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: max-content;
  padding: 1.2rem 1.5rem 1.2rem 2rem;
  background-color: $white;
  border: 1px solid $main3;
  border-radius: 10rem;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main3;
  transition: background-color 0.3s, color 0.3s;

  @include mq("md") {
    padding: 0.5rem 0.7rem 0.5rem 1.2rem;
    gap: 0.5rem;
    font-size: 1.4rem;
  }

  &::after {
    content: "";
    display: block;
    width: 1.5rem;
    aspect-ratio: 1 / 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 0.3s;

    @include mq("md") {
      width: 1rem;
    }
  }

  &:hover {
    background-color: $main3;
    color: $white;
  }

}

.c-shop-item__link.c-shop-item__link--tel a {
  &::after {
    background-image: url(/assets/img/common/icon-tel.svg);
  }

  &:hover {
    &::after {
      background-image: url(/assets/img/common/icon-tel-white.svg);
    }
  }
}

.c-shop-item__link.c-shop-item__link--line a {
  text-transform: uppercase;

  &::after {
    background-image: url(/assets/img/common/icon-calender.svg);
  }

  &:hover {
    &::after {
      background-image: url(/assets/img/common/icon-calender-white.svg);
    }
  }
}

.c-shop-item__link.c-shop-item__link--review a {
  text-transform: uppercase;

  &::after {
    background-image: url(/assets/img/common/icon-review.svg);
  }

  &:hover {
    &::after {
      background-image: url(/assets/img/common/icon-review-white.svg);
    }
  }
}


/* 一覧ページ用スタイル */


.c-shop-item.c-shop-item--page {

  & .c-shop-item__body {
    @include mq("md") {
      padding: 3rem 2rem 4rem;

    }
  }

  & .c-shop-item__name {
    @include mq("md") {
      font-size: 2rem;
      gap: 1.5rem;
    }
  }

  & .c-shop-item__info {
    margin-top: 2rem;
  }

  & .c-shop-item__links {
    gap: 0.5rem;
    flex-wrap: wrap;

    
  }

  & .c-shop-item__link a {
    padding: 1.2rem 0.9rem;
    font-size: max(1.2rem, 10px);
    gap: 0.5rem;


    &::after {
      width: max(1.2rem, 10px);
    }
  }
}

.c-shop-item__features {
  margin-top: auto;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;

}

.c-shop-item__feature {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: $accent6;

  font-weight: $bold;
  font-size: max(1.2rem, 10px);
  line-height: 120%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $accent13;

  &::before {
    content: "";
    display: block;
    width: 3.2rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/icon-kids.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:nth-child(2) {
    background-color: $accent5;
    color: $accent11;

    &::before {
      background-image: url(/assets/img/common/icon-staff.webp);
    }
  }
}