/* ============================
/* 幅に関する設定※案件ごとに変更
/* ========================= */

/* キャンパス設定 */
$pc-width: 1440;
$sp-width: 400;

/* インナー幅設定 */
$inner-wide: 1320px;
$inner: 1150px;
$inner-small: 1000px;

/* 左右の最低余白設定 */
$padding-pc: 25px;
$padding-sp: 20px;

/* ブレイクポイント設定 */
$sp: 400px;
$sp2: 500px;
$tab: 768px;
$laptop: $inner-wide + ($padding-pc * 4 );
$pc: 1440px;

/* ==============================================
/* メディアクエリとブレークポイントの関数※変更不可
/* ============================================== */

$breakpoints: (
  sm: $sp,
  sm2: $sp2,
  md: $tab,
  lg: $laptop,
  xl: $pc,
);

$mediaquerys: (
  sm: "screen and (max-width: #{map-get($breakpoints,'sm')})",
  sm2: "screen and (max-width: #{map-get($breakpoints,'sm2')})",
  md: "screen and (max-width: #{map-get($breakpoints,'md')})",
  lg: "screen and (max-width: #{map-get($breakpoints,'lg')})",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')})",
);

// @include mq(){}で書くとブレークポイントが反映される（初期値はmd）
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}
