@use "global"as *;

.p-footer-sp-menu {
  width: 100%;
}

.p-footer-sp-menu__links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.p-footer-sp-menu__link a {
  width: 100%;
  height: 100%;
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;

  &::before {
    content: "";
    display: block;
    aspect-ratio: 1 /1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--recruit a {
  background-color: $accent9;

  &::before {
    width: 2.2rem;
    background-image: url(/assets/img/common/icon-document.svg);

  }

}
.p-footer-sp-menu__link.p-footer-sp-menu__link--call a {
  background-color: $btn-store-tel;

  &::before {
    width: 2.2rem;
    background-image: url(/assets/img/common/icon-phone.svg);

  }
}

.p-footer-sp-menu__link.p-footer-sp-menu__link--contact a {
  background-color: $btn;

  &::before {
    width: 2.4rem;
    background-image: url(/assets/img/common/icon-calender-white.svg);
  }
}