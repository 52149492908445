@use "global"as *;

.p-top-care-accedent {
  position: relative;
  width: 100%;
  max-width: 100rem;
  margin-inline: auto;
  background-color: $main11;
  border-radius: 2rem;
  padding: 8.7rem 3rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1rem 1rem 4rem rgba(#122933, 4%);
  background-image: url(/assets/img/top/accident-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-top-care-accident__heading {
  position: absolute;
  top: -3rem;
  width: fit-content;
  max-width: 100%;
  padding: 0.7rem 3rem;
  border-radius: 2rem;
  background-color: $main1;
  font-weight: $bold;
  font-size: 3.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $white;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    width: 4.2rem;
    height: 1.8rem;
    bottom: 1px;
    left: 50%;
    background-color: $main1;
    transform: translate(-50%, 100%);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }

  @include mq("md") {
    min-width: 32rem;
    font-size: 2.4rem;
    padding: 0.5rem 3rem;
  }
}

.p-top-care-accident__selected {
  margin-top: 5rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}


.p-top-care-accident__list {
  margin-top: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  @include mq("md") {
    margin-top: 2rem;
    gap: 1.6rem;
    display: block;
  }
}

.p-top-care-accident__banner {
  width: 38rem;
  aspect-ratio: 380 / 180;
  transition: opacity 0.3s;

  &:hover {
    opacity:0.7;
  }

  @include mq("md") {
    width: 100%;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-top-care-accident__message {
  margin-top: 4rem;

  @include mq("md") {
    margin-top: 3rem;
  }
}

.p-top-care-accident__button {
  margin-top: 2.4rem;
  text-align: center;

  @include mq("md") {
    margin-top: 2rem;
  }
}

.p-top-care-accident__item:first-child{
  @include mq('md') {   
    margin-bottom: 2rem;
  }
}