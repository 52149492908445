@use "global"as *;

.c-page-mv {
  width: 100%;
  padding-top: 9rem;
  padding-bottom: 8.5rem;
  background-image: url(/assets/img/common/page-mv-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    padding-top: 8rem;
    padding-bottom: 8.2rem;
    background-image: url(/assets/img/common/page-mv-bg-sp.webp);
  }
}

.c-page-mv__inner {
  position: relative;
}


.c-page-mv__breadcrumb {
  position: absolute;
  left: $padding-pc;
  bottom: -2rem;
  translate: 0 100%;

  @include mq("md") {
    left: $padding-sp;
    bottom: -3rem;
  }
}