@use "global"as *;

.c-text {
  font-size: max(1.6rem, 12px);
  font-weight: $regular;
  line-height: 200%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-size: 1.4rem;
  }
}

.c-text.c-text--lh150 {
  line-height: 150%;
}

.c-text.c-text--lh180 {
  line-height: 180%;
}

.c-text.c-text--fw500 {
  font-weight: $medium;
}

.c-text.c-text--center {
  text-align: center;
}

.c-text.c-text--reverse {
  color: $white;
}

/* PCだけのmodifier */
.c-text.c-text--pc-center {
  text-align: center;
  
  @include mq("md") {
    text-align: left;
  }
}

.c-text.c-text--pc-fw500 {
  font-weight: $medium;

  @include mq("md") {
    font-weight: $regular;
  }
}

.c-text.c-text--pc-ls0 {
  letter-spacing: 0;
  @include mq("md") {
    letter-spacing: 0.05em;
  }
}


/* SPだけのmodifier */
.c-text.c-text--no-change {
  @include mq("md") {
    font-size: 1.6rem;
  }
}

.c-text.c-text--sp-fz16 {
  @include mq("md") {
    font-size: 1.6rem;
  }
}

.c-text.c-text--sp-ls0 {
  @include mq("md") {
    letter-spacing: 0;
  }
}

.c-text.c-text--sp-lh180 {
  @include mq("md") {
    line-height: 180%;
  }
}