@use "global"as *;

.p-top-mv {
  position: relative;
  width: 100%;
  padding-top: 2.9rem;
  margin-top: -2.9rem;
  padding-bottom: vw(1440, 112);
  background-image: url(/assets/img/top/mv-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: hidden;

  @include mq("md") {
    background-image: url(/assets/img/top/mv-bg-sp.webp);
    background-position: center bottom;
    padding-bottom: 6.3rem;
    padding-top: 0;
    margin-top: 0;
  }

  &::before {
    content:"";
    position: absolute;
    top: 0;
    left: -2rem;
    width: 17.5rem;
    height: 19.9rem;
    background-image: url(/assets/img/top/mv-deco1.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: $deco;
    
    @include mq("md") {
      width: 7.5rem;
      height: 8.6rem;
      top: 1.8rem;
      left: -1.7rem;
      }
  }

  &::after {
    content:"";
    position: absolute;
    bottom: 0.3rem;
    right: -4rem;
    width: 17.5rem;
    height: 19.9rem;
    background-image: url(/assets/img/top/mv-deco2.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 5rem;
      height: 5.7rem;
      top: 39.5rem;
      bottom: auto;
      right: -0.2rem;
    }
  }
}

.p-top-mv__splide {
  margin-inline: auto vw(1440, 50);
  width: vw(1440, 900);
  aspect-ratio: 900 / 640;
  border-radius: vw(1440, 30);
  overflow: hidden;

  @include mq("md") {
    margin-inline: auto 0;
    width: 36.5rem ;
    aspect-ratio: 365 / 400;
    border-radius: 3rem 0 0 3rem;
  }
}

.p-top-mv__splide .splide__track {
  width: 100%;
  height: 100%;
}

.p-top-mv__splide .splide__list {
  width: 100%;
  height: 100%;
}

.p-top-mv__splide .splide__slide {
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    @include mq('md') {
      object-fit: inherit;
    }
  }
}

/* pagination */
.p-top-mv .splide__pagination {
  position: absolute;
  top: auto;
  left: auto;
  right: vw(1440, 95);
  bottom: vw(1440, 48);

  padding: 0;
  display: flex;
  gap: vw(1440, 15);

  @include mq("md") {
    display: none;
  }
}

.p-top-mv .splide__pagination__page {
  margin: 0;
  width: vw(1440, 8);
  height: vw(1440, 8);
  background-color: $white;
  border-radius: 50%;
  opacity: 1;
  transition: background-color 0.3s;
}

.p-top-mv .splide__pagination__page.is-active {
  transform: scale(1);
  background-color: $main6;
}

/* animation */
.p-top-mv .splide__slide img {
  transition: 12s ease-out;
}

.p-top-mv .splide__slide.is-active img {
  transform: scale(1.15);
  transition-delay: 0s;
}

.p-top-mv__body {
  position: absolute;
  top: vw(1440, 238);
  left: vw(1440, 100);

  @include mq("md") {
    top: 29.4rem;
    left: 2rem;
    right: 2rem;
  }
}

.p-top-mv__catch {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: vw(1440, 30);
  z-index: $before-deco;

  @include mq("md") {
    gap: 1.5rem;
  }
}

.p-top-mv__catch:first-child .p-top-mv__catch-text {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}


.p-top-mv__catch-text {
  display: flex;
  align-items: center;
  width: max-content;
  background-color: $white;
  padding: vw(1440, 20);
  border-radius: vw(1440, 5);
  box-shadow: (vw(1440, 5) vw(1440, 5) 0 $main1);
  font-weight: $bold;
  font-size: vw(1440, 50);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(1rem);

  @include mq("md") {
    padding: 1.2rem 1.5rem;
    border-radius: 1rem;
    box-shadow: (0.4rem 0.4rem 0 $main1);
    font-weight: $bold;
    font-size: 3rem;

  }
}

.p-top-mv__catch-text .accent {
  color: $accent2;
}

.p-top-mv__catch-text .large {
  font-size: vw(1440, 54);

  @include mq("md") {
    font-size: 3.2rem;
  }
}

.p-top-mv__button {
  margin-top: vw(1440, 254);
  margin-left: vw(1440, 45);

  @include mq("md") {
    margin-top: 15.8rem;
    text-align: center;
  }

}


.p-top-mv__news {
  margin-top: vw(1440, -30);
  margin-left: vw(1440, 100);

  @include mq("md") {
    margin-top: 6rem;
    margin-left: 0;
    width: 100%;
    padding: 0 $padding-sp;
  }

}

