@use "global"as *;

.p-voice {}

.p-voice__inner {}

.l-inner {}

.p-voice__container {
  padding-top: 5rem;
  padding-bottom: 10rem;

  @include mq("md") {
    padding-bottom: 8rem;
  }

}

.p-voice__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 6rem 5rem;

  @include mq("md") {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
}


.p-voice__pagination {
  margin-top: 8rem;

  @include mq("md") {
    margin-top: 6rem;
  }

  & .nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @include mq("md") {
      gap: 0.6rem;
    }
  }

  & .page-numbers {
    font-family: $english;
    display: block;
    width: 4.5rem;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: $bold;
    font-size: 2.4rem;
    line-height: 100%;
    letter-spacing: 0.1em;

    @include mq("md") {
      width: 3.5rem;
      font-size: 1.8rem;
    }
  }

  & a.page-numbers,
  & .page-numbers.dots {
    color: $main3;
  }

  & a.page-numbers {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  & .page-numbers.current {
    color: $white;
    background-color: $main3;
    border-radius: 50%;
  }

  & .page-numbers.prev span,
  & .page-numbers.next span {
    width: 1.8rem;
    aspect-ratio: 1/1;
    background-image: url(/assets/img/common/pagination-arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 1.4rem;
    }
  }

  & .page-numbers.prev span {
    rotate: 180deg;
  }

}

.navigation {}

.pagination {}

.nav-links {}



.current {}

.prev {}

.dots {}

.next {}