@use "global"as *;

.p-store-menu {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: $lightgray;

  @include mq("md") {
    padding-top: 4rem;
  }

}

.p-store-menu__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-menu__banner {
  margin-top: 4rem;

  @include mq("md") {
    margin-top: 2rem;
  }
}

.p-store-menu__insurance {
  margin-top: 6rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-store-menu__self-pay {
  margin-top: 6rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-store-self-pay__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq("md") {
    align-items: flex-start;
  }
}

.p-store-self-pay__heading {
  position: relative;
  padding-bottom: 1rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main8;

  @include mq("md") {
    width: 100%;
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background-image: url(/assets/img/common/dot-line-main-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

  }
}

.p-store-self-pay__introduction {
  margin-top: 2rem;
}

.p-store-self-pay__list {
  margin-top: 5rem;
  display: flex;
  gap: 5rem;
  justify-content: center;
  flex-wrap: wrap;

  @include mq("md") {
    flex-direction: column;
    margin-top: 4rem;
  }
}

.p-store-self-pay__item {
  max-width: calc((100% - 10rem) / 3);

  @include mq("md") {
    max-width: initial;
  }
}
