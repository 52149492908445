@use "global"as *;

.p-header {
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 4rem;
  padding-bottom: 3.4rem;
  background-color: rgba($white,0%);

  @include mq("md") {
    background-color: $white;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.p-header__inner {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: vw(1440, 1240 + strip-unit($padding-pc) * 2);
  padding-right: $padding-pc;
  padding-left: $padding-pc;
  position: relative;
  z-index: $header;

  @include mq("md") {
    padding-right: $padding-sp;
    padding-left: 0;
  }
}

.p-header__inner.p-header__inner--wide {
  max-width: vw(1440, 1340 + strip-unit($padding-pc) * 2);
  @include mq('md') {
    padding-left: $padding-sp;
  }
}

.p-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-header__logo {
  width: 24.7rem;
  aspect-ratio: 247 / 50;

  @include mq("md") {
    width: 18rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.p-header__logo.p-header__logo--store {
  @include mq("lg") {
    width: 18rem;
  }
}

.p-header__nav {
  margin-left: auto;
}