@use "global" as *;


.c-sec-heading.c-sec-heading--case {
  & .c-sec-heading__wrapper {
    gap: 2rem;

    @include mq("md") {
      gap: 1.2rem;
    }
  }

  & .c-sec-heading__main {
    font-size: 2.8rem;
    line-height: 100%;
    letter-spacing: 0;
  }
}