@use "global"as *;

.p-store-message {
  margin-top: 8rem;

  @include mq("md") {
    margin-top: 6rem;
  }
}

.p-store-message__container {
  padding-left: 5.5rem;
  padding-right: 4.5rem;

  @include mq("md") {
    padding: 0;
  }
}

.p-store-message__wrapper {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  gap: 4rem;

@include mq("md") {
  margin-top: 4rem;
  flex-direction: column-reverse;
  gap: 3rem;
}
}

.p-store-message__body {
  display: flex;
  flex-direction: column;
}

.p-store-message__title {
  position: relative;
  width: max-content;
  padding-bottom: 0.9rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main1;

  @include mq("md") {
    width: 100%;
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/store/message-line.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    
    @include mq("md") {
      background-image: url(/assets/img/store/message-line-sp.webp);

    }
  }
}

.p-store-message__title span{
  font-size: 2rem;

  @include mq("md") {
    font-size: 1.6rem;
  }
}

.p-store-message__detail {
  margin-top: 2rem;

  @include mq("md") {
    margin-top: 1.8rem;
  }

  & p + p {
    margin-top: 1em * 1.8;
  }
}


.p-store-message__img {
  width: 100%;
  max-width: 51rem;
  aspect-ratio: 510 / 420;
  border-radius: 2rem;
  overflow: hidden;
  flex-shrink: 0;

  @include mq("md") {
    max-width: initial;
    border-radius: 1.4rem;
    aspect-ratio: 360 / 260;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}