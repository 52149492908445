@use "global"as *;

.p-store-trouble {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 5.5rem;
  background-color: $main9;
  background-image: url(/assets/img/store/trouble-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}

.p-store-trouble__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-store-trouble__heading {
  position: relative;
  padding-inline: 2rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $white;

  @include mq("md") {
    padding-inline: 1.5rem;
    font-size: 1.8rem;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0.3rem;
    width: 2rem;
    height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      bottom: 0.7rem;
    }
  }

  &::before {
    background-image: url(/assets/img/store/trouble-heading-l.webp);
    left: 0;
    translate: -100%;
  }

  &::after {
    background-image: url(/assets/img/store/trouble-heading-r.webp);
    right: 0;
    translate: 100%;
  }


  & .strong {
    font-size: 4rem;
    line-height: 150%;

    @include mq("md") {
      font-size: 3rem;
    }
  }
}

.p-store-trouble__text {
  margin-top: 2rem;

  @include mq("md") {
    display: none;
  }
}

.p-store-trouble__list {
  margin-top: 4rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 4rem 3rem;

  @include mq("md") {
    margin-top: 2.7rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 1rem;
  }
}

.p-store-trouble__item {
  padding: 1rem 2.7rem 3rem;
  background-color: $white;
  border-radius: 2rem;
  border: 3px solid $main10;
  box-shadow: 0 0.2rem 2.7rem rgba(#000, 3%);

  @include mq("md") {
    padding: 1.5rem 1.3rem 2rem;
    border-radius: 1.3rem;
    border-width: 2px;
    box-shadow: 0 0.1rem 1.8rem rgba(#000, 3%);
  }
}

.p-store-trouble__title-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1rem;

  @include mq("md") {
    gap: 0.3rem;
    padding-left: 0;
  }
}

.p-store-trouble__img {
  width: 5rem;
  aspect-ratio: 50 / 80;

  @include mq("md") {
    width: 3.3rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-store-trouble__title {
  font-weight: $bold;
  font-size: 2rem;
  line-height: 144%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-size: 1.3rem;
  }
}

.p-store-troule__about {
  margin-top: 5.4rem;
  padding: 4rem;
  min-width: 78.7rem;
  background-color: $white;
  border-radius: 2rem;
  border: 3px solid $main8;
  box-shadow: 0 0.2rem 3rem rgba(#000, 3%);

  @include mq("md") {
    margin-top: 4rem;
    width: 100%;
    min-width: initial;
    padding: 2rem 2rem 4rem;
  }
}

.p-store-troule__about-text {
  margin-top: 2rem;
}

.p-store-troule__about-btn {
  margin-top: 4rem;
  text-align: center;

  @include mq("md") {
    margin-top: 3rem;
  }
}