@use "global"as *;

.l-inner {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: vw(1440, strip-unit($inner) + strip-unit($padding-pc) * 2);
  padding-right: $padding-pc;
  padding-left: $padding-pc;

  @include mq("md") {
    max-width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
  }
}

.l-inner.l-inner--wide {
  max-width: vw(1440, strip-unit($inner-wide) + strip-unit($padding-pc) * 2);

  @include mq("md") {
    max-width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
  }
}

.l-inner.l-inner--small {
  max-width: vw(1440, strip-unit($inner-small) + strip-unit($padding-pc) * 2);

  @include mq("md") {
    max-width: 100%;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
  }
}