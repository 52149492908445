@use "global" as *;


.c-definition-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include mq("md") {
    gap: 2rem;
  }
}

.c-definition-list__item {
  display: flex;
  gap: 4rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid $gray2;

  @include mq("md") {
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
  }

  & dt,
  & dd {
    font-weight: $medium;
    font-size: 2rem;
    line-height: 150%;
    letter-spacing: 0.05em;

    @include mq("md") {
      font-size: 1.6rem;
    }
  }

  & > dt {
    width: 20rem;
    flex-shrink: 0;

    @include mq("md") {
      width: 100%;
    }
  }

  & dt .small,
  & dd .small {
    font-size: 1.8rem;

    @include mq("md") {
      font-size: 1.4rem;
    }
  }
}
.c-definition-list__child-list{
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include mq("md") {
    gap: 2rem;
  }
}

.c-definition-list__child-item {
  display: flex;
  align-items: start;
  gap: 10rem;

  @include mq("md") {
    flex-direction: column;
    gap: 1.5rem;
  }

  & dt{
    width: max-content;
    min-width: 15rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main1;
    border-radius: 10rem;
    line-height: 100%;
    color: $white;

  }
}