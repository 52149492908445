@use "global" as *;


.c-breadcrumb {
  display: flex;
  align-items: center;
  gap: 1rem;

  & li {
    font-weight: $medium;
    font-size: max(1.5rem, 12px);
    line-height: 100%;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include mq("md") {
      font-size: 1.2rem;
    }

    & a {
      color: $breadcrumb-link;
      transition: opacity 0.3s;
      text-transform: uppercase;

      &:hover {
        opacity: 0.7;
      }
    }

    & span {
      color: $text;
    }
  }

  & li+li::before {
    content: "";
    display: block;
    width: 1.2rem;
    aspect-ratio: 1/1;
    background-image: url(/assets/img/common/breadcrumb-arrow.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 1rem;
    }
  }
}