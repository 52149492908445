@use "global"as *;

.c-modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($black, 70%);
  z-index: $modal;
}

.c-modal__overlay {
  position: absolute;
  inset: 0;
}

.c-modal__body {
  display: inline-block;
  width: 80rem;
  max-height: 90vh;
  background-color: $white;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include mq("md") {
    border-radius: 1rem;
    width: 90vw;
    max-height: 70vh;
  }
}

.c-modal__content {
  padding: 10rem 7.5rem;

  @include mq("md") {
    padding: 4rem 3rem;
  }
}

.c-modal__inner {}

.c-modal__title {
  margin-bottom: 4rem;
  font-weight: $bold;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    margin-bottom: 3rem;
    font-size: 2.4rem;
  }
}

.c-modal__text {
  font-weight: $medium;
  font-size: max(1.4rem, 12px);
  line-height: 170%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-size: 1.2rem;
  }
}

.c-modal__text+.c-modal__text {
  margin-top: 1em * 1.7;
}

.c-modal__subtitle {
  position: relative;
  margin: 6rem auto 2rem;
  padding-bottom: 0.5rem;
  width: max-content;
  font-weight: $bold;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $insurance1;

  @include mq("md") {
    margin: 4rem auto 2rem;
    font-size: 1.6rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-yellow-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.c-modal__message {
  margin-top: 6rem;
  position: relative;
  background-color: $white;
  border: 3px solid $insurance1;
  border-radius: 2rem;
  box-shadow: 1rem 1rem 4rem rgba(#000, 4%);
  padding: 5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50rem;
  margin-inline: auto;

  @include mq("md") {
    margin-top: 4rem;
    padding: 2.4rem 1.6rem;
  }

  &::after {
    content: "";
    position: absolute;
    right: -7.1rem;
    bottom: -1.5rem;
    width: 15rem;
    aspect-ratio: 150 / 98;
    background-image: url(/assets/img/common/cta-deco.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 10rem;
      right: -5rem;
      bottom: -2.5rem;
    }
  }
}

.c-modal__message-text {
  position: relative;
  font-weight: $bold;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    font-size: 1.4rem;
  }

  & .strong {
    font-size: 3.2rem;
    line-height: 150%;
    color: $main8;
    text-decoration: underline $accent8 0.7rem;
    text-underline-offset: -0.2rem;

    @include mq("md") {
      font-size: 1.8rem;
    }
  }
}

.c-modal__close-button {
  display: block;
  margin-top: 4rem;
  margin-inline: auto;
  padding: 1.4rem 2rem 1.6rem;
  width: max-content;
  min-width: 20rem;
  background-color: $btn;
  transition: background-color 0.3s;
  border-radius: 10rem;

  font-weight: $bold;
  font-size: max(1.4rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;

  @include mq("md") {
    font-size: 1.4rem;
  }

  &:hover {
    background-color: $btn-h;
  }
}

.c-modal__close-button.c-modal__close-button--secondary {
  background-color: $btn2;

  &:hover {
    background-color: $btn2-h;
  }
}

.c-modal__insurance-table {
  margin-inline: auto;
  width: 100%;
  max-width: 50rem;
  border-collapse: collapse;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #f6f6f6;

  font-weight: $medium;
  font-size: 1.7rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $black;

  @include mq("md") {
    font-size: 1.4rem;
  }

  & tr {
    display: grid;
    grid-template-columns: 11rem 1fr 1fr 1fr;
    grid-template-rows: 5rem;

    @include mq("md") {
      grid-template-columns: 8rem 1fr 1fr 1fr;
      grid-template-rows: 4.6rem;
    }
  }

  & th,
  & td {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & thead tr th {
    background-color: $modal-table;
  }

  & tr+tr {
    border-top: 0.1rem solid #D5DCDC;
  }

  & th+td,
  & td+td {
    border-left: 0.1rem solid #D5DCDC;
  }
}

.c-modal__menu-list {
  margin-top: 4rem;
  max-width: 50rem;
  margin-inline: auto;

  @include mq("md") {
    margin-top: 3rem;
  }
}

.c-modal__menu-item {
  display: flex;

  & dt,
  & dd {
    padding: 0 3rem 1rem;
    font-weight: $bold;
    font-size: 1.8rem;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: $main8;

    @include mq("md") {
      padding: 0 1.5rem 1rem;
      font-size: 1.4rem;
    }
  }

  & dt {
    width: 20rem;
    flex-shrink: 0;
    border-bottom: 2px solid $main3;

    @include mq("md") {
    width: 14rem;}
  }

  & dd {
    border-bottom: 2px solid $gray2;
    width: 100%;
  }
}

.c-modal__menu-item:not(:first-child) {
  margin-top: 2rem;
}