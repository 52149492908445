@use "global"as *;

.p-menu-detail {}

.p-menu-detail__container {
  margin-top: 5rem;
  padding-bottom: 10rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-menu-detail__heading {
  text-align: center;
  font-weight: $bold;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;

  @include mq("md") {
    font-size: 2.8rem;
  }
}

.p-menu-detail__img {
  margin-top: 5rem;
  width: 100%;
  aspect-ratio: 1000 / 600;
  border-radius: 2rem;
  overflow: hidden;

  @include mq("md") {
    margin-top: 4rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-menu-detail__body {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;

  @include mq("md") {
    gap: 5rem;
  }

  & section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  & h2 {
    position: relative;
    background-color: $lightgray;
    border: 1px solid $gray2;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 2rem 3rem;
    font-weight: $bold;
    font-size: 2.8rem;
    line-height: 100%;
    letter-spacing: 0.05em;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 100%;
      background-color: $main3;
    }

    @include mq("md") {
      padding: 1.5rem 2.5rem;
      font-size: 2rem;

      &::before {
        width: 0.8rem;
      }
    }
  }

  & h3 {
    padding-bottom: 1.5rem;
    font-weight: $bold;
    font-size: 2.4rem;
    color: $main8;
    border-bottom: 2px solid $gray2;

    @include mq("md") {
      padding-bottom: 1rem;
      font-size: 1.8rem;
    }
  }



  & p {
    font-weight: $medium;
    font-size: max(1.6rem, 14px);
    line-height: 180%;
    letter-spacing: 0.05em;

  }

  & dl {
    display: flex;

    & dt,
    & dd {
      padding: 0 3rem 1rem;
      font-weight: $bold;
      font-size: 1.8rem;
      line-height: 150%;
      letter-spacing: 0.05em;
      color: $main8;

      @include mq("md") {
        padding: 0 1.5rem 1rem;
      }
    }

    & dt {
      flex-shrink: 0;
      border-bottom: 2px solid $main3;
    }

    & dd {
      border-bottom: 2px solid $gray2;
      width: 100%;
    }

    & dl:not(:first-child){
      margin-top: 1rem;
    }
  }
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  & li {
    background-color: $main12;
    padding: 0.8rem 2rem 1rem;
    border-radius: 0.5rem;

    font-weight: $bold;
    font-size: max(1.6rem, 14px);
    line-height: 150%;
    letter-spacing: 0.05em;
    color: $main8;
  }
}
