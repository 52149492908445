@use "global"as *;

.p-store-reserve {
  position: relative;
  margin-top: 10rem;
  padding-top: 30rem;

  @include mq("md") {
    margin-top: 6rem;
    padding-top: 15rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    aspect-ratio: 1440 / 470;
    background-image: url(/assets/img/store/reserve-bg-pc.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: $z-bg;

    @include mq("md") {
      aspect-ratio: 390 / 250;
      background-image: url(/assets/img/store/reserve-bg-sp.webp);
    }
  }
}

.p-store-reserve__container {
  padding: 5rem 4.5rem 6rem;
  background-color: $white;
  border-radius: 2rem;
  box-shadow: 1rem 1rem 4rem rgba(#122933, 4%);

  @include mq("md") {
    padding: 4rem 2rem 5rem;
  }
}

.p-store-reserve__introduction {
  margin-top: 4rem;
  text-transform: uppercase;
}

.p-store-reserve__wrapper {
  margin-top: 2rem;
  display: flex;
  gap: 6rem;

  @include mq("md") {
    flex-direction: column-reverse;
    gap: 3rem;
  }
}

.p-store-reserve__content {
  width: 100%;
  max-width: 50rem;
  padding: 3rem 3rem 4rem;
  border-radius: 2rem;
  background-color: $reserve-line-bg;

  @include mq("md") {
    max-width: initial;
    padding: 3rem;
    border-radius: 1.1rem;
  }
}

.p-store-reserve__title {
  font-weight: $bold;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  color: $reserve-line;

  @include mq("md") {
    font-size: 2.4rem;
  }
}

.p-store-reserve__detail {
  margin-top: 2rem;
  font-weight: $medium;
  font-size: 1.7rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;

  @include mq("md") {
    font-size: 1.4rem;
    line-height: 150%;
  }
}

.p-store-reserve__button {
  margin-top: 3rem;
  text-align: center;

  @include mq("md") {
    margin-top: 2rem;
  }
}

.p-store-reserve__link {
  display: inline-flex;
  padding: 1.2rem 3rem;
  width: max-content;
  min-width: 20.6rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: $reserve-line-btn;
  border-radius: 10rem;

  font-weight: $bold;
  font-size: 1.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;
  text-transform: uppercase;

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  @include mq("md") {
    min-width: initial;
    padding: 1.2rem 4.2rem;
    font-size: 1.4rem;
  }

  &::before {
    content: "";
    display: block;
    width: 2.5rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/icon-calender-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-store-reserve__content:nth-child(2) {
  background-color: $reserve-tel-bg;

  .p-store-reserve__title {
    color: $reserve-tel;
  }

  .p-store-reserve__link {
    background-color: $reserve-tel-btn;

    &::before {
      background-image: url(/assets/img/common/icon-tel-white.svg);
    }
  }

}