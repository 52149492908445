@use "global"as *;

.p-top-care-message {
  padding-top: 3rem;
}

.p-top-care-message__wrapper {
  position: relative;
  width: fit-content;
  max-width: 100%;
  margin-inline: auto;

  @include mq("md") {
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    left: -6.15rem;
    top: -2.2rem;
    width: 7.7rem;
    height: 10rem;
    translate: -100%;
    background-image: url(/assets/img/top/message-deco1.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      left: 0rem;
      top: -1.8rem;
      width: 3.85rem;
      height: 5rem;
      translate: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: -3.95rem;
    bottom: -3.2rem;
    width: 9.9rem;
    height: 7.9rem;
    translate: 100%;
    background-image: url(/assets/img/top/message-deco2.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      right: -0.5rem;
      bottom: -3.8rem;
      width: 5rem;
      height: 4rem;
      translate: none;
    }
  }
}

.p-top-care-message__text {
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    font-size: 1.8rem;
  }

  & .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    @include mq("md") {
      gap: 1rem;
    }

    & .img {
      width: 3rem;
      aspect-ratio: 1/ 1;

      @include mq("md") {
        width: 2.6rem;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  & .strong {
    display: inline-block;
    font-size: 4rem;
    line-height: 180%;
    color: $main8;
    text-decoration: underline $accent8 1.2rem;
    text-underline-offset: -0.5rem;
    padding-inline: 0.1em;

    @include mq("md") {
      font-size: 3rem;
      text-decoration-thickness: 0.8rem;
      text-underline-offset: -0.4rem;
      line-height: 120%;

    }
  }

  & .mt {
    @include mq("md") {
      display: inline-block;
      margin-top: 1em * 1.8;
    }
  }
}