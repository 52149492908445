@use "global" as *;

.c-cta {
  position: relative;
  width: 100%;
  max-width: 78.7rem;
  margin-inline: auto;
  border-radius: 2rem;
  border: 3px solid $main7;
  box-shadow: 0 0.2rem 3rem rgba(#000000, 3%);
  background-color: $white;

  @include mq("md") {
    border-width: 2px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -2rem;
    right: -11.4rem;
    width: 25rem;
    height: 15rem;
    background-image: url(/assets/img/common/cta-deco.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    rotate: 5deg;

    @include mq("md") {
      bottom: -2.3rem;
      right: -5.7rem;
      width: 12.5rem;
      height: 7.5rem;
    }
  }
}

.c-cta__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.c-cta__text {
  font-weight: $medium;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $main8;

  @include mq("md") {
    font-size: 1.6rem;
  }

  & .strong {
    font-weight: $bold;
    font-size: 4rem;
    line-height: 150%;
    color: $accent7;

    @include mq("md") {
      font-size: 2rem;
    }
  }

  & .logo {
    width: 30.9rem;
    aspect-ratio: 309 / 40;

    @include mq("md") {
      width: 15.4rem;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.c-cta__text.c-cta__text--flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @include mq("md") {
    gap: 0.5rem;
  }
}

.c-cta__store-text {
  font-weight: $medium;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    font-size: 1.6rem;
    line-height: 180%;
    letter-spacing: 0;
  }
}

.c-cta__button {
  margin-top: 3.5rem;
  text-align: center;

  @include mq("md") {
    margin-top: 2.3rem;
  }

  &.c-cta__button--store {
    margin-top: 4rem;

     @include mq("md") {
      margin-top: 3rem;
     }
  }
}