@use "global"as *;

.p-store-pelvis-trouble {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 13rem;
  width: 100%;
  background-image: url(/assets/img/store/pelvis-bg-pc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    padding-top: 22rem;
    background-image: url(/assets/img/store/pelvis-bg-sp.webp);
  }

  &::after {
    content: "";
    width: 4rem;
    aspect-ratio: 40 / 70;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    translate: -50%;
    background-image: url(/assets/img/store/pelvis-arrow.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}


.p-store-pelvis-trouble__container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.p-store-pelvis-trouble__heading {
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    font-size: 2.8rem;
  }

  & .strong {
    font-size: 4rem;
    line-height: 150%;
    text-decoration: underline $accent8 1.2rem;
    text-underline-offset: -0.5rem;

    @include mq("md") {
      font-size: 3.9rem;
      line-height: 150%;
      text-decoration-thickness: 0.8rem;
      text-underline-offset: -0.4rem;
    }
  }

}

.p-store-pelvis-trouble__content {
  margin-top: 4rem;
  display: flex;
  gap: 4rem;

  @include mq("md") {
    margin-top: 3rem;
    flex-direction: column;
    gap: 1rem;
  }

}

.p-store-pelvis-trouble__list {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  @include mq("md") {
    gap: 1rem;
  }
}

.p-store-pelvis-trouble__list li {
  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  gap: 1rem;

  @include mq("md") {
    font-size: 1.6rem;
  }

  &::before {
    content: "";
    display: block;
    width: 3.5rem;
    aspect-ratio: 1/1;
    background-image: url(/assets/img/store/check-icon.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 3rem;
    }

  }
}