@use "global"as *;

.p-store-campaign {
  padding-top: 8rem;
  padding-bottom: 8rem;

  @include mq("md") {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.p-store-campaign__banner {
  margin-top: 3rem;
  margin-inline: auto;

  @include mq("md") {
    margin-top: 2rem;
  }
}

