@use "global"as *;

.p-store-faq {
  margin-top: 8rem;

  @include mq("md") {
    margin-top: 6rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: $lightgray;
  }
}

.p-store-faq__container {
  width: 100%;
  padding: 5rem 10rem;
  background-color: $lightgray;
  border-radius: 2rem;

  @include mq("md") {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

.p-store-faq__list {
  margin-top: 5rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-faq-list {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.p-faq-list__item {
  padding: 1rem 0;
  background-color: $white;
  border-radius: 0.5rem;

  @include mq("md") {
    padding: 0;
    border-radius: 1rem;
  }
}

.p-faq-list__item-question {
  padding: 2rem 5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  cursor: pointer;

  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;

  @include mq("md") {
    padding: 2rem;
    gap: 1.5rem;
    font-size: 1.6rem;
  }

  &::before {
    content: "Q";
    font-family: $english;
    font-weight: $regular;
    font-size: 3rem;
    line-height: 100%;
    color: $main3;
  }
}


.p-faq-list__item-question .button {
  position: relative;
  margin-left: auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: $main3;
  flex-shrink: 0;

  &::before,
  &::after {
    content: "";
    width: 1.1rem;
    height: 1px;
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    transition: transform 0.3s, opacity 0.3s;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}

.p-faq-list__item-question.is-open .button::before {
  transform: translate(-50%, -50%) rotate(0);
  opacity: 0;
}

.p-faq-list__item-answer {
  height: 0;
  overflow: hidden;

  & p {
    border-top: 0.2rem solid $main14;
    padding: 2rem 5rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    font-weight: $medium;
    font-size: 1.8rem;
    line-height: 150%;
    letter-spacing: 0.05em;

    @include mq("md") {
      padding: 2rem;
      gap: 1.5rem;
      font-size: 1.6rem;
    }

    &::before {
      content: "A";
      font-family: $english;
      font-weight: $regular;
      font-size: 3rem;
      line-height: 100%;
      color: $main3;
    }
  }


}