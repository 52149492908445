@use "global"as *;

.c-campaign-banner {
  width: fit-content;
}

// .c-campaign-banner a {
//   transition: opacity 0.3s;
  

//   &:hover {
//     opacity: 0.8;
//   }
// }

.c-campaign-banner__img {
  width: 72rem;
  aspect-ratio: 720 / 400;

  @include mq("md") {
    width: 36rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}