@use "global"as *;

.c-button {
    display: inline-flex;
    width: max-content;
    padding: 2rem 1.5rem 2rem 5rem;
    background-color: $btn;
    align-items: center;
    gap: 3rem;
    font-weight: $bold;
    font-size: 1.8rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $white;
    border-radius: 1rem;
    transition: background-color 0.3s;

    @include mq("md") {
        padding: 1.5rem 1.5rem 1.5rem 4rem;
        gap: 2.5rem;
        font-size: 1.4rem;
    }

    &::after {
        content: "";
        display: block;
        width: 2rem;
        height: 2rem;
        background-image: url(/assets/img/common/triangle-arrow-white.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @include mq("md") {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &:hover {
        background-color: $btn-h;
    }

}

.c-button.c-button--line {
    border: 3px solid $white;

}

.c-button.c-button--mv {
    @include mq("md") {
        padding: 2rem 1.5rem 2rem 4rem;
        min-width: 21rem;
        text-align: center;
        justify-content: center;
        font-size: 1.8rem;
    }
}

.c-button.c-button--mv.c-button::after{
    @include mq("md") {
        width: 1.9rem;
        height: 1.9rem;
    }
}

.c-button.c-button--secondary {
    background-color: $btn2;

    &:hover {
        background-color: $btn2-h;
    }
}

.c-button.c-button--tertiary {
    background-color: $btn3;

    &:hover {
        background-color: $btn3-h;
    }
}

.c-button.c-button--thin {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    white-space: nowrap;
}

.c-button.c-button--pc-thin {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    white-space: nowrap;

    @include mq("md") {
        padding: 1.5rem 1.5rem 1.5rem 4rem;
        gap: 2.5rem;
        font-size: 1.8rem;

        &::after {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

}

.c-button.c-button--full {
    width: 100%;
    justify-content: center;
}


span.c-button {
    pointer-events: none;
}

.c-button.c-button--store {
    padding: 1.5rem 2rem;
    gap: 1rem;
}

.c-button.c-button--search {
    padding: 3rem 6.5rem 3rem 10rem;
    font-size: 2.2rem;
    justify-content: center;

    @include mq("md") {
        width: 100%;
        padding: 3rem 1.5rem 3rem 4rem;
        font-size: 2rem;
    }

    &::after {
        width: 2.8rem;
        height: 2.8rem;
        background-image: url(/assets/img/common/icon-search.svg);

        @include mq("md") {
            width: 2.6rem;
            height: 2.6rem;
        }
    }
}

.c-button.c-button--sp-same {
    @include mq("md") {
        padding: 2rem 1.5rem 2rem 5rem;
        gap: 3rem;
        font-size: 1.8rem;
    }

    &::after {
        @include mq("md") {
            width: 2rem;
            height: 2rem;
        }
    }
}