@use "global"as *;

.p-top-menu {
  padding-top: 8rem;

  @include mq("md") {
    padding-top: 6rem;
  }

}


.p-top-menu__content {
  margin-top: 2rem;
}

.p-top-menu-list {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6rem 5rem;

  @include mq("md") {
    margin-top: 4rem;
    flex-direction: column;
    gap: 5rem;
  }
}

.p-top-menu-list__item {
  max-width: calc((100% - 10rem) / 3);

  @include mq("md") {
    max-width: 36rem;
  }
}


.p-top-menu__links {
  margin-top: 5rem;
  margin-inline: auto;
  width: 100%;
  max-width: 100rem;
  padding: 4rem;
  border-radius: 2rem;
  background-color: $lightgray;

  @include mq("md") {
    padding: 3rem 3rem 4rem;

  }
}

.p-top-menu__link-list {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;

  @include mq("md") {
    margin-top: 3rem;
    width: fit-content;
    margin-inline: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

}

.p-top-menu__link-item {
  @include mq("md") {
    width: 100%;

    & .c-button {
      width: 100%;
    }
  }
}