@use "global"as *;

.p-store-pelvis-menu {}

.p-store-pelvis-menu__content {
  position: relative;

  &::before {
    content: "";
    width: vw(1440, 1295);
    position: absolute;
    top: 3rem;
    right: 0;
    bottom: 0;
    background-color: $main5;
    border-radius: 2rem 0 0 2rem;
    z-index: index $z-bg;

    @include mq("md") {
      width: 100%;
      border-radius: 0;
    }
  }
}

.p-store-pelvis-menu__heading {
  width: max-content;
  min-width: 70.1rem;
  margin-inline: auto;
  position: relative;
  padding: 0.7rem 3rem;
  background-color: $main3;
  border-radius: 2rem;
  font-weight: $bold;
  font-size: 3.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $white;

  @include mq("md") {
    min-width: initial;
    padding: 0.5rem 3.5rem;
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 1px;
    translate: -50% 100%;
    width: 4.2rem;
    height: 1.8rem;
    background-color: $main3;
    clip-path: polygon(50% 100%, 0 0, 100% 0);

    @include mq("md") {
      margin-top: -1.5rem;
    }
  }

}

.p-store-pelvis-menu__inner {
  position: relative;
  width: 134.5rem;
  max-width: 100%;
  padding-inline: 5rem;
  margin-right: auto;

  @include mq("md") {
    padding-inline: 2rem;
  }
}

.p-store-pelvis-menu__wrapper {
  padding-top: 5.8rem;
  padding-bottom: 6rem;
  display: flex;
  align-items: center;
  gap: 4rem;

  @include mq("md") {
    padding-top: 4.5rem;
    padding-bottom: 5rem;
    flex-direction: column;
    gap: 3rem;
  }
}

.p-store-pelvis-menu__img {
  max-width: vw(1440, 670);
  width: 100%;
  aspect-ratio: 670 / 410;
  border: 3px solid $white;
  border-radius: 2rem;
  overflow: hidden;
  filter: drop-shadow(0 0.7rem 2.2rem rgba(#000, 8%));
  flex-shrink: 0;

  @include mq("md") {
    max-width: initial;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-store-pelvis-menu__body {
  width: 100%;
  max-width: vw(1440, 533);
  margin-right: auto;

  @include mq("md") {
    max-width: initial;
  }
}

.p-store-pelvis-menu__title-wrap {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  background-color: $white;
  padding: 1rem 1.4rem;
  border-left: 3px solid $main3;

  @include mq("md") {
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 0;
  }
}

.p-store-pelvis-menu__title {
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main8;
}

.p-store-pelvis-menu__subtitle {
  font-weight: $bold;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.p-store-pelvis-menu__detail {
  margin-top: 2.7rem;
  font-weight: $medium;
  font-size: max(1.4rem, 12px);
  line-height: 200%;
  letter-spacing: 0.05em;

  & .strong {
    font-weight: $bold;
    color: $main8;
  }

  @include mq("md") {
    margin-top: 3rem;
  }
}

.p-store-pelvis-menu__button {
  margin-top: 2.1rem;

  @include mq("md") {
    margin-top: 3rem;
  }
}

.p-store-pelvis-menu__link {
  width: max-content;
  padding: 1.4rem 1.5rem 1.4rem 5rem;
  background-color: $btn;
  display: inline-flex;
  align-items: center;
  gap: 3rem;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;
  border-radius: 10rem;
  transition: background-color 0.3s;

  &::after {
    content: "";
    display: block;
    width: 1.6rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/triangle-arrow-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    background-color: $btn-h;
  }
}

/* 産後セクション差異スタイル */
.p-store-pelvis-menu__content:nth-child(2) {
  margin-top: 8rem;

  @include mq("md") {
    margin-top: 6rem;
  }

  &::before {
    top: 3rem;
    left: 0;
    right: auto;
    bottom: 0;
    background-color: $accent5;
    border-radius: 0 2rem 2rem 0;

    @include mq("md") {
      border-radius: 0;
    }
  }

  & .p-store-pelvis-menu__heading,
  & .p-store-pelvis-menu__heading::after {
    background-color: $accent11;
  }

  & .p-store-pelvis-menu__inner {
    margin-right: 0;
    margin-left: auto;
  }

  & .p-store-pelvis-menu__wrapper {
    flex-direction: row-reverse;

    @include mq("md") {
      flex-direction: column;
    }
  }

  & .p-store-pelvis-menu__title-wrap {
    border-left: 3px solid $accent11;

  }

  & .p-store-pelvis-menu__title {
    color: $accent11;
  }

  & .p-store-pelvis-menu__link {
    background-color: $btn3;

    &:hover {
      background-color: $btn3-h;
    }
  }

}