@use "global"as *;

.p-top-voice {
  padding-top: 8rem;
  padding-bottom: 8rem;

  @include mq("md") {
    padding-bottom: 6rem;
  }
}


.p-top-voice__introduction {
  margin-top: 2rem;
}


.p-top-voice__splide {
  & .splide__track {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include mq("md") {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
  & .splide__arrow.button {
    width: 6rem;
    height: 6rem;
    background-color: transparent;
    transition: opacity 0.3s;
    top: 24rem;
    opacity: 1;

    @include mq("md") {
      width: 5rem;
      height: 5rem;
    }

    &.prev {
      left: -2.5rem;

      @include mq("md") {
        left: -1.5rem;
      }
    }

    &.next {
      right: -2.5rem;

      @include mq("md") {
        right: -1.5rem;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  & .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 6rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-size: contain;
    

    @include mq("md") {
      width: 5rem;
      height: 5rem;
    }
  }

  & .button.prev::before {
    background-image: url(/assets/img/common/arrow-prev.webp);
  }

  & .button.next::before {
    background-image: url(/assets/img/common/arrow-next.webp);
  }

  & .button:disabled::before {
    opacity: .5;
  }

}

.p-top-voice__button {
  text-align: center;

}
