@use "global"as *;


.c-page-cta {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $lightgray2;

  @include mq("md") {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-main-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.c-page-cta__content {
  width: 100%;
  max-width: 72rem;
  margin-inline: auto;
  padding: 4rem;
  background-color: $white;
  border: 3px solid $main7;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @include mq("md") {
    padding: 3rem 3rem;
  }
}

.c-page-cta__title {
  font-weight: $medium;
  font-size: 2.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main8;

  @include mq("md") {
    font-size: 2rem;
    white-space: nowrap;
  }
}

.c-page-cta__button {
  text-align: center;

  @include mq("md") {
    width: 100%;
  }
}