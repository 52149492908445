@use "global"as *;

.p-store-accident {
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 8rem;

  @include mq("md") {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.p-store-accident__container {
  padding-left: 5.5rem;
  padding-right: 4.5rem;

  @include mq("md") {
    padding: 0;
  }
}

.p-store-accident__wrapper {
  display: flex;
  align-items: center;
  gap: 4rem;

  @include mq("md") {
    flex-direction: column-reverse;
    gap: 3rem;
  }
}

.p-store-accident__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50rem;
  max-width: 100%;
}

.p-store-accident__title {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main1;

  @include mq("md") {
    width: 100%;
    padding-bottom: 1rem;
    font-size: 2.4rem;
  }

  &::before {
    content: "";
    display: block;
    width: 9rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/store/store-accident-medal.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: drop-shadow(0 0.5rem 3rem rgba(#000, 8%));

    @include mq("md") {
      width: 8rem;
    }
  }

  &::after {
    @include mq("md") {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: url(/assets/img/store/accident-line-sp.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.p-store-accident__detail {
  margin-top: 2rem;
}

.p-store-accident__button {
  margin-top: 3rem;
}

.p-store-accident__button-link {
  width: max-content;
  padding: 1.4rem 1.5rem 1.4rem 5rem;
  background-color: $btn2;
  display: inline-flex;
  align-items: center;
  gap: 3rem;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;
  border-radius: 10rem;
  transition: background-color 0.3s;

  &::after {
    content: "";
    display: block;
    width: 1.6rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/triangle-arrow-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    background-color: $btn2-h;
  }
}

.p-store-accident__img {
  width: 51rem;
  max-width: 100%;
  aspect-ratio: 510 / 420;
  border-radius: 2rem;
  overflow: hidden;

  @include mq("md") {
    aspect-ratio: 360 / 260;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}