@use "global" as *;


.l-2col {
  width: 100%;
  display: grid;
  grid-template-columns: 27.6rem 76rem;
  grid-template-rows: 1fr;
  justify-content: space-between;

  @include mq("md") {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
  }
}

.l-2col__side {
  @include mq("md") {
    order: 2;
  }
}

.l-2col__main {
  @include mq("md") {
    order: 1;
  }
}