@use "global"as *;

.c-voice-item {
  position: relative;
  width: 100%;
  background-color: $white;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(#000, 8%);
}

.c-voice-item__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  &:hover {
    &::before {
      background-color: $white;
    }

    &::after {
      background-image: url(/assets/img/common/triangle-arrow-main.svg);
    }

    & .c-voice-item__content {
      background-color: $main4;
    }

    & .c-voice-item__dl>* {
      color: $white;
    }

    & .c-voice-item__info::after {
      background-image: url(/assets/img/common/card-line-white.webp);
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: -3rem;
    bottom: -3rem;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: $main4;
    transition: background-color 0.3s;

  }

  &::after {
    content: "";
    position: absolute;
    right: 1rem;
    bottom: 1.1rem;
    width: 2rem;
    height: 2rem;
    background-image: url(/assets/img/common/triangle-arrow-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 0.3s;
  }
}

.c-voice-item__img {
  width: 100%;
  aspect-ratio: 350 / 240;

  @include mq("md") {
    aspect-ratio: 360 / 240;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-voice-item__content {
  width: 100%;
  height: 100%;
  padding: 4rem 3rem 11.2rem 3rem;
  background-color: $white;
  transition: background-color 0.3s;

  @include mq("md") {
    padding: 3rem 4rem 11.2rem 3rem;
  }
}


.c-voice-item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/card-line.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    transition: background-image 0.3s;
  }
}

.c-voice-item__parson {
  padding: 0.8rem 2rem 1rem;
  background-color: $main12;
  border-radius: 0.5rem;

  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main3;
}

.c-voice-item__dl {
  display: flex;
  align-items: center;

  & dt,
  & dd:not(.c-text) {
    font-weight: $medium;
    font-size: 2rem;
    line-height: 100%;
    transition: color 0.3s;
  }

  & dt {
    display: flex;
    min-width: 11rem;

    &::after {
      content: "：";
      display: block;
      margin-left: auto;
    }

  }
}

.c-voice-item__body {
  padding-top: 2rem;
}

.c-voice-item__dl.c-voice-item__dl--flex-col {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.c-voice-item__to-store {
  position: absolute;
  bottom: 5rem;
  left: 2.4rem;
}

.c-voice-item__button {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: max-content;
  padding: 1.2rem 1.5rem 1.2rem 2rem;
  background-color: $white;
  border: 1px solid $main3;
  border-radius: 10rem;
  font-weight: $bold;
  font-size: 1.6rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main3;
  transition: background-color 0.3s, color 0.3s;

  &::after {
    content: "";
    display: block;
    width: 1.5rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/triangle-arrow-main.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 0.3s;
  }

  &:hover {
    background-color: $main3;
    color: $white;

    &::after {
      background-image: url(/assets/img/common/triangle-arrow-white.svg);

    }
  }
}