@use "global"as *;

.c-menu-item {
  position: relative;
  width: 100%;
  min-height: 50.4rem;
  background-color: $white;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(#000, 8%);

  @include mq("md") {
    min-height: initial;
  }

  & .c-menu-item__wrapper,
  & .c-menu-item__link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      &::before {
        background-color: $white;
      }

      &::after {
        background-image: url(/assets/img/common/triangle-arrow-main.svg);
      }

      & .c-menu-item__body {
        background-color: $main4;
      }

      & .c-menu-item__title {
        color: $white;

        &::after {
          background-image: url(/assets/img/common/card-line-white.webp);
        }
      }


      & .c-menu-item__detail {
        color: $white;
      }
    }

    &::before {
      content: "";
      position: absolute;
      right: -3rem;
      bottom: -3rem;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background-color: $main4;
      transition: background-color 0.3s;

    }

    &::after {
      content: "";
      position: absolute;
      right: 1rem;
      bottom: 1.1rem;
      width: 2rem;
      height: 2rem;
      background-image: url(/assets/img/common/triangle-arrow-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: background-image 0.3s;

    }
  }

  & .c-menu-item__img {
    width: 100%;
    aspect-ratio: 350 / 240;

    @include mq("md") {
      aspect-ratio: 360 / 240;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .c-menu-item__body {
    width: 100%;
    height: 100%;
    padding: 4rem 3rem;
    background-color: $white;
    transition: background-color 0.3s;

    @include mq("md") {
      padding: 3rem 4rem 5rem 3rem;
    }
  }

  & .c-menu-item__title {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    font-weight: $bold;
    font-size: 2.4rem;
    line-height: 100%;
    transition: color 0.3s;

    & .small {
      font-size: 1.8rem;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-image: url(/assets/img/common/card-line.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left center;
      transition: background-image 0.3s;
    }
  }

  & .c-menu-item__detail {
    margin-top: 2rem;
    transition: color 0.3s;
  }

}

.c-menu-item.c-menu-item--page {
  min-height: 49.1rem;

  @include mq("md") {
    min-height: initial;
  }

  .c-menu-item__body {
    width: 100%;
    height: 100%;
    padding: 3rem 2rem 5rem;
    background-color: $white;
    transition: background-color 0.3s;
  }

  .c-menu-item__title {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
}