@use "global" as *;

.p-store-cta-reserve {
width: 100%;
padding-top: 5rem;
padding-bottom: 5rem;
background-color: $accent3;
overflow: hidden;

@include mq("md") {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
}

.p-store-cta-reserve__container {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include mq("md") {
    padding-top: 3rem;
  }
}


