@use "global"as *;

.p-store-pelvis-message {
  width: 100%;
}

.p-store-pelvis-message__heading {
  width: 100%;
  padding-top: 2.8rem;
  padding-bottom: 2.9rem;
  background-color: $main3;
  box-shadow: 0 0.7rem 2.2rem rgba(#000, 8%);

  font-weight: $bold;
  font-size: 3.2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;
  text-align: center;

  @include mq("md") {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 2.4rem;
    line-height: 150%;
  }

  & .strong {
    font-size: 4rem;
    line-height: 100%;
    color: $accent10;

    @include mq("md") {
      font-size: 3.2rem;
      line-height: 150%;
    }
  }
}

.p-store-pelvis-message__container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 8rem;

  @include mq("md") {
    padding-top: 3rem;
    padding-bottom: 18.5rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 2rem;
    left: 10.7rem;
    width: 6.5rem;
    height: 9.6rem;
    background-image: url(/assets/img/store/pelvis-message-deco1.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      top: auto;
      left: 8.4rem;
      bottom: 6.8rem;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: 14.7rem;
    bottom: 3.9rem;
    width: 8rem;
    height: 8rem;
    background-image: url(/assets/img/store/pelvis-message-deco2.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      top: auto;
      right: 7.6rem;
      bottom: 7.5rem;
    }
  }
}

.p-store-pelvis-message__text {
  font-weight: $medium;
  font-size: 2.4rem;
  line-height: 200%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
font-size: 2rem;
  }

  & .strong {
    font-weight: $bold;
    color: $main8;
  }
}

.u-sp {}

.u-pc {}