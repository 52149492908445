@use "global"as *;

.p-store-mv {
  position: relative;
  width: 100%;
  aspect-ratio: 1440 / 816;

  @include mq("md") {
    aspect-ratio: 400 /550;
  }
}

.p-store-mv__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $z-bg;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.p-store-mv__body {
  margin-top: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq("md") {
    margin-top: 2rem;
    width: 100%;
  }
}

.p-store-mv__title {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: $bold;
  font-size: 5rem;
  line-height: 144%;
  color: $white;

  @include mq("md") {
    font-size: 3.2rem;
  }

  &::before {
    content: "";
    display: block;
    width: 3rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/logo-illust-white.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

}

.p-store-mv__time-list {
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  font-weight: $bold;
  font-size: 1.8rem;
  line-height: 144%;
  color: $white;

  @include mq("md") {
    margin-top: 1rem;
    font-size: 1.6rem;
  }
}

.p-store-mv__features {
  margin-top: 1.5rem;
  width: 89rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 3rem;

  @include mq("md") {
    margin-top: 3rem;
    width: 39rem;
    gap: 0.2rem;
  }
}

.p-store-mv__feature-item {
  width: 100%;
  aspect-ratio: 1 / 1;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    filter: drop-shadow(0 1rem 6rem, rgba(#000, 7%));

    @include mq("md") {
      filter: drop-shadow(0 0.5rem 3rem, rgba(#000, 7%));
    }
  }
}

.p-store-mv-splide {
  margin-top: 6.2rem;
  width: 126.6rem;
  max-width: 100%;

  @include mq("md") {
    margin-top: 3rem;
    margin-left: auto;
    width: calc(380 / 400 *100%);
  }

  & .splide__slide {
    width: 30rem;
    aspect-ratio: 300 / 210;
    border-radius: 2rem;
    border: 5px solid $white;
    overflow: hidden;

    @include mq("md") {
      width: 20rem;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .splide__arrow.button {
    width: 4rem;
    height: 4rem;
    background-color: transparent;
    transition: opacity 0.3s;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;

    @include mq("md") {
      width: 3rem;
      height: 3rem;
    }

    &.prev {
      left: 0;
      translate: -50%;

    }

    &.next {
      right: 0;
      translate: 50%;

      @include mq("md") {
        right: 0.5rem;
        translate: 0;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  & .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 4rem;
    height: 4rem;
    background-repeat: no-repeat;
    background-size: contain;

    @include mq("md") {
      width: 3rem;
      height: 3rem;
    }
  }

  & .button.prev::before {
    background-image: url(/assets/img/common/arrow-prev.webp);
  }

  & .button.next::before {
    background-image: url(/assets/img/common/arrow-next.webp);
  }

  & .button:disabled::before {
    opacity: .5;
  }

}