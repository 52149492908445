@use "global" as *;


.p-case-side {
  @include mq("md") {
    margin-top: 6rem;
  }
}

.p-case-side__heading {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 100%;

  &::before {
    content: "";
    display: block;
    width: 5rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/icon-note.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  @include mq("md") {
    justify-content: start;
  }
}

.p-case-side__content {
  margin-top: 2rem;
  padding: 3rem 3rem 4rem;
  border-radius: 2rem;
  background-color: $lightgray;


  @include mq("md") {
    margin-top: 3rem;
    padding: 3rem 2rem 4rem;
  }
}

.p-case-side__content-title {
  position: relative;
  font-weight: $bold;
  font-size: 2rem;
  line-height: 100%;
  padding-bottom: 2rem;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-main-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
  }

}

.p-case-side__main-list {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.p-case-side__main-item {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  font-weight: $bold;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-case-side__child-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.p-case-side__child-item a {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 2.4rem;
  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
  white-space: nowrap;

  &::before {
    content: "";
    display: block;
    width: 1.2rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/arrow-nml-black.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    opacity: 0.7;
  }
}


.p-case-side__link {
  margin-top: 4rem;
  width: 100%;
  aspect-ratio: 276 / 175;
  border-radius: 1rem;
  overflow: hidden;

  @include mq("md") {
    aspect-ratio: 360 / 230;
    border-radius: 1.4rem;
  }
}

.p-case-side__link a {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;

  background-image: url(/assets/img/case/store.webp);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  & p {
    position: relative;
    font-weight: $bold;
    font-size: 2.2rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $white;

    @include mq("md") {
      font-size: 2.8rem;
    }
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(#050505, 30%);
    transition: background-color 0.3s;
  }

  &:hover {
    &::before {
      background-color: rgba(#050505, 60%);

    }
  }
}

.p-case-side__link-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 2.2rem;
  border-radius: 2.2rem;
  border: 2px solid $white;
  background-color: $main3;

  font-weight: $bold;
  font-size: max(1.2rem, 10px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;

  @include mq("md") {
    font-size: 1.6rem;
  }

  &::after {
    content: "";
    display: block;
    width: max(1.2rem, 10px);
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/triangle-arrow-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 1.6rem;
    }
  }
}