@use "global"as *;


.p-footer__content {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-image: url(/assets/img/common/footer-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mq("md") {
    padding-top: 6rem;
    padding-bottom: 12rem;
    background-image: url(/assets/img/common/footer-bg-sp.webp);
  }
}

.p-footer__body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include mq("md") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.p-footer__logo {
  width: 37rem;
  aspect-ratio: 370 / 74;

  @include mq("md") {
    width: 22rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}

.p-footer__information {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq("md") {
    align-items: center;
  }
}

.p-footer__text {
  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 200%;
  letter-spacing: 0.05em;

  @include mq("md") {
    line-height: 150%;
  }
}

.p-footer__nav {
  display: flex;
  align-items: flex-start;
  gap: 6.4rem;
  margin-left: auto;

  @include mq("md") {
    width: 100%;
    margin-top: 4rem;
    margin-left: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    gap: 2rem;
    justify-content: space-between;
  }
}

.p-footer__nav-list {
  display: flex;
  flex-direction: column;
}

.p-footer__nav-sp-wrapper {
  display: contents;

  @include mq("md") {
    display: block;
  }
}

.p-footer__nav-item {
  &>a {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-weight: $medium;
    font-size: 1.6rem;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &::before {
      content: "";
      display: block;
      width: 1.2rem;
      aspect-ratio: 1 / 1;
      background-image: url(/assets/img/common/arrow-nml-black.webp);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &:first-child>a {
    padding-top: 0;
  }

  & a {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

}

.p-footer__nav-child-list {
  display: flex;
  flex-direction: column;
}

.p-footer__nav-child-item {
  &>a {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2.4rem;
    font-weight: $regular;
    font-size: 1.6rem;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &::before {
      content: "";
      display: block;
      width: 1rem;
      height: 0.1rem;
      background-color: $black;
    }
  }
}

.p-footer__copy {
  margin-top: 4rem;
}

.p-footer__copy-text {
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $darkgray;

  @include mq("md") {
    text-align: center;
  }
}


.p-footer__sp-menu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $sp-footer;
}