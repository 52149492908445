@use "global" as *;

html {
  font-size: 62.5%;

  @include mq("xl") {
    font-size: vw($pc,10px);
  }
  @include mq("lg") {
    font-size: vw($laptop,10px);
  }
  @include mq("md") {
    font-size: vw($sp,10px);
  }
}

body {
  font-family: $japanese;
  background-color: $bg;
  color: $text;
}

a {
  color: $text;
}

/* PCでは電話番号のクリックを無効にする */
a[href^="tel:"] {
  pointer-events: none;
  @include mq("md") {
    pointer-events: auto;
  }
}

iframe {
  max-width: 100%;
}
