/* ============================
/* z-indexの数値管理
/* ========================= */

/* 共通パーツ */
$header: 6;
$hamburger: 10;
$drawer:5;
$z-bg:-1;
$sp-footer:4;
$cta-body: 1;
$modal: 20;

/* あしらい */
$deco:2;
$before-deco:3;

/* スライダー */
$arrow:1;

/* top */
$news:2;