@use "global" as *;


.p-case-main {
  padding-left: 3rem;
  padding-right: 3rem;

  @include mq("md") {
    padding-right: 0;
    padding-left: 0;
  }
}


.p-case-main__introduction {
  margin-top: 5rem;
  font-weight: $medium;
  font-size: 1.6rem;
  line-height: 180%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-case-main__anchors {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 16.5rem));
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;

  @include mq("md") {
    margin-top: 4rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.p-case-main__anchor a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.2rem 1.6rem;
  background-color: $white;
  border: 1px solid $main3;
  border-radius: 10rem;
  transition: color 0.3s, background-color 0.3s;

  font-weight: $bold;
  font-size: max(1.4rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main3;
  white-space: nowrap;

  & .small {
    font-size: max(1.2rem, 10px);
  }

  &::after {
    content: "";
    display: block;
    width: 1.2rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/arrow-nml-main.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    rotate: 90deg;
    flex-shrink: 0;
    transition: background-image 0.3s;
  }

  &:hover {
    color: $white;
    background-color: $main3;

    &::after {
      background-image: url(/assets/img/common/arrow-nml-white.webp);
    }
  }

}

.p-case-main__body {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;

  @include mq("md") {
    margin-top: 4rem;
    gap: 5rem;
  }
}

.p-case-main__content {
  position: relative;
  width: 100%;
  padding: 0 5rem 4rem;

  @include mq("md") {
    padding: 0 0 3rem;
    width: inherit;
    margin: 0 20px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 2.8rem 0 0 0;
    background-color: $lightgray;
    z-index: $z-bg;
    border-radius: 2rem;

    @include mq("md") {
      inset: 2.3rem -20px 0 -20px;      
    }
  }
}

.p-case-main__content-title {
  position: relative;
  padding: 1rem 3rem;
  width: max-content;
  min-width: 35.3rem;
  margin-inline: auto;
  background-color: $main3;
  border-radius: 2rem;

  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-align: center;
  color: $white;

  & .small {
    font-size: 2rem;

    @include mq("md") {
      font-size: 1.6rem;
    }
  }

  @include mq("md") {
    min-width: 20.5rem;
    padding: 0.7rem 2rem 0.9rem;
    font-size: 2rem;
    line-height: 150%;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 50%;
    translate: -50% 100%;
    width: 2.9rem;
    height: 1.5rem;
    background-color: $main3;
    clip-path: polygon(50% 100%, 0 0, 100% 0);

    @include mq("md") {
      width: 2.5rem;
      height: 1.3rem;
    }
  }
}

.p-case-main__content-list {
  margin-top: 4.4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 2rem 1.5rem;

  @include mq("md") {
    margin-top: 2.7rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

.p-case-main__content-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.2rem 0.5rem;
  background-color: $main12;
  border-radius: 1rem;

  font-weight: $bold;
  font-size: max(1.6rem, 12px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main8;
  transition: opacity 0.3s;

  @include mq("md") {
    font-size: 1.4rem;
  }

  &::after {
    content: "";
    display: block;
    width: 1.8rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/triangle-arrow-main2.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 1.6rem;
    }
  }

  &:hover {
    opacity: 0.7;
  }
}
