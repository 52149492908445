@use "global"as *;


.p-courses-recommend-item {
  display: none;
}

.p-courses-recommend-item.is-active {
  display: block;
}

.p-courses-recommend-item__link {
  display: flex;
  background-color: $white;
  outline: 3px solid transparent;
  border-radius: 1rem;
  transition: outline 0.3s;
  overflow: hidden;

  @include mq("md") {
    flex-direction: column;
  }

  &:hover {
    outline: 3px solid $main3;
  }
}

.p-courses-recommend-item__img {
  width: 18rem;
  aspect-ratio: 180 / 150;
  flex-shrink: 0;
  overflow: hidden;

  @include mq("md") {
    width: 100%;
    aspect-ratio: 320 / 150;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-courses-recommend-item__body {
  background-color: $white;
  padding: 1rem 2rem;

  @include mq("md") {
    padding: 1rem 2rem 2rem;
  }
}

.p-courses-recommend-item__wrap {
  display: flex;
  align-items: center;
  gap: 1rem 1.5rem;
  flex-wrap: wrap;
}

.p-courses-recommend-item__title {
  font-weight: $bold;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-courses-recommend-item__category {
  background-color: $white;
  padding: 0.8rem 1rem;
  border: 1px solid $main3;
  border-radius: 10rem;

  font-weight: $bold;
  font-size: max(1.2rem, 10px);
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $main3;

}

.p-courses-recommend-item__detail {
  margin-top: 1rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.p-courses-recommend-item__more {
  display: none;

  @include mq("md") {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    font-weight: $bold;
    font-size: 1.4rem;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $main3;

    &::after {
      content: "";
      display: block;
      width: 1.2rem;
      aspect-ratio: 1 / 1;
      background-image: url(/assets/img/common/arrow-nml-main.webp);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}