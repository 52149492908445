@use "global"as *;

.p-courses {
  width: 100%;
}

.p-courses__select {
  width: 100%;
  padding-top: 3rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: $main12;

  @include mq("md") {
    padding-top: 2rem;
  }
}

.p-courses__select-list {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8rem;


  @include mq("md") {
    gap: 0.8rem;
  }
}

.p-courses__select-item {
  border-radius: 2rem 2rem 0 0;
  background-color: $white;
  border-top: 2px solid $main3;
  border-right: 2px solid $main3;
  border-left: 2px solid $main3;
  overflow: hidden;
}

.p-courses__select-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 4rem;
  font-weight: $bold;
  font-size: 2.4rem;
  line-height: 100%;
  letter-spacing: 0;
  text-align: center;

  @include mq("md") {
    padding: 1rem;
    gap: 1rem;
    font-size: 1.8rem;
    line-height: 130%;
    text-align: left;
  }

  &::before {
    content: "";
    display: block;
    width: 3.5rem;
    aspect-ratio: 1 / 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 2rem;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 2rem;
    aspect-ratio: 1 / 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 1.8rem;
    }
  }
}

span.p-courses__select-link {
  color: $text;

  &::after {
    background-image: url(/assets/img/common/arrow-nml-main.webp);
    rotate: 90deg;
  }
}

a.p-courses__select-link {
  color: $white;
  background-color: $main3;

  &::after {
    content: "";
    display: block;
    width: 2rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/arrow-nml-white.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-courses__select-link.p-courses__select-link--worry::before {
  background-image: url(/assets/img/common/icon-check-main.webp);
}

.p-courses__select-link.p-courses__select-link--course::before {
  background-image: url(/assets/img/common/icon-list-main.webp);
}

a.p-courses__select-link.p-courses__select-link--worry::before {
  background-image: url(/assets/img/common/icon-check-white.webp);
}

a.p-courses__select-link.p-courses__select-link--course::before {
  background-image: url(/assets/img/common/icon-list-white.webp);
}

.p-courses__container {
  padding-top: 5rem;
  padding-bottom: 10rem;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }
}

.p-courses__heading {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  font-weight: $bold;
  font-size: 3.2rem;
  line-height: 100%;
  letter-spacing: 0;

  @include mq("md") {
    font-size: 2.4rem;
    gap: 1rem;
  }

  &::before {
    content: "";
    display: block;
    width: 5rem;
    aspect-ratio: 1 / 1;
    background-image: url(/assets/img/common/icon-list-main.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      width: 4rem;
    }
  }
}

.p-courses__heading.p-courses__heading--check::before {
  background-image: url(/assets/img/common/icon-check-main-bicolor.webp);
}

.p-courses__introduction {
  margin-top: 3rem;
  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 180%;
  letter-spacing: 0.05em;
}

.p-courses__worry-content+.p-courses__worry-content {
  margin-top: 6rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-courses__worry-content {
  margin-top: 2rem;

  @include mq("md") {
    margin-top: 3rem;
  }
}


.p-courses__list-content:first-of-type {
  margin-top: 5.2rem;
  padding-top: 0;

  @include mq("md") {
    margin-top: 3rem;
  }
}

.p-courses__list-content {
  padding-top: 6rem;
  padding-bottom: 5.5rem;
  background-color: $white;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

}

.p-course-list-content:nth-of-type(even) {
  background-color: $lightgray;

}

.p-course-list-content__heading {
  position: relative;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main8;
  padding-bottom: 1rem;

  @include mq("md") {
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-main-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
  }

  & .small {
    font-size: 2rem;

    @include mq("md") {
      font-size: 1.6rem;
    }
  }
}

.p-course-list-content__list {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 6rem 5rem;

  @include mq("md") {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
}
