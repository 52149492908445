@use "global"as *;

.p-top-news {
  position: relative;
  z-index: $news;
}

.p-top-news__content {
  width: 100%;
  max-width: 84rem;
  background-color: $white;
  border-radius: 0.5rem;
  padding: 2.5rem 4rem;

  @include mq("md") {
    padding: 2.5rem 2rem 2rem;
    margin-inline: auto;
  }
}

.p-top-news__wrapper {
  display: flex;
  align-items: flex-start;

  @include mq("md") {
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1.7rem;
  }
}

.p-top-news__heading {
  flex-shrink: 0;
  padding: 0.5rem 0;
  font-weight: $bold;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main1;

  @include mq("md") {
    padding: 0;
    font-size: 1.6rem;
  }
}

.p-top-news__link {
  width: 100%;
  display: contents;
  align-items: center;
  transition: opacity 0.3s;

  &:hover{
    opacity: 0.7;

    & .p-top-news__arrow {
      transform: translateX(0.5rem);
    }
  }
}

.p-top-news__date {
  flex-shrink: 0;
  padding: 0.8rem 0;
  margin-left: 3rem;
  font-family: $english;
  font-weight: $medium;
  font-size: 1.6rem;
  color: $darkgray;
}

.p-top-news__title {
  margin-inline: 2rem;
  padding: 0.65rem 0;
  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;

  @include mq("md") {
    margin-inline: 0;
    padding: 0;
    flex-shrink: 0;
    max-width: calc(268 / 320 * 100%);
    font-size: 1.6rem;
  }
}

.p-top-news__arrow {
  position: relative;
  margin-left: auto;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  background-color: $main2;
  border-radius: 50%;
  transition:transform 0.3s;

  @include mq("md") {
    width: 3.2rem;
    height: 3.2rem;
  }

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    object-fit: contain;

    @include mq("md") {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}