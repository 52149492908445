@use "global" as *;

.p-hamburger {
  position: fixed;
  top: 1.8rem;
  right: 2rem;
  width: 4rem;
  height: 4rem;
  background-color: $main2;
  border-radius: 10rem;
  z-index: $hamburger;
  cursor: pointer;
}

.p-hamburger div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 2rem;
  height: 1.2rem;
}

.p-hamburger div span {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: $white;
  position: absolute;
  transition: transform 0.3s, opacity 0.3s;
}

.p-hamburger div span:first-child {
  top: 0;
}

.p-hamburger.is-active div span:first-child {
  top:50%;
  transform: translateY(-50%) rotate(-135deg);
}

.p-hamburger div span:nth-child(2){
  top: 0.6rem;

  @include mq("md") {
    top: 0.5rem;
  }
}

.p-hamburger.is-active div span:nth-child(2){
  opacity: 0;
}

.p-hamburger div span:last-child {
  top: 1.2rem;

  @include mq("md") {
    top: 1rem;
  }
}

.p-hamburger.is-active div span:last-child {
  top:50%;
  transform: translateY(-50%) rotate(135deg);
}