@use "global"as *;

.p-top-care-trouble {
  position: relative;
  padding: 4rem 5rem 5rem;
  background-color: $main9;
  filter: drop-shadow(0 1rem 3rem rgba(#000000, 8%));
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/assets/img/top/care-trouble-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main9;

  @include mq("md") {
    padding: 3rem 1rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 1px;
    width: 8rem;
    height: 4rem;
    transform: translate(-50%, 100%);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    background-color: $main9;
    background-image: url(/assets/img/top/care-trouble-bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $main9;

    @include mq("md") {
      width: 4.2rem;
      height: 2.1rem;
    }
  }

  @include mq("md") {}
}

.p-top-care-trouble__heading {
  position: relative;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $white;
  text-align: center;

  @include mq("md") {
    font-size: 2.2rem;
  }

  & .strong {
    font-size: 4rem;
    line-height: 150%;

    @include mq("md") {
      font-size: 3.4rem;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: -2.4rem;
    bottom: 1rem;
    transform: translateX(-100%);
    width: 12.4rem;
    height: 17.8rem;
    background-image: url(/assets/img/top/care-deco1.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: -0.6rem;
    bottom: -0.4rem;
    transform: translateX(100%);
    width: 14.4rem;
    height: 20rem;
    background-image: url(/assets/img/top/care-deco2.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      display: none;
    }
  }
}

.p-top-care-trouble__list {
  width: 100%;
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 4rem 2.2rem;

  @include mq("md") {
    margin-top: 1.8rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 1rem;
  }
}

.p-top-care-trouble__item {
  background-color: $white;
  padding: 4rem 3rem;
  border-radius: 2rem;
  border: 3px solid $main10;
  box-shadow: 0 0.2rem 2.8rem rgba(#000, 3%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq("md") {
    padding: 2rem 1rem;
    border-radius: 1rem;
    border-width: 2px;
    box-shadow: 0 0.1rem 1.4rem rgba(#000, 3%);
  }
}

.p-top-care-trouble__title {
  font-weight: $bold;
  font-size: 2.4rem;
  line-height: 100%;

  @include mq("md") {
    font-size: 1.6rem;
  }
}

.p-top-care-trouble__img {
  margin-top: 3rem;
  width: 8.6rem;
  aspect-ratio: 86 / 80;

  @include mq("md") {
    margin-top: 2rem;
    width: 4.3rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.p-top-care-trouble__detail {
  margin-top: 2rem;

  @include mq("md") {
    margin-top: 1rem;
  }

  &.c-text {
    @include mq("md") {
      font-size: 1.2rem;
    }
  }
}

.p-top-care-trouble__button {
  margin-top: 5rem;

  @include mq("md") {
    margin-top: 3.2rem;
  }
}