@use "global"as *;

.p-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* headerの高さ */
  padding-top: 7.6rem;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  z-index: $drawer;
}

.p-drawer__overlay {
  width: 100%;
  height: 100%;
}

.p-drawer__container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $lightgray;
  padding: 3rem 2rem;
  transform: translateX(100%);

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.p-drawer__body {
  width: 100%;
  margin-inline: auto;
}

.p-drawer__list {
  display: flex;
  flex-direction: column;
}

.p-drawer__item {
  position: relative;
  border-bottom: 1px solid $gray;
}

/* 全aタグ共通 */
.p-drawer__item a {
  display: flex;
  align-items: center;
  transition: opacity 0.3s;

  &::after {
    content: "";
    display: block;
    margin-left: auto;
    width: 0.8rem;
    height: 1rem;
    background-image: url(/assets/img/common/triangle-arrow-black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    opacity: 0.7;
  }
}

/* メインリストのaタグのみ */
.p-drawer__item>a {
  padding: 2rem 2.6rem 2rem 2rem;
  color: $text;
  font-weight: $medium;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.p-drawer__item:has(.p-drawer__stores-list)>a {
  padding-right: 2rem;
  padding-bottom: 1.5rem;

  &::after {
    display: none;
  }
}


.p-drawer__switch {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.4rem;
  aspect-ratio: 1/1;
  background-color: $main2;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.2rem;
    height: 0.06rem;
    background-color: $white;
    transition: translate 0.3s;

    &:nth-child(2) {
      transform-origin: center center;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.p-drawer__switch.is-open span:nth-child(2) {
  transform: translate(-50%, -50%) rotate(0deg);
}

.p-drawer__stores-list {
  height: 0;
  overflow: hidden;
  padding-right: 2.6rem;
  padding-left: 2rem;
}

.p-drawer__store-item a {
  padding: 0.75rem 0rem 0.75rem 1.5rem;
  font-weight: $medium;
  font-size: 1.6em;
  line-height: 150%;
  letter-spacing: 0.05em;
}

.p-drawer__store-item:first-child a {
  padding-top: 0;
}

.p-drawer__store-item:last-child a {
  padding-bottom: 2rem;
}

.p-drawer__button-list {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.p-drawer__button-item {
  width: 100%;
}

.p-drawer__button-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  width: 100%;
  padding: 2rem 1.5rem 2rem 4rem;
  background-color: $btn;
  border-radius: 1rem;
  transition: background-color 0.3s;
  font-weight: $bold;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;

  &:hover {
    background-color: $btn-h;
  }

  &::after {
    content: "";
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(/assets/img/common/triangle-arrow-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.p-drawer__button-item--secondary-color a {
  background-color: $btn3;

  &:hover{
    background-color: $btn3-h;
  }
}

.p-drawer__button-item--store-color a {
  background-color: $btn-store-tel;

  &:hover{
    opacity: 0.7;
  }
}