@use "global"as *;

.p-bottom-cta {
  padding-top: 10rem;
  padding-bottom: 10rem;

  @include mq("md") {
    padding-top: 6rem;
    padding-bottom: 8rem;
  }
}

.p-bottom-cta__inner {
  width: 100%;
  max-width: 1000px + $padding-pc * 2;
  margin-inline: auto;

  @include mq("md") {
    padding: 0;
  }
}


.p-bottom-cta__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;

  @include mq("md") {
    grid-template-columns: 1fr;
  }
}

.p-bottom-cta__item {
  aspect-ratio: 500 / 400;
  overflow: hidden;

  @include mq("md") {
    aspect-ratio: 400 / 250;
  }

  &:nth-child(1) {
    border-radius: 2rem 0 0 2rem;

    @include mq("md") {
      border-radius: 0;
    }
  }

  &:nth-child(2) {
    border-radius: 0 2rem 2rem 0;

    @include mq("md") {
      border-radius: 0;
    }
  }
}

.p-bottom-cta__item a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(#050505, 40%);
    transition: background-color 0.3s;
  }

  &:hover {

    &::after {
      background-color: rgba(#050505, 70%);
    }
    & .c-button {
      background-color: $btn-h;
    }
  }
}

.p-bottom-cta__item:nth-child(1) a {
  background-image: url(/assets/img/top/bottom-cta-recruit.webp);
}

.p-bottom-cta__item:nth-child(2) a {
  background-image: url(/assets/img/top/bottom-cta-property.webp);
}

.p-bottom-cta__body {
  position: absolute;
  left: 4rem;
  bottom: 3.8rem;
  z-index: $cta-body;

  @include mq("md") {
    left: 2rem;
    bottom: 1.9rem;
  }
}

.p-bottom-cta__logo {
  width: 7.9rem;
  aspect-ratio: 79 / 17;

  @include mq("md") {
    width: 5.4rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.p-bottom-cta__title {
  margin-top: 0.5rem;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $white;

  @include mq("md") {
    font-size: 2rem;
  }
}

.p-bottom-cta__text {
  margin-top: 2rem;

  @include mq("md") {
    margin-top: 1rem;
  }
}

.p-bottom-cta__button {
  margin-top: 2rem;

  @include mq("md") {
    margin-top: 1rem;
  }
}

.c-button.c-button--small {
  padding: 1.2rem 1.5rem 1.2rem 3rem;
  gap: 0.5rem;
  font-size: 1.4rem;

  &::after {
    width: 1.4rem;
    height: 1.4rem;
  }

  @include mq("md") {
    padding: 1rem 1rem 1rem 2.4rem;
    gap: 0.2rem;
    font-size: 1.1rem;

    &::after {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

}


.p-bottom-cta__sponsor {
  margin-top: 6rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-bottom-cta__sponsor-img {
  width: 100%;
  aspect-ratio: 1000 / 270;

  @include mq("md") {
    padding-right: $padding-sp;
    padding-left: $padding-sp;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}