@use "global"as *;

.c-page-card-text {
  font-weight: $medium;
  font-size: max(1.4rem, 12px);
  line-height: 150%;
  letter-spacing: 0.05em;
}

.c-page-card-text.c-page-card-text--ls0 {
  letter-spacing: 0;
}