@use "global"as *;

.c-sub-heading {
  position: relative;
  width: max-content;
  margin-inline: auto;
  padding-inline: 2.1rem;
  font-weight: $bold;
  font-size: 2.4rem;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-align: center;

  @include mq("md") {
    line-height: 150%;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    width: 1.1rem;
    height: 2.6rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq("md") {
      display: none;
    }
  }

  &::before {
    left: 0;
    background-image: url(/assets/img/common/sub-heading-l.webp);
  }

  &::after {
    right: 0;
    background-image: url(/assets/img/common/sub-heading-r.webp);
  }

  & .strong {
    font-size: 3.2rem;
    line-height: 120%;
  }
}

.c-sub-heading.c-sub-heading--store-reserve {
  color: $main8;
  font-weight: $medium;

  &::before {
    background-image: url(/assets/img/common/sub-heading-main-l.webp);

    @include mq("md") {
      display: block;
    }
  }

  &::after {
    background-image: url(/assets/img/common/sub-heading-main-r.webp);

    @include mq("md") {
      display: block;
    }
  }
}

.c-sub-heading.c-sub-heading--sp-small {
  @include mq("md") {
    font-size: 2rem;
  }
}