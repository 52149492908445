@use "global"as *;

.p-stores {
  padding-top: 5rem;
  padding-bottom: 10rem;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }
}

.p-stores__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 6rem 5rem;

  @include mq("md") {
    grid-template-columns: 1fr;
    gap: 5rem;
  }
}
