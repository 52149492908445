@use "global"as *;

.p-top-care {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 12rem;

  @include mq("md") {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30rem;
    background-image: url(/assets/img/top/care-bg-top-pc.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    z-index: $z-bg;

    @include mq("md") {
      height: 8rem;
      background-image: url(/assets/img/top/care-bg-top-sp.webp);
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30rem;
    background-image: url(/assets/img/top/care-bg-bottom-pc.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    z-index: $z-bg;


    @include mq("md") {
      height: 8rem;
      background-image: url(/assets/img/top/care-bg-bottom-sp.webp);
    }
  }
}

.p-top-care__bg {
  background-color: $lightgray;
}

.p-top-care__content {
  margin-top: 5rem;

  @include mq("md") {
    margin-top: 4rem;
  }
}

.p-top-care__trouble {
  margin-bottom: 4rem;

  @include mq("md") {
    margin-bottom: 2rem;
  }
}

.p-top-care__insurance {
  margin-top: 8rem;

  @include mq("md") {
    margin-top: 5rem;
  }
}


.p-top-care__accedent {
  margin-top: 11rem;

  @include mq("md") {
    margin-top: 5rem;
  }
}

