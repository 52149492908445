@use "global"as *;

.p-top-point {
  position: relative;
  background-color: $white;
  padding-top: 10.2rem;

  @include mq("lg") {
    margin-right: 4rem;
  }

  @include mq("md") {
    margin-right: 0;
    padding-top: 6rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 14rem;
    transform: translateY(-100%);
    background-image: url(/assets/img/top/about-bg-pc.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    @include mq("md") {
      height: 8rem;
      background-image: url(/assets/img/top/about-bg-sp.webp);
    }
  }
}

.p-top-point__content {
  padding-top: 5rem;
  padding-bottom: 15rem;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
}

.p-top-point__items {
  display: flex;
  flex-direction: column;
  gap: 8rem;

  @include mq("md") {
    gap: 5rem;
  }
}

.p-top-point__item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding-left: 4rem;

  @include mq("md") {
    flex-direction: column;
    gap: 5rem;
    padding-left: 0;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @include mq("md") {
      flex-direction: column;
    }

    & .p-top-point__img {
      box-shadow: 4rem 4rem 0 $accent5;

      @include mq("md") {
        box-shadow: 2rem 2rem 0 $accent5;
      }
    }
  }
}

.p-top-point__img {
  width: 53.5rem;
  aspect-ratio: 535 / 340;
  box-shadow: -4rem 4rem 0 $main15;
  border-radius: 2rem;
  outline: 0.5rem solid $white;
  outline-offset: -1.5rem;
  flex-shrink: 0;
  overflow: hidden;

  @include mq("md") {
    margin-left: 0;
    width: 100%;
    box-shadow: -2rem 2rem 0 $main15;
    aspect-ratio: 360 / 220;
    outline-width: 0.3rem;
    outline-offset: -1.3rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-top-point__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;

}

.p-top-point__num {
  padding: 0.5rem 1.5rem;
  background-color: $accent4;
  border-radius: 0.5rem;

  font-family: $english;
  font-weight: $bold;
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: 0.125em;
  text-align: center;

}

.p-top-point__title {
  font-weight: $bold;
  font-size: 3.2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main3;
  white-space: nowrap;

  @include mq("md") {
    white-space: normal;
    font-size: 2.4rem;
  }
}