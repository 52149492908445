@use "global"as *;

.p-top-about {
  width: 100%;
  background-color: $lightgray;
  padding-top: 6rem;
  padding-bottom: 9.8rem;

  @include mq("md") {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }

}

.p-top-about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  @include mq("md") {
    gap: 2.5rem;
  }
}

.p-top-about__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;

  @include mq("md") {
    gap: 4rem;
  }
}

.p-top-about__catch {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  &.u-pc {
    display: flex;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -8.3rem;
      left: -11rem;
      width: 12.1rem;
      aspect-ratio: 1/1;
      background-image: url(/assets/img/top/about-deco1.webp);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -7.8rem;
      right: -7.5rem;
      width: 11.2rem;
      height: 11.6rem;
      background-image: url(/assets/img/top/about-deco2.webp);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.u-sp {
    display: none;
  }

  @include mq("md") {
    align-items: flex-start;
    gap: 2rem;

    &.u-pc {
      display: none;
    }

    &.u-sp {
      display: flex;
    }
  }

}

.about__catch-text {
  padding: 0.2rem 4.8rem;
  background-color: $white;
  box-shadow: 0.4rem 0.4rem 0 $main1;
  border-radius: 0.5rem;
  font-weight: $medium;
  font-size: 2.4rem;
  line-height: 200%;
  letter-spacing: 0.05em;
  color: $main1;

  @include mq("md") {
    padding: 0.5rem 1.9rem;
    font-size: 2rem;
    line-height: 4.8rem;
    white-space: nowrap;
  }

  & .strong {
    font-size: 3rem;
    line-height: 200%;
    color: $accent2;

    @include mq("md") {
      font-size: 2.4rem;
    }
  }
}

.p-top-about__detail-text {
  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 180%;
  letter-spacing: 0;
  text-align: center;

  @include mq("md") {
    font-size: 1.6rem;
  }
}

.p-top-about__detail-text+.p-top-about__detail-text {
  margin-top: calc(1em * 1.8);
}


.p-top-gallery {
  margin-top: 8rem;

  @include mq("md") {
    margin-top: 4rem;
  }


  &.splide {}

  & .splide__track {}

  & .splide__list {}

  & .splide__slide {
    width: 40rem;
    height: 28rem;
    border-radius: 2rem;
    border: 5px solid $white;
    box-shadow: 0 1rem 3rem rgba(#000, 8%);
    overflow: hidden;

    @include mq("md") {
      width: 20rem;
      height: 14rem;
      border-radius: 1rem;
      border-width: 2.5px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:nth-child(even) {
      margin-top: 6rem;

      @include mq("md") {
        margin-top: 3rem;
      }
    }
  }

}