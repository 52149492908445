@use "global" as *;


.p-courses-recommend__container {
  padding: 5rem 5rem 6rem;
  background-color: $main12;
  border-radius: 2rem;

  @include mq("md") {
    padding: 4rem 2rem 5rem;
  }
}

.p-courses-recommend__heading {
  width: max-content;
  margin-inline: auto;
  padding-bottom: 1.3rem;
  position: relative;
  font-weight: $bold;
  font-size: 2.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main8;
  @include mq("md") {
    font-size: 2.4rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: url(/assets/img/common/dot-line-main-full.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.p-courses-recommend__list {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 3rem 5rem;

  @include mq("md") {
    margin-top: 3.3rem;
    grid-template-columns: 1fr;
    gap: 4.8rem;
  }
}
