@use "global"as *;

.p-news {
  padding-top: 5rem;
  padding-bottom: 10rem;

  @include mq("md") {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }
}


.p-news {
  position: relative;
  z-index: $news;
}

.p-news__content {
  width: 100%;
  max-width: 84rem;
  background-color: $white;
  border-radius: 0.5rem;
  padding: 2.5rem 4rem;

  @include mq("md") {
    padding: 2.5rem 2rem 2rem;
    margin-inline: auto;
  }
}

.p-news__wrapper {
  display: flex;
  align-items: flex-start;

  @include mq("md") {
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1.7rem;
  }
}

.p-news__heading {
  flex-shrink: 0;
  padding: 0.5rem 0;
  font-weight: $bold;
  font-size: 2rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: $main1;

  @include mq("md") {
    padding: 0;
    font-size: 1.6rem;
  }
}

.p-news__link {
  width: 100%;
  display: contents;
  align-items: center;
  transition: opacity 0.3s;

  &:hover{
    opacity: 0.7;

    & .p-news__arrow {
      transform: translateX(0.5rem);
    }
  }
}

.p-news__date {
  flex-shrink: 0;
  padding: 0.8rem 0;
  font-family: $english;
  font-weight: $medium;
  font-size: 1.6rem;
  color: $darkgray;
}

.p-news__title {
  margin-inline: 2rem;
  padding: 0.65rem 0;
  font-weight: $medium;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;

  @include mq("md") {
    margin-inline: 0;
    padding: 0;
    flex-shrink: 0;
    max-width: calc(268 / 320 * 100%);
    font-size: 1.6rem;
  }
}
